import Axios from 'axios';
import {
    ADD_DISTRIBUTION_IN_PROGRESS,
    ADD_DISTRIBUTION_SUCCESS,
    ADD_DISTRIBUTION_ERROR,
    FETCH_DISTRIBUTION_IN_PROGRESS,
    FETCH_DISTRIBUTION_SUCCESS,
    FETCH_DISTRIBUTION_ERROR,
    UPDATE_DISTRIBUTION_IN_PROGRESS,
    UPDATE_DISTRIBUTION_SUCCESS,
    UPDATE_DISTRIBUTION_ERROR,
    UPDATE_DISTRIBUTION_ITEM_IN_PROGRESS,
    UPDATE_DISTRIBUTION_ITEM_SUCCESS,
    UPDATE_DISTRIBUTION_ITEM_ERROR,
} from '../constants/distribution';
import {
    distributionURL,
    updateDistributionURL,
    updateDistributionItemURL,
} from '../constants/url';

const addDistributionInProgress = () => {
    return {
        type: ADD_DISTRIBUTION_IN_PROGRESS,
    };
};

const addDistributionSuccess = (message) => {
    return {
        message,
        type: ADD_DISTRIBUTION_SUCCESS,
    };
};

const addDistributionError = (message) => {
    return {
        message,
        type: ADD_DISTRIBUTION_ERROR,
    };
};

const fetchDistributionInProgress = () => {
    return {
        type: FETCH_DISTRIBUTION_IN_PROGRESS,
    };
};

const fetchDistributionSuccess = (item) => {
    return {
        item,
        type: FETCH_DISTRIBUTION_SUCCESS,
    };
};

const fetchDistributionError = () => {
    return {
        type: FETCH_DISTRIBUTION_ERROR,
    };
};

const updateDistributionInProgress = () => {
    return {
        type: UPDATE_DISTRIBUTION_IN_PROGRESS,
    };
};

const updateDistributionSuccess = (message, item) => {
    return {
        message,
        type: UPDATE_DISTRIBUTION_SUCCESS,
        item,
    };
};

const updateDistributionError = (message) => {
    return {
        type: UPDATE_DISTRIBUTION_ERROR,
        message,
    };
};

const updateDistributionItemInProgress = () => {
    return {
        type: UPDATE_DISTRIBUTION_ITEM_IN_PROGRESS,
    };
};

const updateDistributionItemSuccess = (message, item) => {
    return {
        message,
        type: UPDATE_DISTRIBUTION_ITEM_SUCCESS,
        item,
    };
};

const updateDistributionItemError = (message) => {
    return {
        type: UPDATE_DISTRIBUTION_ITEM_ERROR,
        message,
    };
};

export const addDistributionDetails = (data, cb) => (dispatch) => {
    dispatch(addDistributionInProgress());

    const { id } = data;
    const URL = distributionURL(id);
    Axios.post(URL, data, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
    })
        .then((res) => {
            dispatch(addDistributionSuccess('Distribution details added successfully.'));
            cb(null, res.data && res.data.result && res.data.result._id);
        })
        .catch((error) => {
            dispatch(addDistributionError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            cb(error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : 'Failed!');
        });
};

export const fetchDistributionDetails = (ID) => (dispatch) => {
    dispatch(fetchDistributionInProgress());

    const URL = distributionURL(ID);
    Axios.get(URL, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
    }).then((res) => {
        dispatch(fetchDistributionSuccess(res.data.result));
    }).catch((error) => {
        dispatch(fetchDistributionError(
            error.response &&
            error.response.data &&
            error.response.data.message
                ? error.response.data.message
                : 'Failed!',
        ));
    });
};

export const updateDistributionDetails = (ID, distributionID, data, cb) => (dispatch) => {
    dispatch(updateDistributionInProgress());

    const URL = updateDistributionURL(ID, distributionID);
    console.log(URL);
    Axios.put(URL, data, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
    })
        .then((res) => {
            dispatch(updateDistributionSuccess('Distribution details updated successfully!', 'res'));
            cb(null, res.data && res.data.result && res.data.result._id);
        })
        .catch((error) => {
            dispatch(updateDistributionError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            cb(error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : 'Failed!');
        });
};

export const updateDistributionItem = (ID, distributionID, distributionItemID, data, cb) => (dispatch) => {
    dispatch(updateDistributionItemInProgress());

    const URL = updateDistributionItemURL(ID, distributionID, distributionItemID);
    Axios.put(URL, data, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
    })
        .then((res) => {
            dispatch(updateDistributionItemSuccess('Distribution Item updated successfully!', 'res'));
            cb(null, res.data && res.data.result && res.data.result._id);
        })
        .catch((error) => {
            dispatch(updateDistributionItemError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            cb(error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : 'Failed!');
        });
};
