import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import Banner from './Banner';
import Newsfeed from './Newsfeed';
import PublicBio from './PublicBio';
import '../index.css';
import { useParams } from 'react-router';
import { fetchSpecificUser, fetchSpecificProfileImage, fetchSpecificBannerImage } from '../../../actions/users';

const PublicProfile = (props) => {
    const [publicProfile] = useState(true);
    const { id } = useParams();
    const {
        fetchSpecificUser,
        userData,
        fetchSpecificProfileImage,
        userProfilePic,
        fetchSpecificBannerImage,
        userBannerImage,
    } = props;

    useEffect(() => {
        fetchSpecificUser(id);
        fetchSpecificProfileImage(id);
        fetchSpecificBannerImage(id);
    }, []);

    return (
        <div className="public-profile">
            <div className="public-prifile__container">
                <div className="public-profile__header">
                    <Banner
                        bannerImage={userBannerImage}
                    />
                    <PublicBio
                        userData={userData}
                        userProfilePic={userProfilePic}
                    />
                </div>
                <div className="public-profile__body">
                    <Newsfeed
                        lang={props.lang}
                        publicProfile={publicProfile}
                        userData={userData}
                    />
                </div>
            </div>
        </div>
    );
};

PublicProfile.propTypes = {
    fetchSpecificBannerImage: PropTypes.func.isRequired,
    fetchSpecificProfileImage: PropTypes.func.isRequired,
    fetchSpecificUser: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    location: PropTypes.object.isRequired,
    userBannerImage: PropTypes.object.isRequired,
    userData: PropTypes.object.isRequired,
    userProfilePic: PropTypes.object.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        userData: state.users.specificUserInfo,
        userProfilePic: state.users.specificProfilePic,
        userBannerImage: state.users.specificBannerPic,
    };
};

const actionToProps = {
    fetchSpecificUser,
    fetchSpecificProfileImage,
    fetchSpecificBannerImage,
};

export default connect(stateToProps, actionToProps)(PublicProfile);
