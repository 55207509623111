import React, { useEffect, useState } from 'react';
import { EditorState } from 'draft-js';
import * as PropTypes from 'prop-types';
import { Editor } from 'react-draft-wysiwyg';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const EditorWrapper = ({ editorState, onEditorStateChange }) => {
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        setMounted(true);
    }, []);

    return (
        <React.Suspense fallback={<div>Loading...</div>}>
            {mounted && (
                <Editor
                    editorClassName="demo-editor"
                    editorState={editorState}
                    wrapperClassName="demo-wrapper h-4/6"
                    onEditorStateChange={onEditorStateChange}
                />
            )}
        </React.Suspense>
    );
};

EditorWrapper.propTypes = {
    editorState: PropTypes.instanceOf(EditorState),
    onEditorStateChange: PropTypes.func,
};

export default EditorWrapper;
