import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';

const TryAgainButton = (props) => {
    return (
        <Button
            className="verify_button"
            onClick={props.onClose}>
            Ok, I will try again
        </Button>
    );
};

TryAgainButton.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export default TryAgainButton;
