import React from 'react';
import * as PropTypes from 'prop-types';

const ParticipantsColumn = ({ participant }) => {
    const filteredParticipant = participant.filter((user, index) => index > 3);
    return (
        <>
            {participant.length !== 0
                ? <>
                    {participant.slice(0, 3).map((item, key) => <div key={key} className={`participant-item item-${key}`}><div><img alt="test" src={item.user_id.profile_image_base64} /></div></div>)}
                    {filteredParticipant.length > 1 &&
                    <div className="participant-item item-3">
                        <div className="flex items-center justify-center">
                            <span className="font-semibold font-oswald-text text-sm">{filteredParticipant.length > 99 ? '99' : filteredParticipant.length}+</span>
                        </div>
                    </div>
                    }
                </>
                : <span className="font-semibold font-oswald-text text-sm">No Participants</span>}
        </>
    );
};

ParticipantsColumn.propTypes = {
    participant: PropTypes.array,
};

export default ParticipantsColumn;
