import React, { Component } from 'react';
import DataTable from '../../components/DataTable';
import './index.css';
import variables from '../../utils/variables';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchUsersList } from '../../actions/users';
import {
    DEFAULT_LIMIT,
    DEFAULT_ORDER,
    DEFAULT_SEARCH_KEY,
    DEFAULT_SKIP,
    DEFAULT_SORT_BY,
    USERS_EXPORT_URL,
} from '../../constants/url';
import CircularProgress from '../../components/CircularProgress';
import { removeUnderScroll } from '../../utils/statusMessages';
import { downloadFile } from '../../actions/unstakeRequests';
import { Link } from 'react-router-dom';
import { fetchProfileDetails } from '../../actions/navigationBar';

class Table extends Component {
    constructor (props) {
        super(props);

        this.handleFetch = this.handleFetch.bind(this);
    }

    componentDidMount () {
        if (this.props.inProgress || this.props.data.length > 0) {
            return;
        }

        this.handleFetch(DEFAULT_SKIP,
            DEFAULT_LIMIT,
            DEFAULT_SEARCH_KEY,
            DEFAULT_SORT_BY,
            DEFAULT_ORDER);
    }

    componentDidUpdate (pp, ps, ss) {
        if (this.props.auth && (pp.auth !== this.props.auth)) {
            this.handleFetch(DEFAULT_SKIP,
                DEFAULT_LIMIT,
                DEFAULT_SEARCH_KEY,
                DEFAULT_SORT_BY,
                DEFAULT_ORDER);
        }
    }

    handleFetch (skip, limit, searchText, sortBy, order) {
        this.props.fetch(skip, limit, searchText, sortBy, order);
    }

    render () {
        const options = {
            responsive: 'vertical',
            serverSide: true,
            print: false,
            count: this.props.count,
            selectableRows: 'none',
            searchPlaceholder: variables[this.props.lang]['search_by_account'],
            onSearchChange: (searchText) => {
                this.handleFetch(this.props.skip, this.props.limit, searchText, this.props.sortBy, null);
            },
            onChangePage: (currentPage) => {
                if (this.props.data && this.props.data.length === 0) {
                    return;
                }

                this.handleFetch(this.props.limit * currentPage, this.props.limit, this.props.searchKey, this.props.sortBy, null);
            },
            onChangeRowsPerPage: (numberOfRows) => {
                if (this.props.data && this.props.data.length === 0) {
                    return;
                }

                this.handleFetch(this.props.skip, numberOfRows, this.props.searchKey, this.props.sortBy, null);
            },
            onColumnSortChange: (changedColumn, direction) => {
                if (this.props.data && this.props.data.length === 0) {
                    return;
                }

                const order = direction === 'asc' ? 'asc' : 'desc';
                this.handleFetch(this.props.skip, this.props.limit, this.props.searchKey, changedColumn, order);
            },
            onDownload: () => {
                this.props.downloadFile(USERS_EXPORT_URL, 'Users Report');
                return false;
            },
            textLabels: {
                body: {
                    noMatch: this.props.inProgress
                        ? <CircularProgress/>
                        : <div className="no_data_table"> No data found </div>,
                    toolTip: 'Sort',
                },
                filter: {
                    all: 'All',
                    title: 'FILTERS',
                    reset: 'RESET',
                },
                viewColumns: {
                    title: 'Show Columns',
                    titleAria: 'Show/Hide Table Columns',
                },
            },
        };

        const columns = [{
            name: 'email_address',
            label: 'Email Address',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => (
                    <Link className="font-bold user-detail__link" to={`/${(this.props.user_id === value._id) ? 'profile' : 'user'}/${value._id}`}>{value.email_address}</Link>
                ),
            },
        }, {
            name: 'telegram_username',
            label: 'Telegram Handle',
            options: {
                filter: false,
                sort: true,
                display: true,
            },
        }, {
            name: 'primary_account_address',
            label: 'ETH Account',
            options: {
                filter: false,
                sort: true,
            },
        }, {
            name: 'exntBalance',
            label: 'EXNT Balance',
            options: {
                filter: false,
                sort: true,
                display: false,
            },
        }, {
            name: 'stake',
            label: 'EXNT Staked',
            options: {
                filter: false,
                sort: true,
            },
        }, {
            name: 'status',
            label: 'Status',
            options: {
                filter: false,
                sort: true,
            },
        }, {
            name: 'memberSince',
            label: 'Member Since',
            options: {
                filter: false,
                sort: true,
                display: false,
            },
        }, {
            name: 'activeGroups',
            label: 'Active Groups',
            options: {
                filter: false,
                sort: true,
                display: false,
            },
        }];

        const tableData = this.props.data && this.props.data.length
            ? this.props.data.map((item, index) =>
                [
                    item,
                    item.telegram_username,
                    item.primary_account_address,
                    item.exnt_balance,
                    item.stake * Math.pow(10, -16),
                    item.status && removeUnderScroll(item.status),
                    null,
                    item.groups && item.groups.length && item.groups.join(', '),
                ])
            : [];

        return (
            <div className="table">
                <DataTable
                    columns={columns}
                    data={tableData}
                    name="Users Info"
                    options={options}/>
            </div>
        );
    }
}

Table.propTypes = {
    count: PropTypes.number.isRequired,
    data: PropTypes.array.isRequired,
    downloadFile: PropTypes.func.isRequired,
    fetch: PropTypes.func.isRequired,
    fetchProfileDetails: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    limit: PropTypes.number.isRequired,
    searchKey: PropTypes.string.isRequired,
    skip: PropTypes.number.isRequired,
    sortBy: PropTypes.string.isRequired,
    user_id: PropTypes.string.isRequired,
    auth: PropTypes.string,
    order: PropTypes.number,
};

const stateToProps = (state) => {
    return {
        auth: state.accessToken.value,
        lang: state.language,
        data: state.users.list.list,
        count: state.users.list.count,
        inProgress: state.users.list.inProgress,
        limit: state.users.list.limit,
        order: state.users.list.order,
        searchKey: state.users.list.searchKey,
        skip: state.users.list.skip,
        sortBy: state.users.list.sortBy,
        user_id: state.navigationBar.profileDetails.value._id,
    };
};

const actionToProps = {
    fetch: fetchUsersList,
    downloadFile,
    fetchProfileDetails,
};

export default connect(stateToProps, actionToProps)(Table);
