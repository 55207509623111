import Axios from 'axios';
import {
    CONFIG_UPDATE_ERROR,
    CONFIG_UPDATE_IN_PROGRESS,
    CONFIG_UPDATE_SUCCESS,
    FETCH_CONFIG_ERROR,
    FETCH_CONFIG_IN_PROGRESS,
    FETCH_CONFIG_SUCCESS,
} from '../constants/config';
import { CONFIG_URL } from '../constants/url';

const fetchConfigInProgress = () => {
    return {
        type: FETCH_CONFIG_IN_PROGRESS,
    };
};

const fetchConfigSuccess = (item) => {
    return {
        type: FETCH_CONFIG_SUCCESS,
        item,
    };
};

const fetchConfigError = (message) => {
    return {
        type: FETCH_CONFIG_ERROR,
        message,
    };
};

const updateConfigInProgress = () => {
    return {
        type: CONFIG_UPDATE_IN_PROGRESS,
    };
};

const updateConfigSuccess = (item) => {
    return {
        type: CONFIG_UPDATE_SUCCESS,
        item,
    };
};

const updateConfigError = (message) => {
    return {
        type: CONFIG_UPDATE_ERROR,
        message,
    };
};

export const fetchConfig = (ID) => (dispatch) => {
    dispatch(fetchConfigInProgress());

    Axios.get(CONFIG_URL, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
    }).then((res) => {
        dispatch(fetchConfigSuccess(res.data.result));
    }).catch((error) => {
        dispatch(fetchConfigError(
            error.response &&
            error.response.data &&
            error.response.data.message
                ? error.response.data.message
                : 'Failed!',
        ));
    });
};

export const updateConfig = (data, cb) => (dispatch) => {
    dispatch(updateConfigInProgress());

    Axios.put(CONFIG_URL, data, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
    }).then((res) => {
        dispatch(updateConfigSuccess('Success'));
        cb(null, res.data && res.data.result && res.data.result._id);
    }).catch((error) => {
        dispatch(updateConfigError(
            error.response &&
            error.response.data &&
            error.response.data.message
                ? error.response.data.message
                : 'Failed!',
        ));
        cb(error);
    });
};
