import * as PropTypes from 'prop-types';
import React from 'react';

const Banner = (props) => {
    const { bannerImage } = props;

    return (
        <>
            <div className="profile-page__banner">
                <img alt="Banner Image" src={bannerImage.value.url} />
            </div>
        </>
    );
};

Banner.propTypes = {
    bannerImage: PropTypes.object.isRequired,
};

export default Banner;
