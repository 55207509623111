export const TASK_LIST_FETCH_IN_PROGRESS = 'TIPPING_LIST_FETCH_IN_PROGRESS';
export const TASK_LIST_FETCH_SUCCESS = 'TIPPING_LIST_FETCH_SUCCESS';
export const TASK_LIST_FETCH_ERROR = 'TIPPING_LIST_FETCH_ERROR';

export const TASK_SPECIFIC_FETCH_IN_PROGRESS = 'TIPPING_SPECIFIC_FETCH_IN_PROGRESS';
export const TASK_SPECIFIC_FETCH_SUCCESS = 'TIPPING_SPECIFIC_FETCH_SUCCESS';
export const TASK_SPECIFIC_FETCH_ERROR = 'TIPPING_LIST_FETCH_ERROR';

export const ADD_TASK_IN_PROGRESS = 'ADD_TASK_IN_PROGRESS';
export const ADD_TASK_SUCCESS = 'ADD_TASK_SUCCESS';
export const ADD_TASK_ERROR = 'ADD_TASK_ERROR';

export const UPDATE_TASK_IN_PROGRESS = 'UPDATE_TASK_IN_PROGRESS';
export const UPDATE_TASK_SUCCESS = 'UPDATE_TASK_SUCCESS';
export const UPDATE_TASK_ERROR = 'UPDATE_TASK_ERROR';

export const TASK_SPECIFIC_PARTICIPANT_FETCH_IN_PROGRESS = 'TASK_SPECIFIC_PARTICIPANT_FETCH_IN_PROGRESS';
export const TASK_SPECIFIC_PARTICIPANT_FETCH_SUCCESS = 'TASK_SPECIFIC_PARTICIPANT_FETCH_SUCCESS';
export const TASK_SPECIFIC_PARTICIPANT_FETCH_ERROR = 'TASK_SPECIFIC_PARTICIPANT_FETCH_ERROR';
