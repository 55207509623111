import * as PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Input, makeStyles, TextField } from '@material-ui/core';
// import TextField from '../../../../components/TextField';
import variables from '../../../../utils/variables';
import classNames from 'classnames';
import { setAuthenticationCode } from '../../../../actions/settings';

const useStyles = makeStyles(() => ({
    root: {
        '& .verification_field': {
            width: '53px',
            height: '53px',
            marginTop: '0',
            marginBottom: '0',
            '& .MuiInput-root': {
                height: '100%',
            },
            '& .MuiInputBase-root': {
                height: '100%',
                width: '100%',
            },
            '& input': {
                textAlign: 'center',
                width: '53px',
                height: '53px',
                color: '#EF0048',
            },

            '& .MuiInput-underline:before': {
                borderBottom: '0',
            },
        },
        '& .hidden-field': {
            position: 'absolute',
            height: '1px',
            width: '1px',
        },
    },
}));

const AuthenticationCodeTextField = (props) => {
    const { onChange } = props;

    useEffect(() => {
        onChange('');
    }, [onChange]);

    const handleChange = (value) => {
        if (value.length < 7) {
            onChange(value);
        } else if (props.backUp && value.length < 9) {
            onChange(value);
        }
    };

    const handlePaste = (e) => {
        handleChange(e.clipboardData.getData('Text'));
    };

    const onFieldHandler = (e) => {
        const newValue = props.value.split('');
        const index = e.target.attributes.index.value;

        if (e.keyCode === 8) {
            newValue[index] = '';
            handleChange(newValue.join(''));
            if (index !== '0') {
                document.querySelector(`#${e.target.attributes.prev.value}`).focus();
            }
        } else {
            if (
                (e.keyCode >= 48 && e.keyCode <= 57) ||
                (e.keyCode >= 96 && e.keyCode <= 105)
            ) {
                newValue[index] = e.key;
                handleChange(newValue.join(''));
                if (index !== '5') {
                    document.querySelector(`#${e.target.attributes.next.value}`).focus();
                }
            }
        }
    };

    return (
        <div
            className={classNames(useStyles().root, 'verification_wrapper', 'flex', 'relative', 'justify-evenly', 'mb-10')}>
            <TextField className="verification_field rounded-md bg-black" id="ver_1" inputProps={{ index: 0, next: 'ver_2' }} maxLength="1" name="ver_1" placeholder="●" value={props.value[0] || ''} onKeyUp={onFieldHandler} onPaste={handlePaste}/>
            <TextField className="verification_field rounded-md bg-black" id="ver_2" inputProps={{ index: 1, next: 'ver_3', prev: 'ver_1' }} maxLength="1" name="ver_2" placeholder="●" value={props.value[1] || ''} onKeyUp={onFieldHandler} onPaste={handlePaste}/>
            <TextField className="verification_field rounded-md bg-black" id="ver_3" inputProps={{ index: 2, next: 'ver_4', prev: 'ver_2' }} maxLength="1" name="ver_3" placeholder="●" value={props.value[2] || ''} onKeyUp={onFieldHandler} onPaste={handlePaste}/>
            <TextField className="verification_field rounded-md bg-black" id="ver_4" inputProps={{ index: 3, next: 'ver_5', prev: 'ver_3' }} maxLength="1" name="ver_4" placeholder="●" value={props.value[3] || ''} onKeyUp={onFieldHandler} onPaste={handlePaste}/>
            <TextField className="verification_field rounded-md bg-black" id="ver_5" inputProps={{ index: 4, next: 'ver_6', prev: 'ver_4' }} maxLength="1" name="ver_5" placeholder="●" value={props.value[4] || ''} onKeyUp={onFieldHandler} onPaste={handlePaste}/>
            <TextField className="verification_field rounded-md bg-black" id="ver_6" inputProps={{ index: 5, prev: 'ver_5' }} maxLength="1" name="ver_6" placeholder="●" value={props.value[5] || ''} onKeyUp={onFieldHandler} onPaste={handlePaste}/>
            <Input
                className={props.value ? 'letter_spacing' : '' + 'absolute hidden-field'}
                id="authentication_code_text_field"
                name="authenticationCode"
                placeholder={variables[props.lang]['enter_authentication_code']}
                type="hidden"
                value={props.value || ''}/>
        </div>
    );
};

AuthenticationCodeTextField.propTypes = {
    lang: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    backUp: PropTypes.bool,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        value: state.settings.authCode,
    };
};

const actionsToProps = {
    onChange: setAuthenticationCode,
};

export default connect(stateToProps, actionsToProps)(AuthenticationCodeTextField);
