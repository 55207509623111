import { combineReducers } from 'redux';
import {
    TASK_LIST_FETCH_IN_PROGRESS,
    TASK_LIST_FETCH_SUCCESS,
    TASK_LIST_FETCH_ERROR,
    TASK_SPECIFIC_FETCH_IN_PROGRESS,
    TASK_SPECIFIC_FETCH_SUCCESS,
    TASK_SPECIFIC_FETCH_ERROR,
    TASK_SPECIFIC_PARTICIPANT_FETCH_IN_PROGRESS,
    TASK_SPECIFIC_PARTICIPANT_FETCH_SUCCESS,
    TASK_SPECIFIC_PARTICIPANT_FETCH_ERROR,
} from '../constants/tasks';

const taskList = (state = {
    inProgress: false,
    list: [],
}, action) => {
    switch (action.type) {
    case TASK_LIST_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case TASK_LIST_FETCH_SUCCESS:
        return {
            inProgress: false,
            list: action.value,
        };
    case TASK_LIST_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const specificTask = (state = {
    inProgress: false,
    task: {},
}, action) => {
    switch (action.type) {
    case TASK_SPECIFIC_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case TASK_SPECIFIC_FETCH_SUCCESS:
        return {
            inProgress: false,
            list: action.value,
        };
    case TASK_SPECIFIC_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const specificParticipantTask = (state = {
    inProgress: false,
    participant: {},
}, action) => {
    switch (action.type) {
    case TASK_SPECIFIC_PARTICIPANT_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case TASK_SPECIFIC_PARTICIPANT_FETCH_SUCCESS:
        return {
            inProgress: false,
            participant: action.value,
        };
    case TASK_SPECIFIC_PARTICIPANT_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

export default combineReducers({
    taskList,
    specificParticipantTask,
    specificTask,
});
