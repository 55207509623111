import Axios from 'axios';
import {
    GET_MESSAGING_USERS,
    getMessages,
    getMessageById,
    getSender,
    getRecipient,
} from '../constants/url';
import {
    MESSAGING_USERS_FETCH_IN_PROGRESS,
    MESSAGING_USERS_FETCH_SUCCESS,
    MESSAGING_USERS_FETCH_ERROR,
    MESSAGES_FETCH_IN_PROGRESS,
    MESSAGES_FETCH_SUCCESS,
    MESSAGES_FETCH_ERROR,
    MESSAGES_GET_BY_ID_IN_PROGRESS,
    MESSAGES_GET_BY_ID_SUCCESS,
    MESSAGES_GET_BY_ID_ERROR,
    MESSAGES_GET_SENDER_IN_PROGRESS,
    MESSAGES_GET_SENDER_SUCCESS,
    MESSAGES_GET_SENDER_ERROR,
    MESSAGES_GET_RECIPIENT_IN_PROGRESS,
    MESSAGES_GET_RECIPIENT_SUCCESS,
    MESSAGES_GET_RECIPIENT_ERROR,
} from '../constants/messaging';

const fetchMessagingUsersInProgress = () => {
    return {
        type: MESSAGING_USERS_FETCH_IN_PROGRESS,
    };
};

const fetchMessagingUsersSuccess = (value) => {
    return {
        type: MESSAGING_USERS_FETCH_SUCCESS,
        value,
    };
};

const fetchMessagingUsersError = (message) => {
    return {
        type: MESSAGING_USERS_FETCH_ERROR,
        message,
    };
};

export const getUsers = () => (dispatch) => {
    dispatch(fetchMessagingUsersInProgress());
    const URL = GET_MESSAGING_USERS;

    Axios.get(URL, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
    }).then((res) => {
        dispatch(fetchMessagingUsersSuccess(res.data));
    }).catch((error) => {
        dispatch(fetchMessagingUsersError(
            error.response &&
        error.response.data &&
        error.response.data.message
                ? error.response.data.message
                : 'Failed!',
        ));
    });
};

const fetchMessagesInProgress = () => {
    return {
        type: MESSAGES_FETCH_IN_PROGRESS,
    };
};

const fetchMessageSuccess = (value) => {
    return {
        type: MESSAGES_FETCH_SUCCESS,
        value,
    };
};

const fetchMessageError = (message) => {
    return {
        type: MESSAGES_FETCH_ERROR,
        message,
    };
};

export const getRecievedMessages = (userID) => (dispatch) => {
    dispatch(fetchMessagesInProgress());
    const URL = getMessages(userID);

    Axios.get(URL, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
    }).then((res) => {
        dispatch(fetchMessageSuccess(res.data));
    }).catch((error) => {
        dispatch(fetchMessageError(
            error.response &&
        error.response.data &&
        error.response.data.message
                ? error.response.data.message
                : 'Failed!',
        ));
    });
};

const getMessageByIdInProgress = () => {
    return {
        type: MESSAGES_GET_BY_ID_IN_PROGRESS,
    };
};

const getMessageByIdSuccess = (value) => {
    return {
        type: MESSAGES_GET_BY_ID_SUCCESS,
        value,
    };
};

const getMessageByIdError = (message) => {
    return {
        type: MESSAGES_GET_BY_ID_ERROR,
        message,
    };
};

export const getMessageByID = (id) => (dispatch) => {
    dispatch(getMessageByIdInProgress());
    const URL = getMessageById(id);

    Axios.get(URL, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
    }).then((res) => {
        // console.log(res.data);
        dispatch(getMessageByIdSuccess(res.data));
    }).catch((error) => {
        dispatch(getMessageByIdError(
            error.response &&
        error.response.data &&
        error.response.data.message
                ? error.response.data.message
                : 'Failed!',
        ));
    });
};

const getMessageSenderInProgress = () => {
    return {
        type: MESSAGES_GET_SENDER_IN_PROGRESS,
    };
};

const getMessageSenderSuccess = (value) => {
    return {
        type: MESSAGES_GET_SENDER_SUCCESS,
        value,
    };
};

const getMessageSenderError = (message) => {
    return {
        type: MESSAGES_GET_SENDER_ERROR,
        message,
    };
};

export const getMessageSender = (id) => (dispatch) => {
    dispatch(getMessageSenderInProgress());
    const URL = getSender(id);
    Axios.get(URL, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
    }).then((res) => {
        dispatch(getMessageSenderSuccess(res.data));
    }).catch((error) => {
        dispatch(getMessageSenderError(
            error.response &&
        error.response.data &&
        error.response.data.message
                ? error.response.data.message
                : 'Failed!',
        ));
    });
};

const getMessageRecipientInProgress = () => {
    return {
        type: MESSAGES_GET_RECIPIENT_IN_PROGRESS,
    };
};

const getMessageRecipientSuccess = (value) => {
    return {
        type: MESSAGES_GET_RECIPIENT_SUCCESS,
        value,
    };
};

const getMessageRecipientError = (message) => {
    return {
        type: MESSAGES_GET_RECIPIENT_ERROR,
        message,
    };
};

export const fetchRecipient = (id) => (dispatch) => {
    dispatch(getMessageRecipientInProgress());
    const URL = getRecipient(id);
    Axios.get(URL, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
    }).then((res) => {
        dispatch(getMessageRecipientSuccess(res.data));
    }).catch((error) => {
        dispatch(getMessageRecipientError(
            error.response &&
        error.response.data &&
        error.response.data.message
                ? error.response.data.message
                : 'Failed!',
        ));
    });
};
