import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import DealsForm from './DealsForm';
import Snackbar from '../../../components/Snackbar';
import { showDealForm } from '../../../actions/settings';

const DealsTab = (props) => {
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const enableDealFormDialog = () => {
        props.showDealForm();
    };

    const onUpdateFeedback = (message, type) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(type);
        setOpenSnackbar(true);
    };

    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };
    return (
        <>
            <Snackbar
                message={snackbarMessage}
                open={openSnackbar}
                severity={snackbarSeverity}
                onClose={handleSnackbarClose}
            />
            <div className="settings__item-container pt-6 md:pt-10 flex items-center flex-col md:flex-row">
                <div className="flex-1 mb-4 md:mb-0">
                    <h3 className="text-base md:text-2xl font-semibold font-oswald-text mb-2">Deal Config</h3>
                    <p className="text-sm md:text-base font-semibold font-oswald-text text-custom-light-gray">Set deal
                        post time configuration.</p>
                </div>
                <div>
                    <Button className="settings__button" disabled={false} onClick={enableDealFormDialog}>Modify</Button>
                </div>
            </div>
            <DealsForm onUpdateFeedback={onUpdateFeedback}/>
        </>
    );
};

DealsTab.propTypes = {
    showDealForm: PropTypes.func.isRequired,
};

const actionToProps = {
    showDealForm,
};

export default connect(null, actionToProps)(DealsTab);
