import React from 'react';
import './index.css';
import '../Dashboard/index.css';
import Cards from './Cards';
import Table from './Table';

const Users = () => {
    return (
        <div className="dashboard content_padding users">
            <Cards/>
            <Table/>
        </div>
    );
};

export default Users;
