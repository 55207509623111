export const PASSWORD_UPDATE_IN_PROGRESS = 'PASSWORD_UPDATE_IN_PROGRESS';
export const PASSWORD_UPDATE_SUCCESS = 'PASSWORD_UPDATE_SUCCESS';
export const PASSWORD_UPDATE_ERROR = 'PASSWORD_UPDATE_ERROR';

export const SETTINGS_TAB_VALUE_SET = 'SETTINGS_TAB_VALUE_SET';

export const AUTHENTICATION_SWITCH_VALUE_SET = 'AUTHENTICATION_SWITCH_VALUE_SET';

export const AUTHENTICATION_SWITCH_VERIFY_DIALOG_SHOW = 'AUTHENTICATION_SWITCH_VERIFY_DIALOG_SHOW';
export const AUTHENTICATION_SWITCH_VERIFY_DIALOG_HIDE = 'AUTHENTICATION_SWITCH_VERIFY_DIALOG_HIDE';

export const AUTHENTICATION_DIALOG_SHOW = 'AUTHENTICATION_DIALOG_SHOW';
export const AUTHENTICATION_DIALOG_HIDE = 'AUTHENTICATION_DIALOG_HIDE';
export const AUTHENTICATION_CODE_SET = 'AUTHENTICATION_CODE_SET';

export const AUTHENTICATION_SUCCESS_DIALOG_SHOW = 'AUTHENTICATION_SUCCESS_DIALOG_SHOW';
export const AUTHENTICATION_SUCCESS_DIALOG_HIDE = 'AUTHENTICATION_SUCCESS_DIALOG_HIDE';

export const AUTHENTICATION_ERROR_DIALOG_SHOW = 'AUTHENTICATION_ERROR_DIALOG_SHOW';
export const AUTHENTICATION_ERROR_DIALOG_HIDE = 'AUTHENTICATION_ERROR_DIALOG_HIDE';

export const TWO_FACTOR_AUTH_ADD_IN_PROGRESS = 'TWO_FACTOR_AUTH_ADD_IN_PROGRESS';
export const TWO_FACTOR_AUTH_ADD_SUCCESS = 'TWO_FACTOR_AUTH_ADD_SUCCESS';
export const TWO_FACTOR_AUTH_ADD_ERROR = 'TWO_FACTOR_AUTH_ADD_ERROR';

export const TWO_FACTOR_AUTH_VERIFY_IN_PROGRESS = 'TWO_FACTOR_AUTH_VERIFY_IN_PROGRESS';
export const TWO_FACTOR_AUTH_VERIFY_SUCCESS = 'TWO_FACTOR_AUTH_VERIFY_SUCCESS';
export const TWO_FACTOR_AUTH_VERIFY_ERROR = 'TWO_FACTOR_AUTH_VERIFY_ERROR';

export const TWO_FACTOR_AUTH_VERIFY_BACKUP_TOKEN_IN_PROGRESS = 'TWO_FACTOR_AUTH_VERIFY_BACKUP_TOKEN_IN_PROGRESS';
export const TWO_FACTOR_AUTH_VERIFY_BACKUP_TOKEN_SUCCESS = 'TWO_FACTOR_AUTH_VERIFY_BACKUP_TOKEN_SUCCESS';
export const TWO_FACTOR_AUTH_VERIFY_BACKUP_TOKEN_ERROR = 'TWO_FACTOR_AUTH_VERIFY_BACKUP_TOKEN_ERROR';

export const TWO_FACTOR_AUTH_UPDATE_IN_PROGRESS = 'TWO_FACTOR_AUTH_UPDATE_IN_PROGRESS';
export const TWO_FACTOR_AUTH_UPDATE_SUCCESS = 'TWO_FACTOR_AUTH_UPDATE_SUCCESS';
export const TWO_FACTOR_AUTH_UPDATE_ERROR = 'TWO_FACTOR_AUTH_UPDATE_ERROR';

export const CHANGE_PASSSWORD_DIALOG_SHOW = 'CHANGE_PASSSWORD_DIALOG_SHOW';
export const CHANGE_PASSSWORD_DIALOG_HIDE = 'CHANGE_PASSSWORD_DIALOG_HIDE';

export const SETTING_TIMEZONE = 'SETTING_TIMEZONE';
export const SETTING_TIMEZONE_VALUE = 'SETTING_TIMEZONE_VALUE';

export const SHOW_DEAL_FORM = 'SHOW_DEAL_FORM';
export const HIDE_DEAL_FORM = 'HIDE_DEAL_FORM';

export const SETTING_DEAL_TIME = 'SETTING_DEAL_TIME';
export const SETTING_DEAL_TIER_LIMIT = 'SETTING_DEAL_TIER_LIMIT';
