import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { setEmailId } from '../../actions/signIn';
import TextField from '../../components/TextField';
import variables from '../../utils/variables';
import { isValidEmail } from '../../utils/validation';

const EmailTextField = (props) => {
    return (
        <>
            <label className="text-white font-oswald-text lg:text-lg md:mb-1 block">{variables[props.lang].email_label}</label>
            <TextField
                className="rounded-md bg-black w-full"
                error={props.value !== '' && !isValidEmail(props.value)}
                errorText={props.value !== '' && !isValidEmail(props.value) ? 'Please enter valid email address' : ''}
                id="email_id_text_field"
                name="emailId"
                placeholder={variables[props.lang].email}
                value={props.value}
                onChange={props.onChange}/>
        </>
    );
};

EmailTextField.propTypes = {
    lang: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        value: state.signIn.emailId,
    };
};

const actionsToProps = {
    onChange: setEmailId,
};

export default connect(stateToProps, actionsToProps)(EmailTextField);
