import Axios from 'axios';
import {
    UPDATE_ME_IN_PROGRESS,
    UPDATE_ME_SUCCESS,
    UPDATE_ME_ERROR,
    UPDATE_PROFILE_PIC_IN_PROGRESS,
    UPDATE_PROFILE_PIC_SUCCESS,
    UPDATE_PROFILE_PIC_ERROR,
    UPDATE_BANNER_PIC_ERROR,
    UPDATE_BANNER_PIC_IN_PROGRESS,
    UPDATE_BANNER_PIC_SUCCESS,
    BANNER_IMAGE_FETCH_IN_PROGRESS,
    BANNER_IMAGE_FETCH_SUCCESS,
    BANNER_IMAGE_FETCH_ERROR,
} from '../constants/me';
import { PROFILE_DETAILS_FETCH, PROFILE_IMAGE_FETCH, BANNER_IMAGE_FETCH } from '../constants/url';

const updateMeInProgress = () => {
    return {
        type: UPDATE_ME_IN_PROGRESS,
    };
};

const updateMeSuccess = (message) => {
    return {
        type: UPDATE_ME_SUCCESS,
        message,
    };
};

const updateMeError = (message) => {
    return {
        type: UPDATE_ME_ERROR,
        message,
    };
};

export const updateMe = (data, cb) => (dispatch) => {
    dispatch(updateMeInProgress());
    const URL = PROFILE_DETAILS_FETCH;
    Axios.put(URL, data, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
    })
        .then((res) => {
            dispatch(updateMeSuccess('Deal updated successfully!'));
            cb(null, res.data && res.data.result && res.data.result._id);
        })
        .catch((error) => {
            dispatch(updateMeError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            cb(error);
        });
};

const updateProfilePicInProgress = () => {
    return {
        type: UPDATE_PROFILE_PIC_IN_PROGRESS,
    };
};

const updateProfilePicSuccess = (message) => {
    return {
        type: UPDATE_PROFILE_PIC_SUCCESS,
        message,
    };
};

const updateProfilePicError = (message) => {
    return {
        type: UPDATE_PROFILE_PIC_ERROR,
        message,
    };
};

export const updateProfilePic = (data, cb) => (dispatch) => {
    dispatch(updateProfilePicInProgress());
    const URL = PROFILE_IMAGE_FETCH;
    Axios.put(URL, data, {
        headers: {
            // Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
            'Content-Type': 'multipart/form-data',
        },
    })
        .then((res) => {
            dispatch(updateProfilePicSuccess('Image Uploaded!'));
            cb(null, res.data && res.data.result && res.data.result._id);
        })
        .catch((error) => {
            dispatch(updateProfilePicError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            cb(error);
        });
};

const updateBannerPicInProgress = () => {
    return {
        type: UPDATE_BANNER_PIC_IN_PROGRESS,
    };
};

const updateBannerPicSuccess = (message) => {
    return {
        type: UPDATE_BANNER_PIC_SUCCESS,
        message,
    };
};

const updateBannerPicError = (message) => {
    return {
        type: UPDATE_BANNER_PIC_ERROR,
        message,
    };
};

export const updateBannerPic = (data, cb) => (dispatch) => {
    dispatch(updateBannerPicInProgress());
    const URL = BANNER_IMAGE_FETCH;
    Axios.put(URL, data, {
        headers: {
            // Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
            'Content-Type': 'multipart/form-data',
        },
    })
        .then((res) => {
            dispatch(updateBannerPicSuccess('Image Uploaded!'));
            cb(null, res.data && res.data.result && res.data.result._id);
        })
        .catch((error) => {
            dispatch(updateBannerPicError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            cb(error);
        });
};

const fetchBannerImageInProgress = () => {
    return {
        type: BANNER_IMAGE_FETCH_IN_PROGRESS,
    };
};

const fetchBannerImageSuccess = (value) => {
    return {
        type: BANNER_IMAGE_FETCH_SUCCESS,
        value,
    };
};

const fetchBannerImageError = (message) => {
    return {
        type: BANNER_IMAGE_FETCH_ERROR,
        message,
    };
};

export const fetchBannerImage = () => (dispatch) => {
    dispatch(fetchBannerImageInProgress());

    Axios.get(BANNER_IMAGE_FETCH, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
    })
        .then((res) => {
            const imageUrl = { url: res.data.result };
            dispatch(fetchBannerImageSuccess(imageUrl));
        })
        .catch((error) => {
            console.log(error);
            dispatch(fetchBannerImageError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};
