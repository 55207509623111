import React from 'react';
import { Button } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import noTask from '../../assets/images/notask.svg';

const NoTaskItems = ({ handleOpen }) => {
    return (
        <div className="notask flex flex-col text-center justify-center my-12">
            <img alt="No Task" className="mx-auto" src={noTask} />
            <div className="mt-4 font-oswald-text text-base mb-6">
                <p>You don&apos;t have any task listed yet. You can create one now</p>
            </div>
            <div>
                <Button className="tasks-button tasks-button--create" onClick={handleOpen}>+ Create</Button>
            </div>
        </div>
    );
};

NoTaskItems.propTypes = {
    handleOpen: PropTypes.func,
};

export default NoTaskItems;
