import React, { useState } from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { updateDeal } from '../../../actions/deals';
import DotsLoading from '../../../components/DotsLoading';
import Snackbar from '../../../components/Snackbar';
import variables from '../../../utils/variables';
import './index.css';

const ArchiveDealModal = (props) => {
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const {
        open,
        onClose,
        lang,
        inProgress,
        id,
        onSuccess,
        fetchDefault,
        updateDeal,
        isArchived,
    } = props;

    const archiveDeal = () => {
        const data = {};
        if (isArchived) {
            data.isArchive = false;
        } else {
            data.isArchive = true;
        }
        updateDeal(id, data, (error) => {
            if (!error && !inProgress) {
                onSuccess(variables[lang]['update_deal_success']);
                fetchDefault();
                onClose();
            } else if (error) {
                setSnackbarMessage(`Error: ${error}`);
                setOpenSnackbar(true);
            }
        });
    };

    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };

    return (
        <>
            <Snackbar
                message={snackbarMessage}
                open={openSnackbar}
                severity="error"
                onClose={handleSnackbarClose}
            />
            <Dialog
                aria-describedby="deal-dialog-description"
                aria-labelledby="deal-dialog-title"
                className="dialog close_deal_dialog rounded-lg"
                open={open}
                onClose={onClose}
            >
                <DialogContent className="content text-center">
                    <h2 className="font-oswald-text font-bold text-white text-2xl mb-2">{isArchived === true ? variables[lang]['unarchive_deal_warn_message'] : variables[lang]['archive_deal_warn_message']}</h2>
                </DialogContent>
                <DialogActions className="button_div  w-4/5 mx-auto flex justify-between">
                    {inProgress
                        ? <DotsLoading/>
                        : <>
                            <Button className="cancel_button  w-1/2 mx-2 font-oswald-text" onClick={onClose}>
                                {variables[lang].cancel}
                            </Button>
                            <Button className="close_submit  w-1/2 mx-2 font-oswald-text" onClick={archiveDeal}>
                                {isArchived === true ? variables[lang]['unarchive_deal'] : variables[lang]['archive_deal']}
                            </Button>
                        </>
                    }
                </DialogActions>
            </Dialog>
        </>
    );
};

ArchiveDealModal.propTypes = {
    fetchDefault: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    inProgress: PropTypes.bool.isRequired,
    isArchived: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    updateDeal: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        inProgress: state.deals.updateDeal.inProgress,
    };
};

const actionToProps = {
    updateDeal,
};

export default connect(stateToProps, actionToProps)(ArchiveDealModal);
