import React, { useEffect, useState, useContext } from 'react';
import { withRouter } from 'react-router';
import EmojiInput from 'react-input-emoji';
// import Dropzone from 'react-dropzone';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getRecievedMessages, getMessageByID, fetchRecipient } from '../../actions/message';
import variables from '../../utils/variables';
import './index.css';
// import TextField from '../../components/TextField';
import UserInbox from './UserInbox';
import Icon from '../../components/Icon';
import FromDialog from './FromDialog';
import ToDialog from './ToDialog';
import moment from 'moment';
import { SocketContext } from '../../context/socket';
import Snackbar from '../../components/Snackbar';
import CreateMessage from './CreateMessage';

const Inbox = (props) => {
    const {
        getMessageByID,
        getRecievedMessages,
        userData,
        recievedMessages,
        lang,
        conversation,
        match: {
            params: {
                id,
            },
        },
    } = props;
    const [currentId, setCurrentId] = useState('');
    const [messages, setMessages] = useState({});
    const [selectedMessage, setSelectedMessage] = useState({});
    const socket = useContext(SocketContext);
    const [isNewMessage, setNewMessage] = useState(false);
    const [message, setMessage] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('');
    // const [files, setFiles] = useState([]);
    const [formstate, setFormState] = useState({});
    const [closeMessage, setCloseMessage] = useState(true);
    const [isOpenNewMessage, setIsOpenNewMessage] = useState(false);
    const [hasMultipleRecipient, setHasMultipleRecipient] = useState(false);

    useEffect(() => {
        const fetch = async () => {
            await getRecievedMessages(userData?._id);
        };
        if (Object.keys(userData).length > 0) {
            fetch();
        }

        if (userData?._id) {
            setFormState((prevState) => ({ ...prevState, sender: userData?._id, sender_type: 'admin_user' }));
        }
    }, [userData]);

    useEffect(() => {
        if (id && messages && Object.keys(messages).length > 0) {
            const messageObj = messages.filter((item) => {
                return item._id === id;
            });
            getMessageByID(id);
            setCurrentId(id);
            setSelectedMessage(messageObj[0]);
            setFormState((prevState) => ({ ...prevState, parent_id: id }));
            setCloseMessage(false);
            handleConversation(id, messageObj[0]);
        }
    }, [messages]);

    useEffect(() => {
        socket.on('new-single-message', async (result) => {
            await getMessageByID(result);
        });
    }, []);

    useEffect(() => {
        socket.on('new-message', async (result) => {
            if (result.parentId === null) {
                result.receiver = {
                    _id: result.receiver,
                    email_address: result.receiver_email,
                };
                setSelectedMessage(result);
                setCurrentId(result._id);
                getMessageByID(result._id);
                setNewMessage(false);
                setFormState((prevState) => ({ ...prevState, parent_id: result._id }));
            }
        });
    }, []);

    useEffect(() => {
        if (Object.keys(selectedMessage).length > 0) {
            if (typeof selectedMessage?.receiver?._id === 'undefined') {
                return;
            }
            if ((Object.keys(selectedMessage?.receiver?._id).length > 0 && typeof selectedMessage?.receiver?._id !== 'string')) {
                const receiverType = [];
                selectedMessage?.receiver?._id.forEach((id) => {
                    receiverType.push('user');
                });
                setFormState((prevState) => ({ ...prevState, receiver_type: receiverType }));
                setFormState((prevState) => ({ ...prevState, subject: selectedMessage?.title, recipient: selectedMessage?.receiver?._id }));

                if (Object.keys(selectedMessage?.receiver?._id).length > 1) {
                    setHasMultipleRecipient(true);
                } else {
                    setHasMultipleRecipient(false);
                }
            }

            if (typeof selectedMessage?.receiver?._id === 'string') {
                setFormState((prevState) => ({ ...prevState, receiver_type: 'user' }));
                setFormState((prevState) => ({ ...prevState, subject: selectedMessage.title, recipient: userData?._id === selectedMessage.receiver?._id ? selectedMessage.sender?._id : selectedMessage.receiver?._id }));
                setHasMultipleRecipient(false);
            }
        }
    }, [selectedMessage]);

    useEffect(() => {
        if (Object.keys(recievedMessages).length > 0) {
            setMessages(recievedMessages?.result);
        }
    }, [recievedMessages]);

    const handleEmojiChange = (text) => {
        setMessage(text);
        setFormState((prevState) => ({ ...prevState, content: text.trim().replace(/<br\s*\/?>/gi, '') }));
    };

    // const handleFileDrop = (acceptedFiles) => {
    //     setFiles(acceptedFiles);
    //     console.log(files);
    // };

    const snackbarOnClose = () => {
        setSnackbarOpen(false);
    };

    const handleSubmit = () => {
        if (!formstate.parent_id) {
            formstate.receiver_email = selectedMessage.receiver.email_address;
        }
        if (validateForm(formstate)) {
            socket.emit('send-message', formstate, async (error, result) => {
                if (error) {
                    setSnackbarMessage(error);
                    setSnackbarOpen(true);
                    setSnackbarSeverity('error');
                } else {
                    const doc = {
                        from: result.sender,
                        fromType: result.sender_type,
                        to: result.receiver,
                        toType: result.receiver_type,
                        reference: result._id,
                        referenceType: 'message',
                        content: 'New Message Received',
                    };

                    if (result.parentId) {
                        doc.parentReference = result.parentId;
                        doc.parentReferenceType = 'message';
                    }

                    await getRecievedMessages(userData?._id);

                    socket.emit('single-message', { messageID: currentId, receiver: doc.to }, async (error) => {
                        if (error) {
                            console.error('Error emitting single-message event:', error);
                        } else {
                            try {
                                await getMessageByID(currentId);
                            } catch (error) {
                                console.error('Error fetching message by ID:', error);
                            }
                        }
                    });

                    socket.emit('new-notification', doc, async (error) => {
                        if (error) {
                            setSnackbarMessage(error);
                            setSnackbarOpen(true);
                            setSnackbarSeverity('error');
                        } else {
                            setMessage('');
                            await getMessageByID(currentId);
                        }
                    });
                }
            });
        }
        if (hasMultipleRecipient) {
            handleCloseMessage();
        }
    };

    const validateForm = () => {
        if (!formstate.sender || typeof formstate.sender === 'undefined') {
            setSnackbarMessage(variables[lang].message_validate_sender);
            setSnackbarOpen(true);
            setSnackbarSeverity('error');
            return false;
        }

        if (!formstate.recipient || typeof formstate.recipient === 'undefined') {
            setSnackbarMessage(variables[lang].message_validate_recipient);
            setSnackbarOpen(true);
            setSnackbarSeverity('error');
            return false;
        }

        if (!formstate.receiver_type || typeof formstate.receiver_type === 'undefined') {
            setSnackbarMessage(variables[lang].message_validate_receiver_type);
            setSnackbarOpen(true);
            setSnackbarSeverity('error');
            return false;
        }

        if (!formstate.sender_type || typeof formstate.sender_type === 'undefined') {
            setSnackbarMessage(variables[lang].message_validate_sender_type);
            setSnackbarOpen(true);
            setSnackbarSeverity('error');
            return false;
        }

        if (!formstate.subject || typeof formstate.subject === 'undefined') {
            setSnackbarMessage(variables[lang].message_validate_subject);
            setSnackbarOpen(true);
            setSnackbarSeverity('error');
            return false;
        }

        if (!formstate.content || typeof formstate.content === 'undefined') {
            setSnackbarMessage(variables[lang].message_validate_content);
            setSnackbarOpen(true);
            setSnackbarSeverity('error');
            return false;
        }
        return true;
    };

    const handleConversation = (id, message) => {
        getMessageByID(id);
        setCurrentId(id);
        setSelectedMessage(message);
        setFormState((prevState) => ({ ...prevState, parent_id: id }));
        setCloseMessage(false);
        if (userData?._id) {
            setFormState((prevState) => ({ ...prevState, sender: userData?._id, sender_type: 'admin_user' }));
        }
    };

    const handleCloseMessage = () => {
        setCloseMessage(true);
        setSelectedMessage({});
        setMessage('');
        setFormState({});
        setHasMultipleRecipient(false);
    };

    const handleNewMessage = () => {
        setIsOpenNewMessage(true);
    };

    const handleCloseNewMessage = async () => {
        setIsOpenNewMessage(false);
    };

    const handleCreateNewMessage = (data) => {
        setSelectedMessage({
            title: data.subject,
            receiver: {
                _id: data.recipient,
                email_address: data.email_address,
            },
        });
        handleCloseNewMessage();
        setCloseMessage(false);
        setFormState((prevState) => ({ ...prevState, parent_id: null }));
        if (userData?._id) {
            setFormState((prevState) => ({ ...prevState, sender: userData?._id, sender_type: 'admin_user' }));
        }
        setCurrentId('');
        getMessageByID('619cce04b97c3476149f3671');
        setNewMessage(true);
    };

    return (
        <>
            <div className="inbox-message">
                <div className="flex w-full h-full">
                    <div className="flex flex-col lg:w-1/2 xl:w-1/3 2xl:w-1/4 border-r-2 border-gray-700 py-8">
                        <div className="flex justify-center lg:justify-between items-end px-2 lg:px-8">
                            <h1 className="font-oswald-text text-white font-bold text-2xl hidden lg:block">Inbox</h1>
                            <button className="h-6 w-6 mb-4 lg:mb-0 lg:ml-4" onClick={(handleNewMessage)}>
                                <Icon className="new-message" icon="new-message"/>
                            </button>
                        </div>
                        {/* <div className="mb-6 px-8">
                            <TextField
                                className="rounded-full bg-black"
                                id="search"
                                name="search"
                                placeholder="Search here"
                            />
                        </div> */}
                        <div className="h-full overflow-auto px-4 lg:px-8 mt-2">
                            {messages && Object.keys(messages).length > 0 ? (
                                messages.map((message) => (
                                    <UserInbox
                                        key={message?._id}
                                        handleConversation={handleConversation}
                                        id={message?._id}
                                        image={userData?.email_address !== message?.receiver?.email_address ? message?.receiver_profile_image_url : message?.sender_profile_image_url}
                                        message={message}
                                        name={userData?.email_address !== message?.receiver?.email_address ? message?.receiver?.email_address : message?.sender?.email_address}
                                        subject={message?.title}
                                        time={`${moment(message?.created_at).startOf('second').fromNow()}`}
                                    />
                                ))
                            ) : (
                                <h2 className="text-center">{variables[lang].no_message_available}</h2>
                            )}
                        </div>
                    </div>
                    <div className={`flex flex-col w-full lg:w-1/2 xl:w-2/3 2xl:w-3/4 ${closeMessage && 'hidden'}`}>
                        {((messages && Object.keys(messages).length > 0) || isNewMessage) && (
                            <div className="flex flex-col">
                                <div className="flex justify-between w-full font-oswald-text text-white px-4 pt-6 pb-3 bg-custom-dark border-b border-black">
                                    <div className="flex">
                                        <span className="opacity-50 text-lg font-light pr-4">To:</span>
                                        {

                                        }
                                        <span className="w-auto text-lg">{userData?.email_address !== selectedMessage?.receiver?.email_address ? selectedMessage?.receiver?.email_address : selectedMessage?.sender?.email_address}</span>
                                    </div>
                                    <button className="h-6 w-6 ml-4" onClick={handleCloseMessage}>
                                        <Icon className="telegram-red" icon="close"/>
                                    </button>
                                </div>
                                <div className="flex w-full font-oswald-text text-white px-4 pt-3 pb-4 bg-custom-dark">
                                    <span className="opacity-50 text-lg font-light pr-4">Subject:</span>
                                    <span className="w-auto text-lg">{selectedMessage?.title}</span>
                                </div>
                            </div>
                        )}
                        <div className="flex flex-col h-full overflow-auto p-4">
                            {
                                conversation?.length > 0 &&
                                conversation?.map((v, i) => (
                                    <div key={v.message._id} >
                                        {v.sender?.email_address === userData?.email_address ? <FromDialog content={v.message} image={v.sender_profile_image_url} /> : <ToDialog content={v.message} image={v.sender_profile_image_url} />}
                                    </div>
                                ))
                            }
                        </div>
                        <div className="flex px-4 lg:px-6 items-center mb-4 mt-4">
                            <div className="inbox__chat w-full flex items-center">
                                {/* <Dropzone onDrop={handleFileDrop}>
                                    {({ getRootProps, getInputProps }) => (
                                        <div {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <div className="h-6 w-6 mr-2">
                                                <Icon className="paperclip" icon="paperclip"/>
                                            </div>
                                        </div>
                                    )}
                                </Dropzone> */}
                                <div className="inbox__emoji-input w-full rounded-md text-white">
                                    <EmojiInput shouldReturn={true} value={message} onChange={handleEmojiChange} onEnter={handleEmojiChange} />
                                </div>
                            </div>
                            <button className="h-6 w-6 ml-4" onClick={handleSubmit}>
                                <Icon className="telegram-red" icon="telegram-red"/>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Snackbar
                message={snackbarMessage}
                open={snackbarOpen}
                severity={snackbarSeverity}
                onClose={snackbarOnClose}
            />
            <CreateMessage handleClose={handleCloseNewMessage} handleCreateNewMessage={handleCreateNewMessage} id={userData?._id} open={isOpenNewMessage} />
        </>
    );
};

Inbox.propTypes = {
    lang: PropTypes.string.isRequired,
    conversation: PropTypes.array,
    fetchRecipient: PropTypes.func,
    getMessageByID: PropTypes.func,
    getRecievedMessages: PropTypes.func,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }),
    match: PropTypes.shape({
        params: PropTypes.object.isRequired,
    }),
    recievedMessages: PropTypes.any,
    userData: PropTypes.object,
};

const stateToProps = (state) => {
    const { result } = state.messaging.messageByID.message;
    const { value } = state.navigationBar.profileDetails;
    const { recievedMessages } = state.messaging.recievedMessages;

    return {
        conversation: result,
        lang: state.language,
        userData: value,
        recievedMessages,
    };
};

const actionToProps = {
    getMessageByID,
    getRecievedMessages,
    fetchRecipient,
};

export default withRouter(connect(stateToProps, actionToProps)(Inbox));
