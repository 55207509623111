import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { getMessageByID, getMessageSender } from '../../../actions/message';
import { connect } from 'react-redux';
import { Button, Link } from '@material-ui/core';
import variables from '../../../utils/variables';
import Sender from './Sender';

const View = (props) => {
    const {
        getMessageByID,
        match: {
            params: {
                id,
            },
        },
        messages,
        userData,
        lang,
    } = props;
    const [messageCount, setMessageCount] = useState(0);

    useEffect(() => {
        const fetch = async () => {
            await getMessageByID(id);
        };

        fetch();
    }, []);

    useEffect(() => {
        const fetch = async () => {

        };
        if (messages) {
            fetch();
            setMessageCount(Object.keys(messages).length);
        }
    }, [messages]);

    const handleReply = (message) => {
        const sender = Array.isArray(message?.sender) ? message.sender[0]?._id : message.sender._id;
        const receiver = Array.isArray(message?.receiver) ? message.receiver[0]?._id : message.receiver._id;
        const parentId = message?.parentId ? message?.parentId : message?._id;
        if (userData._id === sender) {
            props.history.push(`/message/${receiver}/reply/${parentId}`);
        } else {
            props.history.push(`/message/${sender}/reply/${parentId}`);
        }
    };

    return (
        <>
            <div className="mb-4 pt-10 w-4/5 mx-auto pb-10">
                <div className="flex justify-between items-center">
                    <Link className="text-bold text-custom-white font-oswald-text" href="/inbox">{variables[lang].back}</Link>
                </div>
                <div className="border-2 border-custom-dark rounded-md bg-custom-dark flex flex-col mt-10 p-20 w-full text-custom-white font-oswald-text tracking-wider">
                    {messages && (
                        messages.map((message, index) => (
                            <div key={message?._id}>
                                <div className="my-6 border-b border-slate-500 py-8">
                                    <div className="flex justify-between">
                                        <div>
                                            <h3 className="heading_text text-xl md:text-2xl font-semibold font-oswald-text mb-4 text-white">{message?.title}</h3>
                                        </div>
                                    </div>
                                    <div className="flex w-full">
                                        <div dangerouslySetInnerHTML={{ __html: message?.message }} />
                                    </div>
                                    <div className="flex justify-end md:items-center w-full flex-col md:flex-row mt-14">
                                        <div></div>
                                        <Sender message={message} userData={userData} />
                                    </div>
                                </div>
                                {(index + 1) === messageCount && (
                                    <div className="flex justify-end mt-10">
                                        <Button key={message?.sender} className="message__btn flex font-oswald-text w-52" onClick={() => handleReply(message)}>Reply</Button>
                                    </div>
                                )}
                            </div>
                        ))
                    )}

                </div>
            </div>
        </>
    );
};

View.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    lang: PropTypes.string.isRequired,
    getMessageByID: PropTypes.func,
    getMessageSender: PropTypes.func,
    match: PropTypes.shape({
        params: PropTypes.object.isRequired,
    }),
    messages: PropTypes.array,
    sender: PropTypes.any,
    userData: PropTypes.object,
};

const stateToProps = (state) => {
    const { result } = state.messaging.messageByID.message;
    const { sender } = state.messaging.messageSender.sender;
    const { value } = state.navigationBar.profileDetails;

    return {
        messages: result,
        sender,
        userData: value,
        lang: state.language,
    };
};

const actionToProps = {
    getMessageByID,
    getMessageSender,
};

export default connect(stateToProps, actionToProps)(View);
