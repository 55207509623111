import React, {
    useState,
    useEffect,
    useContext,
} from 'react';
import * as PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import { IconButton } from '@material-ui/core';
import Icon from '../../components/Icon';
import NotificationModal from './NotificationModal';
import { fetchMyNotifications } from '../../actions/notification';
import { getRecievedMessages } from '../../actions/message';
import { fetchTaskList } from '../../actions/tasks';
import { SocketContext } from '../../context/socket';
import './index.scss';

const Notification = (props) => {
    const [open, setOpen] = useState(false);
    const [unread, setUnread] = useState(0);
    const [user, setUser] = useState({});
    const [showAllNotifs, setShowAllNotifs] = useState(true);

    const socket = useContext(SocketContext);

    const {
        notifications,
        fetch,
        fetchTaskList,
        userData,
        getRecievedMessages,
    } = props;

    useEffect(() => {
        fetch();

        socket.on('return-new-notification', () => {
            fetch();
            fetchTaskList('status=OPEN');
        });
    }, []);

    useEffect(() => {
        socket.on('new-message-notif', async (result) => {
            if (user?._id) {
                await getRecievedMessages(user?._id);
            }
        });
    }, [user]);

    useEffect(() => {
        if (Object.keys(userData).length > 0) {
            setUser(userData);
        }
    }, [userData]);

    useEffect(() => {
        if (!isEmpty(notifications)) {
            countUnread();
        }
    }, [notifications]);

    const handleClick = () => {
        if (open) {
            setOpen(false);
        } else {
            setOpen(true);
        }
    };

    const countUnread = () => {
        let unread = 0;
        notifications.forEach((notification) => {
            if (!notification.is_read) {
                unread++;
            }
        });

        setUnread(unread);
    };

    const toggleShowAllNotifs = () => {
        setShowAllNotifs(true);
    };

    const toggleShowUnreadOnly = () => {
        setShowAllNotifs(false);
    };

    return (
        <>
            <IconButton className="notification" onClick={handleClick}>
                <Icon
                    className="notification"
                    icon="notification"/>
                <span className="circle">
                    {
                        unread > 0 && <span>{unread}</span>
                    }
                </span>
            </IconButton>
            <NotificationModal
                close={handleClick}
                fetchNotifications={fetch}
                notifications={notifications}
                open={open}
                showAllNotifs={showAllNotifs}
                toggleShowAllNotifs={toggleShowAllNotifs}
                toggleShowUnreadOnly={toggleShowUnreadOnly}
                unread={unread}
            />
        </>
    );
};

Notification.propTypes = {
    fetch: PropTypes.func.isRequired,
    fetchTaskList: PropTypes.func.isRequired,
    notifications: PropTypes.array.isRequired,
    getRecievedMessages: PropTypes.func,
    userData: PropTypes.object,
};

const stateToProps = (state) => {
    return {
        notifications: state.notification.myNotifications.list,
        userData: state.navigationBar.profileDetails.value,
    };
};

const actionToProps = {
    fetch: fetchMyNotifications,
    fetchTaskList,
    getRecievedMessages,
};

export default connect(stateToProps, actionToProps)(Notification);
