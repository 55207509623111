import { combineReducers } from 'redux';
import {
    HIDE_SIDE_BAR_SET,
    PROFILE_DETAILS_FETCH_ERROR,
    PROFILE_DETAILS_FETCH_IN_PROGRESS,
    PROFILE_DETAILS_FETCH_SUCCESS,
    PROFILE_IMAGE_FETCH_ERROR,
    PROFILE_IMAGE_FETCH_IN_PROGRESS,
    PROFILE_IMAGE_FETCH_SUCCESS,
    SHOW_SIDE_BAR_SET,
    SIGN_OUT_ERROR,
    SIGN_OUT_IN_PROGRESS,
    SIGN_OUT_SUCCESS,
} from '../constants/navigationBar';

const profileDetails = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case PROFILE_DETAILS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case PROFILE_DETAILS_FETCH_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case PROFILE_DETAILS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const profileImage = (state = {
    inProgress: false,
    value: '',
}, action) => {
    switch (action.type) {
    case PROFILE_IMAGE_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case PROFILE_IMAGE_FETCH_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case PROFILE_IMAGE_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const signOutInProgress = (state = false, action) => {
    switch (action.type) {
    case SIGN_OUT_IN_PROGRESS:
        return true;
    case SIGN_OUT_SUCCESS:
    case SIGN_OUT_ERROR:
        return false;
    default:
        return state;
    }
};

const show = (state = false, action) => {
    switch (action.type) {
    case SHOW_SIDE_BAR_SET:
        return true;
    case HIDE_SIDE_BAR_SET:
        return false;
    default:
        return state;
    }
};

export default combineReducers({
    profileDetails,
    profileImage,
    signOutInProgress,
    show,
});
