import { Tab, Tabs } from '@material-ui/core';
import React from 'react';
import './index.css';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import variables from '../../utils/variables';
import { setTabValue } from '../../actions/settings';
import TabPanel from '../../components/TabPanel';
import AccountsTab from './AccountsTab';
import SecurityTab from './SecurityTab';
import ClassNames from 'classnames';
import DealsTab from './DealsTab';

const Settings = (props) => {
    const a11yProps = (index) => {
        return {
            id: `settings-tab-${index}`,
            'aria-controls': `settings-tab-panel-${index}`,
        };
    };

    const handleChange = (event, newValue) => {
        props.onChange(newValue);
    };

    return (
        <div className="w-4/5 mx-auto py-10">
            <p className="heading_text text-3xl md:text-4xl font-semibold font-oswald-text mb-4">{variables[props.lang].settings}</p>
            <div className="tabs_section">
                <Tabs
                    className="vertical_tabs"
                    orientation="horizontal"
                    value={props.value}
                    variant="scrollable"
                    onChange={handleChange}>
                    <Tab
                        className={ClassNames('tab normal-case', props.value === 0 ? 'active' : '')}
                        label={variables[props.lang].account}
                        {...a11yProps(0)} />
                    <Tab
                        className={ClassNames('tab', props.value === 1 ? 'active' : '')}
                        label={variables[props.lang].security}
                        {...a11yProps(1)} />
                    <Tab
                        className={ClassNames('tab', props.value === 2 ? 'active' : '')}
                        label="Deals"
                        {...a11yProps(2)} />
                </Tabs>
                <TabPanel
                    className="tab_content"
                    index={0}
                    value={props.value}>
                    <AccountsTab/>
                </TabPanel>
                <TabPanel
                    className="tab_content authentication_content"
                    index={1}
                    value={props.value}>
                    <SecurityTab/>
                </TabPanel>
                <TabPanel
                    className="tab_content authentication_content"
                    index={2}
                    value={props.value}>
                    <DealsTab/>
                </TabPanel>
            </div>
        </div>
    );
};

Settings.propTypes = {
    lang: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        value: state.settings.tabValue,
    };
};

const actionToProps = {
    onChange: setTabValue,
};

export default connect(stateToProps, actionToProps)(Settings);
