export const MESSAGING_USERS_FETCH_IN_PROGRESS = 'MESSAGING_USERS_FETCH_IN_PROGRESS';
export const MESSAGING_USERS_FETCH_SUCCESS = 'MESSAGING_USERS_FETCH_SUCCESS';
export const MESSAGING_USERS_FETCH_ERROR = 'MESSAGING_USERS_FETCH_ERROR';

export const MESSAGES_FETCH_IN_PROGRESS = 'MESSAGES_FETCH_IN_PROGRESS';
export const MESSAGES_FETCH_SUCCESS = 'MESSAGES_FETCH_SUCCESS';
export const MESSAGES_FETCH_ERROR = 'MESSAGES_FETCH_ERROR';

export const MESSAGES_GET_BY_ID_IN_PROGRESS = 'MESSAGES_GET_BY_ID_IN_PROGRESS';
export const MESSAGES_GET_BY_ID_SUCCESS = 'MESSAGES_GET_BY_ID_SUCCESS';
export const MESSAGES_GET_BY_ID_ERROR = 'MESSAGES_GET_BY_ID_ERROR';

export const MESSAGES_GET_SENDER_IN_PROGRESS = 'MESSAGES_GET_SENDER_IN_PROGRESS';
export const MESSAGES_GET_SENDER_SUCCESS = 'MESSAGES_GET_SENDER_SUCCESS';
export const MESSAGES_GET_SENDER_ERROR = 'MESSAGES_GET_SENDER_ERROR';

export const MESSAGES_GET_RECIPIENT_IN_PROGRESS = 'MESSAGES_GET_RECIPIENT_IN_PROGRESS';
export const MESSAGES_GET_RECIPIENT_SUCCESS = 'MESSAGES_GET_RECIPIENT_SUCCESS';
export const MESSAGES_GET_RECIPIENT_ERROR = 'MESSAGES_GET_RECIPIENT_ERROR';
