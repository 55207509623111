import { combineReducers } from 'redux';
import {
    CONFIRM_PASSWORD_SET,
    EMAIL_ID_SET,
    PASSWORD_SET,
    REMEMBER_PASSWORD_SET,
    USER_SIGN_IN_ERROR,
    USER_SIGN_IN_IN_PROGRESS,
    USER_SIGN_IN_SUCCESS,
} from '../constants/signIn';
import { PASSWORD_UPDATE_ERROR, PASSWORD_UPDATE_SUCCESS } from '../constants/settings';
import {
    RECOVERY_EMAIL_ADD_SUCCESS,
    RECOVERY_PASSWORD_ERROR,
    RECOVERY_PASSWORD_SUCCESS,
} from '../constants/forgotPassword';

const emailId = (state = '', action) => {
    switch (action.type) {
    case EMAIL_ID_SET:
        return action.value;
    case RECOVERY_EMAIL_ADD_SUCCESS:
        return '';
    default:
        return state;
    }
};

const password = (state = '', action) => {
    switch (action.type) {
    case PASSWORD_SET:
        return action.value;
    case PASSWORD_UPDATE_SUCCESS:
        return '';
    default:
        return state;
    }
};

const rememberPassword = (state = false, action) => {
    if (action.type === REMEMBER_PASSWORD_SET) {
        return action.value;
    }

    return state;
};

const inProgress = (state = false, action) => {
    switch (action.type) {
    case USER_SIGN_IN_IN_PROGRESS:
        return true;
    case USER_SIGN_IN_SUCCESS:
    case USER_SIGN_IN_ERROR:
        return false;
    default:
        return state;
    }
};

const confirmPassword = (state = '', action) => {
    switch (action.type) {
    case CONFIRM_PASSWORD_SET:
        return action.value;
    case PASSWORD_UPDATE_SUCCESS:
    case PASSWORD_UPDATE_ERROR:
    case RECOVERY_PASSWORD_SUCCESS:
    case RECOVERY_PASSWORD_ERROR:
        return '';
    default:
        return state;
    }
};

export default combineReducers({
    emailId,
    password,
    rememberPassword,
    inProgress,
    confirmPassword,
});
