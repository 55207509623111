import { Switch } from '@material-ui/core';
import React from 'react';
import * as PropTypes from 'prop-types';
import {
    addTwoFactorAuth,
    setSwitchValue,
    showAuthenticationDialog,
    showAuthenticationSwitchDialog,
} from '../../../actions/settings';
import { connect } from 'react-redux';
import AuthenticateDialog from './Dialog';
import AuthenticateSuccessDialog from './Dialog/SuccessDialog';
import AuthenticateErrorDialog from './Dialog/ErrorDialog';
import SwitchDialog from './SwitchDialog';
import variables from '../../../utils/variables';

const SecurityTab = (props) => {
    const handleChange = (event) => {
        props.showDialog();
        props.onChange(event.target.checked);
    };

    const handleClick = () => {
        props.addTwoFactorAuth();
        props.showAuthenticationDialog();
    };

    return (
        <div className="card settings__item-container pt-6 md:pt-10 flex items-center">
            <div className="flex-1">
                <h3 className="text-base md:text-2xl font-semibold font-oswald-text mb-2">{variables[props.lang]['two_factor_auth']}</h3>
                <p className="text-sm md:text-base font-semibold font-oswald-text text-custom-light-gray">Require a
                    security key or code in addition to your password.</p>
            </div>
            <div>
                {props.profileDetails.two_factor_authentication &&
                props.profileDetails.two_factor_authentication.status !== 'UNVERIFIED'
                    ? <Switch
                        checked={!!(props.profileDetails.two_factor_authentication &&
                            props.profileDetails.two_factor_authentication.status === 'ENABLED')}
                        className="switch"
                        color="primary"
                        name="2fa"
                        onChange={handleChange}/>
                    : <Switch
                        checked={false}
                        className="switch"
                        color="primary"
                        name="2fa"
                        onChange={handleClick}/>
                }
            </div>
            <AuthenticateDialog/>
            <AuthenticateSuccessDialog/>
            <AuthenticateErrorDialog/>
            <SwitchDialog/>
        </div>
    );
};

SecurityTab.propTypes = {
    addTwoFactorAuth: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    showAuthenticationDialog: PropTypes.func.isRequired,
    showDialog: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    profileDetails: PropTypes.shape({
        two_factor_authentication: PropTypes.shape({
            status: PropTypes.string,
        }),
    }),
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        profileDetails: state.navigationBar.profileDetails.value,
    };
};

const actionToProps = {
    showAuthenticationDialog,
    onChange: setSwitchValue,
    addTwoFactorAuth,
    showDialog: showAuthenticationSwitchDialog,
};

export default connect(stateToProps, actionToProps)(SecurityTab);
