import React, { useState } from 'react';
import DataTable from '../../components/DataTable';
import { Button } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import ParticipantsDialog from './ParticipantsDialog';
import ParticipantsColumn from './ParticipantsColumn';
import dots3 from '../../assets/images/dots-3.svg';
import NoTaskItems from './NoTaskItems';
import moment from 'moment';

const OpenTaskTable = ({ tableData, handleOpen, handleEdit, handleArchive }) => {
    const [openParticipantDialog, setOpenParticipantDialog] = useState(false);
    const [displayItemId, setDisplayItemId] = useState(0);
    const [participantItems, setParticipantItems] = useState([]);
    const [taskId, setTaskId] = useState(0);

    const handleViewDetails = (value) => {
        if (displayItemId === 0 || displayItemId !== value) {
            setDisplayItemId(value);
        } else {
            setDisplayItemId(0);
        }
    };

    const handleCloseTwitter = () => {
        setOpenParticipantDialog(false);
    };

    const handleParticipantDialog = (id, value) => {
        setOpenParticipantDialog(true);
        setTaskId(id);
        setParticipantItems(value);
    };

    const handleEditFunc = (value) => {
        handleEdit(value);
        setDisplayItemId(0);
    };

    const handleArchiveFunc = (value) => {
        handleArchive(value);
        setDisplayItemId(0);
    };

    const columns = [
        {
            name: 'type',
            label: 'Type',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => (
                    <div className={`circle circle--${value === 'TWITTER' ? 'blue' : 'yellow'} text-white font-semibold font-oswald-text text-xl flex items-center justify-center`}><span>{value && value.charAt(0)}</span></div>
                ),
            },
        },
        {
            name: 'title',
            label: 'Title',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value) => (
                    <div className="font-semibold font-oswald-text">{value}</div>
                ),
            },
        },
        {
            name: 'deadline_at',
            label: 'Deadline',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value) => (
                    <div className="font-semibold font-oswald-text">{value && moment(value).format('MMMM DD, YYYY')}</div>
                ),
            },
        },
        {
            name: 'participants',
            label: 'Participants',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <div className="participant-wrapper flex flex-row items-center cursor-pointer" onClick={() => handleParticipantDialog(tableMeta.tableData[tableMeta.rowIndex]._id, value)}>
                        <ParticipantsColumn participant={value} />
                    </div>
                ),
            },
        },
        {
            name: '_id',
            label: 'Action',
            options: {
                filter: false,
                sort: false,
                // eslint-disable-next-line react/display-name
                customBodyRender: (value) => (
                    <div className="task-action__container relative">
                        <Button
                            onClick={() => handleViewDetails(value)}
                        >
                            <img alt="dots" src={dots3} />
                        </Button>
                        <div className={`task-action__options flex flex-col absolute ${displayItemId === value ? '' : 'hidden'}`}>
                            <span onClick={() => handleEditFunc(value)}>Edit</span>
                            {/* <span onClick={() => handleDelete(value)}>Delete</span> */}
                            <span onClick={() => handleArchiveFunc(value)}>Archive</span>
                        </div>
                    </div>
                ),
            },
        },
    ];

    const options = {
        selectableRows: 'none',
    };

    return (
        <div className="table">
            {(tableData.length > 0)
                ? <>
                    <DataTable
                        columns={columns}
                        data={tableData}
                        name="Deals"
                        options={options}/>
                    <ParticipantsDialog handleClose={handleCloseTwitter} isArchived={false} open={openParticipantDialog} participantItems={participantItems} taskId={taskId}/>
                </> : <NoTaskItems handleOpen={handleOpen} />
            }
        </div>
    );
};

OpenTaskTable.propTypes = {
    handleArchive: PropTypes.func,
    handleEdit: PropTypes.func,
    handleOpen: PropTypes.func,
    tableData: PropTypes.array,
};

export default OpenTaskTable;
