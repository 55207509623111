import React, { useEffect, useState } from 'react';
import { EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import * as PropTypes from 'prop-types';

import EditorWrapper from './EditorWrapper';

const TextArea = ({
    className,
    name,
    onChangeTextarea,
}) => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    useEffect(() => {
        const contentState = editorState.getCurrentContent();
        const contentHTML = stateToHTML(contentState);
        onChangeTextarea(name, contentHTML, contentState.getPlainText());
    }, [editorState]);

    function handleEditorChange (newState) {
        setEditorState(newState);
    }

    return (
        <div className={`bg-custom-dark text-white p-4 ${className}`}>
            <div className="pt-4 h-5/6">
                <EditorWrapper
                    editorState={editorState}
                    onEditorStateChange={(state) => handleEditorChange(state)}
                />
            </div>
        </div>
    );
};

TextArea.propTypes = {
    className: PropTypes.string,
    name: PropTypes.string,
    onChangeTextarea: PropTypes.func,
};

export default TextArea;
