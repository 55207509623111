import React, { useState } from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { updateDeal } from '../../../actions/deals';
import DotsLoading from '../../../components/DotsLoading';
import Snackbar from '../../../components/Snackbar';
import variables from '../../../utils/variables';
import './index.css';

const CloseDealModal = (props) => {
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const {
        open,
        onClose,
        lang,
        inProgress,
        id,
        onSuccess,
        fetchDefault,
        updateDeal,
        status,
    } = props;

    const closeDeal = () => {
        const data = {};
        if (status === 'ACTIVE') {
            data.status = 'CLOSED';
        } else {
            data.status = 'ACTIVE';
        }
        updateDeal(id, data, (error) => {
            if (!error && !inProgress) {
                onSuccess(variables[lang]['update_deal_success']);
                fetchDefault();
                onClose();
            } else if (error) {
                setSnackbarMessage(`Error: ${error}`);
                setOpenSnackbar(true);
            }
        });
    };

    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };

    return (
        <>
            <Snackbar
                message={snackbarMessage}
                open={openSnackbar}
                severity="error"
                onClose={handleSnackbarClose}
            />
            <Dialog
                aria-describedby="deal-dialog-description"
                aria-labelledby="deal-dialog-title"
                className="dialog close_deal_dialog rounded-lg"
                open={open}
                onClose={onClose}
            >
                <DialogContent className="content text-center">
                    <h2 className="font-oswald-text text-white font-bold text-2xl mb-2">{status === 'ACTIVE' ? variables[lang]['close_deal_warn_message'] : variables[lang]['reopen_deal_warn_message']}</h2>
                </DialogContent>
                <DialogActions className="button_div  w-4/5 mx-auto flex justify-between">
                    {inProgress
                        ? <DotsLoading/>
                        : <>
                            <Button className="cancel_button  w-1/2 mx-2 font-oswald-text" onClick={onClose}>
                                {variables[lang].cancel}
                            </Button>
                            <Button className="close_submit  w-1/2 mx-2 font-oswald-text" onClick={closeDeal}>
                                {status === 'ACTIVE' ? variables[lang]['close_deal'] : variables[lang]['reopen_deal']}
                            </Button>
                        </>
                    }
                </DialogActions>
            </Dialog>
        </>
    );
};

CloseDealModal.propTypes = {
    fetchDefault: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    inProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    status: PropTypes.string.isRequired,
    updateDeal: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        inProgress: state.deals.updateDeal.inProgress,
    };
};

const actionToProps = {
    updateDeal,
};

export default connect(stateToProps, actionToProps)(CloseDealModal);
