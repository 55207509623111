import { REFERRALS_LIST_URL } from '../constants/url';
import Axios from 'axios';
import {
    REFERRALS_LIST_FETCH_ERROR,
    REFERRALS_LIST_FETCH_IN_PROGRESS,
    REFERRALS_LIST_FETCH_SUCCESS,
} from '../constants/referrals';

const fetchReferralsListInProgress = () => {
    return {
        type: REFERRALS_LIST_FETCH_IN_PROGRESS,
    };
};

const fetchReferralsListSuccess = (value) => {
    return {
        type: REFERRALS_LIST_FETCH_SUCCESS,
        value,
    };
};

const fetchReferralsListError = (message) => {
    return {
        type: REFERRALS_LIST_FETCH_ERROR,
        message,
    };
};

export const fetchReferralsList = () => (dispatch) => {
    dispatch(fetchReferralsListInProgress());

    Axios.get(REFERRALS_LIST_URL, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
    })
        .then((res) => {
            dispatch(fetchReferralsListSuccess(res.data && res.data.result));
        })
        .catch((error) => {
            dispatch(fetchReferralsListError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};
