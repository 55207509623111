import React, { useContext, useState, useEffect } from 'react';
import OvalImg from '../../assets/images/oval.png';
import DealImg from '../../assets/images/deal-image.png';
import ContributeImg from '../../assets/images/contribute.png';
import TaskImg from '../../assets/images/task.png';
import DefaultImg from '../../assets/profile.png';
import { SocketContext } from '../../context/socket';
import * as PropTypes from 'prop-types';

const NotificationImg = (props) => {
    const { notif } = props;
    const socket = useContext(SocketContext);
    const [img, setImg] = useState('');

    useEffect(() => {
        handleNotificationImg(notif);
    }, [notif._id]);

    function getImage (notif) {
        return new Promise((resolve, reject) => {
            let img;
            switch (notif.reference_type) {
            case 'deal':
                img = DealImg;
                resolve(img);
                break;
            case 'task':
                img = TaskImg;
                resolve(img);
                break;
            case 'contributor':
                img = ContributeImg;
                resolve(img);
                break;
            case 'message':
                if (notif.from) {
                    socket.emit('profile-image', { id: notif.from._id, fromType: notif.from_type }, (error, result) => {
                        if (!error) {
                            img = result;
                            resolve(img);
                        } else {
                            console.error(error);
                            resolve(DefaultImg);
                        }
                    });
                } else {
                    img = DefaultImg;
                    resolve(img);
                }
                break;
            default:
                img = OvalImg;
                resolve(img);
                break;
            }
        });
    }

    const handleNotificationImg = async (notif) => {
        try {
            const img = await getImage(notif);
            setImg(img);
        } catch (error) {
            console.error(error);
        }
    };
    return (
        <>
            <div className="notification__notif-image shrink-0">
                <img alt="image" src={img}/>
            </div>
        </>
    );
};

NotificationImg.propTypes = {
    notif: PropTypes.object,
};

export default NotificationImg;
