import { combineReducers } from 'redux';
import {
    DEFAULT_COUNT,
    DEFAULT_LIMIT,
    DEFAULT_ORDER,
    DEFAULT_SEARCH_KEY,
    DEFAULT_SKIP,
    DEFAULT_SORT_BY,
} from '../constants/url';
import {
    CARDS_INFO_FETCH_ERROR,
    CARDS_INFO_FETCH_IN_PROGRESS,
    CARDS_INFO_FETCH_SUCCESS,
    EXNG_CLAIMS_FETCH_ERROR,
    EXNG_CLAIMS_FETCH_IN_PROGRESS,
    EXNG_CLAIMS_FETCH_SUCCESS,
} from '../constants/dashboard';

const list = (state = {
    inProgress: false,
    list: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    count: DEFAULT_COUNT,
    searchKey: DEFAULT_SEARCH_KEY,
    sortBy: DEFAULT_SORT_BY,
    order: DEFAULT_ORDER,
}, action) => {
    switch (action.type) {
    case EXNG_CLAIMS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case EXNG_CLAIMS_FETCH_SUCCESS:
        return {
            inProgress: false,
            list: action.value.list,
            count: action.value.count,
            skip: action.skip,
            limit: action.limit,
            searchKey: action.searchKey,
            sortBy: action.sortBy,
            order: action.order,
        };
    case EXNG_CLAIMS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const cards = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case CARDS_INFO_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case CARDS_INFO_FETCH_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case CARDS_INFO_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

export default combineReducers({
    list,
    cards,
});
