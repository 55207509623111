import { combineReducers } from 'redux';
import {
    ADD_DISTRIBUTION_IN_PROGRESS,
    ADD_DISTRIBUTION_SUCCESS,
    ADD_DISTRIBUTION_ERROR,
    FETCH_DISTRIBUTION_IN_PROGRESS,
    FETCH_DISTRIBUTION_SUCCESS,
    FETCH_DISTRIBUTION_ERROR,
    UPDATE_DISTRIBUTION_IN_PROGRESS,
    UPDATE_DISTRIBUTION_SUCCESS,
    UPDATE_DISTRIBUTION_ERROR,
    UPDATE_DISTRIBUTION_ITEM_IN_PROGRESS,
    UPDATE_DISTRIBUTION_ITEM_SUCCESS,
    UPDATE_DISTRIBUTION_ITEM_ERROR,
} from '../constants/distribution';

const addDistributionDetails = (state = {
    inProgress: false,
    message: '',
}, action) => {
    switch (action.type) {
    case ADD_DISTRIBUTION_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case ADD_DISTRIBUTION_SUCCESS:
        return {
            inProgress: false,
            message: action.message,
        };
    case ADD_DISTRIBUTION_ERROR:
        return {
            inProgress: false,
            message: action.message,
        };
    default:
        return state;
    }
};

const distributionDetails = (state = {
    inProgress: false,
    result: {},
}, action) => {
    switch (action.type) {
    case FETCH_DISTRIBUTION_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_DISTRIBUTION_SUCCESS:
        return {
            inProgress: false,
            result: action.item,
        };
    case FETCH_DISTRIBUTION_ERROR:
        return {
            ...state,
            inProgress: false,
            result: {},
        };
    default:
        return state;
    }
};

const updateDistribution = (state = {
    inProgress: false,
    result: {},
    message: '',
}, action) => {
    switch (action.type) {
    case UPDATE_DISTRIBUTION_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case UPDATE_DISTRIBUTION_SUCCESS:
        return {
            inProgress: false,
            result: action.item,
            message: action.message,
        };
    case UPDATE_DISTRIBUTION_ERROR:
        return {
            ...state,
            inProgress: false,
            result: {},
        };
    default:
        return state;
    }
};

const updateDistributionItem = (state = {
    inProgress: false,
    result: {},
    message: '',
}, action) => {
    switch (action.type) {
    case UPDATE_DISTRIBUTION_ITEM_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case UPDATE_DISTRIBUTION_ITEM_SUCCESS:
        return {
            inProgress: false,
            result: action.item,
            message: action.message,
        };
    case UPDATE_DISTRIBUTION_ITEM_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

export default combineReducers({
    addDistributionDetails,
    distributionDetails,
    updateDistribution,
    updateDistributionItem,
});
