import React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Dialog, DialogContent } from '@material-ui/core';
import PasswordTextField from '../../SignIn/PasswordTextField';
import { hidechangePasswordDialog, updatePassword } from '../../../actions/settings';
import { fetchProfileDetails } from '../../../actions/navigationBar';
import ChangePasswordButton from './ChangePasswordButton';
import ConfirmPasswordTextField from '../../RecoveryPassword/ConfirmPasswordTextField';
import { encryptToken } from '../../../utils/crypto';
import './index.css';
import CloseDialog from '../../../components/CloseDialog';

const ChangePasswordForm = (props) => {
    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && !disable) {
            handleUpdateProfile();
        }
    };

    const handleUpdateProfile = () => {
        const data = {
            password: encryptToken(props.password),
        };

        props.updatePassword(data, (error) => {
            if (!error) {
                props.fetchProfileDetails();
            }
        });

        hideChangePassword();
    };

    const hideChangePassword = () => {
        props.hidechangePasswordDialog();
    };

    const disable = props.password === '' || props.confirmPassword === '' ||
        props.password !== props.confirmPassword || props.password.length < 8 || props.inProgress;

    return (
        <Dialog
            aria-describedby="change-password-description"
            aria-labelledby="change-password-title"
            className="w-full change-password "
            open={props.showChangePassword}>
            <DialogContent className="change-password__content font-oswald-text bg-custom-dark rounded-lg mb-4 relative">
                <form
                    noValidate
                    autoComplete="off"
                    className="card w-full md:p-4 text-white"
                    onKeyPress={handleKeyPress}>
                    <h3 className="font-oswald-text font-bold text-2xl mb-4">Change Password</h3>
                    <CloseDialog className="absolute top-6 md:top-9 right-4 md:right-10" onClose={hideChangePassword}/>
                    <div className="flex flex-col ">
                        <PasswordTextField/>
                    </div>
                    <div className="flex flex-col">
                        <ConfirmPasswordTextField/>
                    </div>
                    <div className="button_div flex justify-between mt-4">
                        <Button
                            className="w-1/2 min-w-0 mx-2 font-oswald-text cancel_button"
                            onClick={hideChangePassword}>Cancel</Button>
                        <ChangePasswordButton disable={disable} lang={props.lang} onClick={handleUpdateProfile}/>
                    </div>
                </form>
            </DialogContent>
        </Dialog>
    );
};

ChangePasswordForm.propTypes = {
    confirmPassword: PropTypes.string.isRequired,
    fetchProfileDetails: PropTypes.func.isRequired,
    hidechangePasswordDialog: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    showChangePassword: PropTypes.bool.isRequired,
    updatePassword: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        confirmPassword: state.signIn.confirmPassword,
        inProgress: state.settings.changePassword.inProgress,
        lang: state.language,
        password: state.signIn.password,
        showChangePassword: state.settings.changePasswordDialog,
    };
};

const actionToProps = {
    fetchProfileDetails,
    updatePassword,
    hidechangePasswordDialog,
};

export default connect(stateToProps, actionToProps)(ChangePasswordForm);
