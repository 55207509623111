import {
    AUTHENTICATION_CODE_SET,
    AUTHENTICATION_DIALOG_HIDE,
    AUTHENTICATION_DIALOG_SHOW,
    AUTHENTICATION_ERROR_DIALOG_HIDE,
    AUTHENTICATION_ERROR_DIALOG_SHOW,
    AUTHENTICATION_SUCCESS_DIALOG_HIDE,
    AUTHENTICATION_SUCCESS_DIALOG_SHOW,
    AUTHENTICATION_SWITCH_VALUE_SET,
    AUTHENTICATION_SWITCH_VERIFY_DIALOG_HIDE,
    AUTHENTICATION_SWITCH_VERIFY_DIALOG_SHOW,
    CHANGE_PASSSWORD_DIALOG_HIDE,
    CHANGE_PASSSWORD_DIALOG_SHOW,
    HIDE_DEAL_FORM,
    PASSWORD_UPDATE_ERROR,
    PASSWORD_UPDATE_IN_PROGRESS,
    PASSWORD_UPDATE_SUCCESS,
    SETTING_DEAL_TIER_LIMIT,
    SETTING_DEAL_TIME,
    SETTING_TIMEZONE,
    SETTING_TIMEZONE_VALUE,
    SETTINGS_TAB_VALUE_SET,
    SHOW_DEAL_FORM,
    TWO_FACTOR_AUTH_ADD_ERROR,
    TWO_FACTOR_AUTH_ADD_IN_PROGRESS,
    TWO_FACTOR_AUTH_ADD_SUCCESS,
    TWO_FACTOR_AUTH_UPDATE_ERROR,
    TWO_FACTOR_AUTH_UPDATE_IN_PROGRESS,
    TWO_FACTOR_AUTH_UPDATE_SUCCESS,
    TWO_FACTOR_AUTH_VERIFY_BACKUP_TOKEN_ERROR,
    TWO_FACTOR_AUTH_VERIFY_BACKUP_TOKEN_IN_PROGRESS,
    TWO_FACTOR_AUTH_VERIFY_BACKUP_TOKEN_SUCCESS,
    TWO_FACTOR_AUTH_VERIFY_ERROR,
    TWO_FACTOR_AUTH_VERIFY_IN_PROGRESS,
    TWO_FACTOR_AUTH_VERIFY_SUCCESS,
} from '../constants/settings';
import Axios from 'axios';
import {
    PROFILE_DETAILS_FETCH,
    TWO_FACTOR_AUTH_ACTIVATE,
    TWO_FACTOR_AUTH_ADD,
    TWO_FACTOR_AUTH_VERIFY,
    TWO_FACTOR_AUTH_VERIFY_BACKUP_TOKEN,
    update2FA,
} from '../constants/url';

const updatePasswordInProgress = () => {
    return {
        type: PASSWORD_UPDATE_IN_PROGRESS,
    };
};

const updatePasswordSuccess = (message) => {
    return {
        type: PASSWORD_UPDATE_SUCCESS,
        message,
    };
};

const updatePasswordError = (message) => {
    return {
        type: PASSWORD_UPDATE_ERROR,
        message,
    };
};

export const updatePassword = (data, cb) => (dispatch) => {
    dispatch(updatePasswordInProgress());

    Axios.put(PROFILE_DETAILS_FETCH, data, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
    })
        .then((res) => {
            dispatch(updatePasswordSuccess('Success'));
            cb(null);
        })
        .catch((error) => {
            dispatch(updatePasswordError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            cb(error);
        });
};

export const setTabValue = (value) => {
    return {
        type: SETTINGS_TAB_VALUE_SET,
        value,
    };
};

export const setSwitchValue = (value) => {
    return {
        type: AUTHENTICATION_SWITCH_VALUE_SET,
        value,
    };
};

export const showchangePasswordDialog = () => {
    return {
        type: CHANGE_PASSSWORD_DIALOG_SHOW,
    };
};

export const hidechangePasswordDialog = () => {
    return {
        type: CHANGE_PASSSWORD_DIALOG_HIDE,
    };
};

export const showAuthenticationDialog = () => {
    return {
        type: AUTHENTICATION_DIALOG_SHOW,
    };
};

export const hideAuthenticationDialog = () => {
    return {
        type: AUTHENTICATION_DIALOG_HIDE,
    };
};

export const setAuthenticationCode = (value) => {
    return {
        type: AUTHENTICATION_CODE_SET,
        value,
    };
};

export const showAuthenticationSuccessDialog = () => {
    return {
        type: AUTHENTICATION_SUCCESS_DIALOG_SHOW,
    };
};

export const hideAuthenticationSuccessDialog = () => {
    return {
        type: AUTHENTICATION_SUCCESS_DIALOG_HIDE,
    };
};

export const showAuthenticationErrorDialog = () => {
    return {
        type: AUTHENTICATION_ERROR_DIALOG_SHOW,
    };
};

export const hideAuthenticationErrorDialog = () => {
    return {
        type: AUTHENTICATION_ERROR_DIALOG_HIDE,
    };
};

const twoFactorAuthAddInProgress = () => {
    return {
        type: TWO_FACTOR_AUTH_ADD_IN_PROGRESS,
    };
};

const twoFactorAuthAddSuccess = (data) => {
    return {
        type: TWO_FACTOR_AUTH_ADD_SUCCESS,
        data,
    };
};

const twoFactorAuthAddError = (message) => {
    return {
        type: TWO_FACTOR_AUTH_ADD_ERROR,
        message,
    };
};

export const addTwoFactorAuth = (data = {}) => (dispatch) => {
    dispatch(twoFactorAuthAddInProgress());

    Axios.post(TWO_FACTOR_AUTH_ADD, data,
        {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
            },
        })
        .then((res) => {
            dispatch(twoFactorAuthAddSuccess(res.data && res.data.result));
        })
        .catch((error) => {
            dispatch(twoFactorAuthAddError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const twoFactorAuthVerifyInProgress = () => {
    return {
        type: TWO_FACTOR_AUTH_VERIFY_IN_PROGRESS,
    };
};

const twoFactorAuthVerifySuccess = (data) => {
    return {
        type: TWO_FACTOR_AUTH_VERIFY_SUCCESS,
        data,
    };
};

const twoFactorAuthVerifyError = (message) => {
    return {
        type: TWO_FACTOR_AUTH_VERIFY_ERROR,
        message,
    };
};

export const verifyTwoFactorAuth = (data, cb) => (dispatch) => {
    dispatch(twoFactorAuthVerifyInProgress());

    Axios.post(TWO_FACTOR_AUTH_VERIFY, data,
        {
            headers: {
                Accept: 'application/json',
            },
        })
        .then((res) => {
            localStorage.setItem('accessToken', res.data.result['access_token']);
            localStorage.setItem('refreshToken', res.data.result['refresh_token']);

            dispatch(twoFactorAuthVerifySuccess(res.data && res.data.result));
            cb(null);
        })
        .catch((error) => {
            dispatch(twoFactorAuthVerifyError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            cb(error);
        });
};

const twoFactorAuthUpdateInProgress = () => {
    return {
        type: TWO_FACTOR_AUTH_UPDATE_IN_PROGRESS,
    };
};

const twoFactorAuthUpdateSuccess = (data) => {
    return {
        type: TWO_FACTOR_AUTH_UPDATE_SUCCESS,
        data,
    };
};

const twoFactorAuthUpdateError = (message) => {
    return {
        type: TWO_FACTOR_AUTH_UPDATE_ERROR,
        message,
    };
};

export const updateTwoFactorAuth = (type, data, cb) => (dispatch) => {
    dispatch(twoFactorAuthUpdateInProgress());
    const url = update2FA(type);

    Axios.post(url, data,
        {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
            },
        })
        .then((res) => {
            dispatch(twoFactorAuthUpdateSuccess(res.data && res.data.result));
            cb(null);
        })
        .catch((error) => {
            dispatch(twoFactorAuthUpdateError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            cb(error);
        });
};

const twoFactorAuthActivationInProgress = () => {
    return {
        type: TWO_FACTOR_AUTH_VERIFY_IN_PROGRESS,
    };
};

const twoFactorAuthActivationSuccess = (data) => {
    return {
        type: TWO_FACTOR_AUTH_VERIFY_SUCCESS,
        data,
    };
};

const twoFactorAuthActivationError = (message) => {
    return {
        type: TWO_FACTOR_AUTH_VERIFY_ERROR,
        message,
    };
};

export const activateTwoFactorAuth = (data, cb) => (dispatch) => {
    dispatch(twoFactorAuthActivationInProgress());

    Axios.post(TWO_FACTOR_AUTH_ACTIVATE, data,
        {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
            },
        })
        .then((res) => {
            dispatch(twoFactorAuthActivationSuccess(res.data && res.data.result));
            cb(null);
        })
        .catch((error) => {
            dispatch(twoFactorAuthActivationError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            cb(error);
        });
};

const twoFactorAuthVerifyBackupTokenInProgress = () => {
    return {
        type: TWO_FACTOR_AUTH_VERIFY_BACKUP_TOKEN_IN_PROGRESS,
    };
};

const twoFactorAuthVerifyBackupTokenSuccess = (message) => {
    return {
        type: TWO_FACTOR_AUTH_VERIFY_BACKUP_TOKEN_SUCCESS,
        message,
    };
};

const twoFactorAuthVerifyBackupTokenError = (message) => {
    return {
        type: TWO_FACTOR_AUTH_VERIFY_BACKUP_TOKEN_ERROR,
        message,
    };
};

export const twoFactorAuthVerifyBackupToken = (data, cb) => (dispatch) => {
    dispatch(twoFactorAuthVerifyBackupTokenInProgress());

    Axios.post(TWO_FACTOR_AUTH_VERIFY_BACKUP_TOKEN, data,
        {
            headers: {
                Accept: 'application/json',
            },
        })
        .then((res) => {
            localStorage.setItem('accessToken', res.data.result['access_token']);
            localStorage.setItem('refreshToken', res.data.result['refresh_token']);

            dispatch(twoFactorAuthVerifyBackupTokenSuccess('Success!'));
            cb(null);
        })
        .catch((error) => {
            dispatch(twoFactorAuthVerifyBackupTokenError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            cb(error);
        });
};

export const showAuthenticationSwitchDialog = () => {
    return {
        type: AUTHENTICATION_SWITCH_VERIFY_DIALOG_SHOW,
    };
};

export const hideAuthenticationSwitchDialog = () => {
    return {
        type: AUTHENTICATION_SWITCH_VERIFY_DIALOG_HIDE,
    };
};

// settings deals

export const setSelectedTimezone = (value) => {
    return {
        type: SETTING_TIMEZONE,
        value,
    };
};

export const setSelectedTimezoneValue = (value) => {
    return {
        type: SETTING_TIMEZONE_VALUE,
        value,
    };
};

export const setSelectedTime = (value) => {
    return {
        type: SETTING_DEAL_TIME,
        value,
    };
};

export const setSelectedTierLimit = (value) => {
    return {
        type: SETTING_DEAL_TIER_LIMIT,
        value,
    };
};

export const showDealForm = () => {
    return {
        type: SHOW_DEAL_FORM,
    };
};

export const hideDealForm = () => {
    return {
        type: HIDE_DEAL_FORM,
    };
};
