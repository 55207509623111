export const ADD_DISTRIBUTION_IN_PROGRESS = 'ADD_DISTRIBUTION_IN_PROGRESS';
export const ADD_DISTRIBUTION_SUCCESS = 'ADD_DISTRIBUTION_SUCCESS';
export const ADD_DISTRIBUTION_ERROR = 'ADD_DISTRIBUTION_ERROR';

export const FETCH_DISTRIBUTION_IN_PROGRESS = 'FETCH_DISTRIBUTION_IN_PROGRESS';
export const FETCH_DISTRIBUTION_SUCCESS = 'FETCH_DISTRIBUTION_SUCCESS';
export const FETCH_DISTRIBUTION_ERROR = 'FETCH_DISTRIBUTION_ERROR';

export const UPDATE_DISTRIBUTION_IN_PROGRESS = 'UPDATE_DISTRIBUTION_IN_PROGRESS';
export const UPDATE_DISTRIBUTION_SUCCESS = 'UPDATE_DISTRIBUTION_SUCCESS';
export const UPDATE_DISTRIBUTION_ERROR = 'UPDATE_DISTRIBUTION_ERROR';

export const UPDATE_DISTRIBUTION_ITEM_IN_PROGRESS = 'UPDATE_DISTRIBUTION_ITEM_IN_PROGRESS';
export const UPDATE_DISTRIBUTION_ITEM_SUCCESS = 'UPDATE_DISTRIBUTION_ITEM_SUCCESS';
export const UPDATE_DISTRIBUTION_ITEM_ERROR = 'UPDATE_DISTRIBUTION_ITEM_ERROR';
