import React from 'react';
import './app.css';
import Router from './Router';
import Snackbar from './containers/Snackbar';
import { SocketContext, socket } from './context/socket';

const App = () => {
    return (
        <SocketContext.Provider value={socket}>
            <div className="ex_network">
                <Router/>
                <Snackbar/>
            </div>
        </SocketContext.Provider>
    );
};

export default App;
