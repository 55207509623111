import { combineReducers } from 'redux';
import {
    REFERRALS_LIST_FETCH_ERROR,
    REFERRALS_LIST_FETCH_IN_PROGRESS,
    REFERRALS_LIST_FETCH_SUCCESS,
} from '../constants/referrals';

const tableData = (state = {
    inProgress: false,
    list: [],
}, action) => {
    switch (action.type) {
    case REFERRALS_LIST_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case REFERRALS_LIST_FETCH_SUCCESS:
        return {
            inProgress: false,
            list: action.value,
        };
    case REFERRALS_LIST_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

export default combineReducers({
    tableData,
});
