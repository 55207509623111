import React from 'react';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setUnstakeFilter } from '../../../actions/unstakeRequests';

const UnstakedFilter = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleUpdate = (value) => {
        props.setUnstakeFilter(value);
        handleClose();
    };

    return (
        <>
            <IconButton
                aria-controls="unstake-filter-menu"
                aria-haspopup="true"
                className="filter right-1 top-1"
                onClick={handleClick}>
                <MoreVertIcon/>
            </IconButton>
            <Menu
                keepMounted
                anchorEl={anchorEl}
                id="unstake-filter-menu"
                open={Boolean(anchorEl)}
                onClose={handleClose}>
                {props.options &&
                props.options.map((value) => (
                    <MenuItem key={value} onClick={() => handleUpdate(value)}>
                        {value}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};

UnstakedFilter.propTypes = {
    options: PropTypes.array.isRequired,
    setUnstakeFilter: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        options: state.users.filterOptions,
    };
};

const actionToProps = {
    setUnstakeFilter,
};

export default connect(stateToProps, actionToProps)(UnstakedFilter);
