import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import { hideSnackbar } from '../actions/snackbar';
import Snackbar from '../components/Snackbar';
import { fetchAccessToken } from '../actions/accessToken';
import { withRouter } from 'react-router';
import ErrorDialog from '../containers/Tasks/ErrorDialog';
import DoneDialog from '../containers/Tasks/DoneDialog';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../utils/variables';

class SnackbarMessage extends Component {
    componentDidUpdate (pp, ps, ss) {
        if (pp.message !== this.props.message) {
            switch (this.props.message) {
            case 'Token is expired':
            case 'Error occurred while verifying the JWT token.':
                this.props.onClose();

                if (this.props.tokenInProgress) {
                    return;
                }

                return this.props.fetchAccessToken((error) => {
                    if (error) {
                        this.props.history.push('/');
                    }
                });
            default:
                break;
            }
        }
    }

    render () {
        if (this.props.message && ERROR_MESSAGE.includes(this.props.message)) {
            return (
                <ErrorDialog
                    handleClose={this.props.onClose}
                    open={this.props.open}/>
            );
        } else if (this.props.message && SUCCESS_MESSAGE.includes(this.props.message)) {
            return (
                <DoneDialog
                    handleClose={this.props.onClose}
                    open={this.props.open}/>
            );
        } else {
            return (
                <Snackbar
                    message={this.props.message}
                    open={this.props.open}
                    onClose={this.props.onClose}/>
            );
        }
    }
}

SnackbarMessage.propTypes = {
    fetchAccessToken: PropTypes.func.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    message: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    tokenInProgress: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        open: state.snackbar.open,
        message: state.snackbar.message,
        tokenInProgress: state.accessToken.inProgress,
    };
};

const actionsToProps = {
    fetchAccessToken,
    onClose: hideSnackbar,
};

export default withRouter(connect(stateToProps, actionsToProps)(SnackbarMessage));
