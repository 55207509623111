import { Button, Snackbar } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
// import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import variables from '../../utils/variables';
import Icon from '../../components/Icon';
// import bannerImage from '../../assets/images/Rectangle 2.jpg';
import { updateBannerPic, fetchBannerImage } from '../../actions/me';

const Banner = (props) => {
    const {
        fetchBannerPic,
        upload,
        bannerImage,
    } = props;
    const [banner, setBanner] = useState('');
    const [selectedFile, setSelectedFile] = useState({});
    const [snackbarType, setSnackbarType] = useState('success');
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    // const [isFilePicked, setIsFilePicked] = useState(false);
    const hiddenFileInput = React.useRef(null);
    const handleClick = () => {
        hiddenFileInput.current.click();
    };

    const onerror = (message) => {
        setSnackbarType('error');
        setSnackbarMessage(message);
        setSnackbarOpen(true);
    };

    const onSuccess = () => {
        setSnackbarType('success');
        setSnackbarMessage('Successfully update banner');
        setSnackbarOpen(true);
    };

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        const file = new FormData();
        file.append('file', event.target.files[0]);
        upload(file, async (error) => {
            if (!error) {
                await onSuccess();
                fetchBannerPic();
            } else if (error) {
                onerror(`${error}`);
            }
        });
    };

    const snackbarOnClose = () => {
        setSnackbarOpen(false);
    };

    useEffect(() => {
        props.handleProfilePic(selectedFile);
    }, [selectedFile]);

    useEffect(() => {
        if (!isEmpty(bannerImage)) {
            setBanner(bannerImage.url);
        }
    }, [bannerImage]);

    // const bannerImg = {
    //     backgroundImage: `url(${bannerImage})`,
    // };

    return (
        <>
            <div className="profile-page__banner">
                <img alt="Banner Image" src={banner} />
                <input
                    ref={hiddenFileInput}
                    name="file"
                    style={{ display: 'none' }}
                    type="file"
                    onChange={changeHandler}
                />
                <Button
                    className="btn__banner"
                    onClick={handleClick}
                >
                    <Icon
                        className="camera"
                        icon="camera"/>
                    {variables[props.lang].change_photo}
                </Button>
            </div>
            <Snackbar
                message={snackbarMessage}
                open={snackbarOpen}
                severity={snackbarType}
                onClose={snackbarOnClose}
            />
        </>
    );
};

Banner.propTypes = {
    bannerImage: PropTypes.object.isRequired,
    lang: PropTypes.string.isRequired,
    upload: PropTypes.func.isRequired,
    fetchBannerPic: PropTypes.func,
    handleProfilePic: PropTypes.func,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }),
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        bannerImage: state.me.bannerImage.value,
    };
};

const actionToProps = {
    upload: updateBannerPic,
    fetchBannerPic: fetchBannerImage,
};

export default connect(stateToProps, actionToProps)(Banner);
