import { Card } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import variables from '../../../utils/variables';
import ActiveFilter from './ActiveFilter';
import SignupFilter from './SignupFilter';
import exnt1 from '../../../assets/images/exnt-1.png';
import exnt2 from '../../../assets/images/exnt-2.png';

const Cards = (props) => {
    return (
        <div className="cards -mx-4">
            <Card className="w-full lg:w-1/2 special-card mb-6 lg:mb-0">
                <div className="bg-custom-dark mx-4 rounded-lg p-4 relative h-full">
                    <ActiveFilter/>
                    <p className="mb-10 block text-lg font-oswald-text font-semibold text-white name">
                        {variables[props.lang]['total_active'] + ' ' + props.activeFilterValue}
                    </p>
                    <div className="">
                        <span className="font-extrabold font-oswald-text text-white text-2xl break-all value">
                            NA
                        </span>
                    </div>
                    <div className="absolute bottom-0 right-0 w-5/12 xl:w-3/12">
                        <img alt="exnt_1" className="w-full" src={exnt1}/>
                    </div>
                </div>
            </Card>
            <Card className="w-full lg:w-1/2 special-card mb-6 lg:mb-0">
                <div className="bg-custom-dark mx-4 rounded-lg p-4 relative h-full">
                    <SignupFilter/>
                    <p className="mb-10 block text-lg font-oswald-text font-semibold text-white name">
                        {variables[props.lang]['total_new_signups'] + ' ' + props.signupsFilterValue}
                    </p>
                    <div className="">
                        <span className="font-extrabold font-oswald-text text-2xl text-white break-all value">
                            NA
                        </span>
                    </div>
                    <div className="absolute bottom-0 right-0 w-5/12 xl:w-3/12">
                        <img alt="exnt_2" className="w-full" src={exnt2}/>
                    </div>
                </div>
            </Card>
        </div>
    );
};

Cards.propTypes = {
    activeFilterValue: PropTypes.string.isRequired,
    lang: PropTypes.string.isRequired,
    signupsFilterValue: PropTypes.string.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        activeFilterValue: state.users.activeFilter,
        signupsFilterValue: state.users.signupsFilter,
    };
};

export default connect(stateToProps)(Cards);
