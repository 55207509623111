import React, { useState } from 'react';
import { Button, Dialog, DialogContent } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Snackbar from '../../components/Snackbar';
import CloseDialog from '../../components/CloseDialog';
import { archiveTask } from '../../actions/tasks';

const ArchiveDialog = ({ open, handleClose, item, archiveTask, updateTaskList }) => {
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackBarType, setSnackBarType] = useState('success');

    const openSnackbarOnError = (message) => {
        setSnackbarMessage(message);
        setSnackbarOpen(true);
    };

    const openSnackbarOnSuccess = (message) => {
        setSnackbarMessage(message);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleUpdateArchive = (id) => {
        archiveTask(id, { isArchive: true }, (error) => {
            if (!error) {
                openSnackbarOnSuccess('Task Successfully updated.');
                setSnackBarType('success');
                handleClose();
                updateTaskList();
            } else {
                openSnackbarOnError(`${error}`);
                setSnackBarType('error');
            }
        });
    };

    return (
        <>
            <Snackbar
                message={snackbarMessage}
                open={snackbarOpen}
                severity={snackBarType}
                onClose={handleSnackbarClose}
            />
            <Dialog
                aria-describedby="change-password-description"
                aria-labelledby="change-password-title"
                className="w-full change-password "
                open={open}>
                <DialogContent className="change-password__content rounded-lg mb-4 relative">
                    <div className="flex justify-between items-center">
                        <span className="text-custom-gray text-sm font-semibold block">Are you sure you want to archive this?</span>
                        <CloseDialog onClose={handleClose}/>
                    </div>

                    <div className="flex">
                    </div>

                    <div className="button_div flex justify-between mt-4">
                        <Button
                            className="w-1/2 min-w-0 mx-2 font-oswald-text cancel_button"
                            onClick={handleClose}>No</Button>
                        <Button
                            className="change_password w-1/2 mx-2 font-oswald-text"
                            onClick={() => handleUpdateArchive(item._id)}>Yes</Button>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
};

ArchiveDialog.propTypes = {
    archiveTask: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    item: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    updateTaskList: PropTypes.func.isRequired,
};

const actionToProps = {
    archiveTask,
};

export default connect(null, actionToProps)(ArchiveDialog);
