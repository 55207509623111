import React from 'react';
import { Link } from 'react-router-dom';
import Icon from '../../components/Icon';

const BackLink = () => {
    return (
        <>
            <div className="backlink__container absolute lg:relative top-10 lg:top-auto left-5 md:left-10 lg:left-auto mb-6 md:mb-4 lg:-ml-12">
                <Link className="backlink flex items-center" to="/">
                    <Icon className="arrow-left-black flex-1" icon="arrow-left-black"/>
                    <span className="text-white text-sm font-oswald-text md:text-2xl flex-auto pb-1 pl-2">Back</span>
                </Link>
            </div>
        </>
    );
};

export default BackLink;
