import { combineReducers } from 'redux';
import {
    NOTIFICATIONS_FETCH_IN_PROGRESS,
    NOTIFICATIONS_FETCH_SUCCESS,
    NOTIFICATIONS_FETCH_ERROR,
} from '../constants/notification';

const myNotifications = (state = {
    inProgress: false,
    list: [],
    message: '',
}, action) => {
    switch (action.type) {
    case NOTIFICATIONS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
            list: [],
        };
    case NOTIFICATIONS_FETCH_SUCCESS:
        return {
            inProgress: false,
            list: action.value.list,
        };
    case NOTIFICATIONS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

export default combineReducers({
    myNotifications,
});
