import Axios from 'axios';
import {
    NOTIFICATIONS_FETCH_IN_PROGRESS,
    NOTIFICATIONS_FETCH_SUCCESS,
    NOTIFICATIONS_FETCH_ERROR,
} from '../constants/notification';
import { MY_NOTIFICATIONS } from '../constants/url';

const fetchNotificationsInProcess = () => {
    return {
        type: NOTIFICATIONS_FETCH_IN_PROGRESS,
    };
};

const fetchNotificationsSuccess = (value) => {
    return {
        type: NOTIFICATIONS_FETCH_SUCCESS,
        value,
    };
};

const fetchNotificationsError = (message) => {
    return {
        type: NOTIFICATIONS_FETCH_ERROR,
        message,
    };
};

export const fetchMyNotifications = () => (dispatch) => {
    dispatch(fetchNotificationsInProcess());

    const URL = MY_NOTIFICATIONS;
    Axios.get(URL, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
    }).then((res) => {
        dispatch(fetchNotificationsSuccess(res.data && res.data.result));
    }).catch((error) => {
        dispatch(fetchNotificationsError(
            error.response &&
            error.response.data &&
            error.response.data.message
                ? error.response.data.message
                : 'Failed!',
        ));
    });
};
