import React from 'react';
import { withRouter } from 'react-router';
import { Button } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import variables from '../../../utils/variables';
import Icon from '../../../components/Icon';
import { connect } from 'react-redux';
import { signOut } from '../../../actions/navigationBar';

const SignOut = (props) => {
    const handleClose = () => {
        props.signOut((error) => {
            if (!error) {
                localStorage.removeItem('accessToken');
                localStorage.removeItem('refreshToken');

                props.history.push('/');
                props.onClick();
            } else {
                localStorage.removeItem('accessToken');
                localStorage.removeItem('refreshToken');

                props.history.push('/login');
            }
        });
    };

    return (
        <Button
            className="sign_out_button"
            disabled={props.inProgress}
            onClick={handleClose}>
            <Icon
                className="exit"
                icon="exit"/>
            {variables[props.lang]['sign_out']}
        </Button>
    );
};

SignOut.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    inProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    signOut: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        inProgress: state.navigationBar.signOutInProgress,
    };
};

const actionToProps = {
    signOut,
};

export default withRouter(connect(stateToProps, actionToProps)(SignOut));
