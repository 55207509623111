import React from 'react';
import '../Dashboard/index.css';
import './index.css';
import ExngClaimsTable from '../Dashboard/ExngClaimsTable';

const ExngClaims = () => {
    return (
        <div className="dashboard exng_claims content_padding">
            <ExngClaimsTable/>
        </div>
    );
};

export default ExngClaims;
