import React, { useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchProfileDetails } from '../../actions/navigationBar';
import { fetchBannerImage } from '../../actions/me';
import Banner from './Banner';
import Bio from './Bio';
import Newsfeed from './Newsfeed';
import './index.css';

const ProfilePage = (props) => {
    const {
        fetch,
        fetchBannerImage,
        userData,
    } = props;
    useEffect(() => {
        fetch();
        fetchBannerImage();
    }, []);

    const handleProfilePic = (file) => {
    };
    return (
        <>
            <div className="profile-page bg-custom-dark-2">
                <div className="profile-page__header">
                    <Banner
                        handleProfilePic={handleProfilePic}
                        lang={props.lang}
                    />
                </div>
                <div className="profile-page__body  px-8 xl:px-0">
                    <Bio
                        lang={props.lang}
                        userData={userData}
                    />
                    <Newsfeed
                        lang={props.lang}
                        userData={userData}
                    />
                </div>
            </div>
        </>
    );
};

ProfilePage.propTypes = {
    fetch: PropTypes.func.isRequired,
    fetchBannerImage: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    userData: PropTypes.object.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        userData: state.navigationBar.profileDetails,
    };
};

const actionToProps = {
    fetch: fetchProfileDetails,
    fetchBannerImage: fetchBannerImage,
};
export default connect(stateToProps, actionToProps)(ProfilePage);
