import Axios from 'axios';
import moment from 'moment';
const URL = process.env.REACT_APP_API_URL;

export const contributorsToCSV = (dealId) => {
    const apiURL = `${URL}/admin/deals/${dealId}/contributors/all`;

    Axios.get(apiURL, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
    }).then((res) => {
        const {
            data: {
                result: {
                    list,
                },
            },
        } = res;

        const rows = [['Timestamp', 'Tier Group', 'TG Username', 'Email Address', 'Contribution', 'Status', 'Etherscan Link', 'Notes']];

        if (list && list.length > 0) {
            list.forEach((item) => {
                const content = [
                    moment(item.created_at).format('MM/DD/YYYY h:mm:ss a'),
                    item.user.top_tier,
                    item.user.telegram.username,
                    item.user.email_address,
                    item.contribution,
                    item.status,
                    item.etherscan,
                    item.notes,
                ];

                rows.push(content);
            });
        }

        const csvContent = `data:text/csv;charset=utf-8,${rows
            .map((row) => row.join(','))
            .join('\n')}`;
        const encodedUri = encodeURI(csvContent);
        window.location.href = encodedUri;
    }).catch((error) => {
        console.log(error);
    });
};
