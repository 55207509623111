import { combineReducers } from 'redux';
import {
    UPDATE_ME_IN_PROGRESS,
    UPDATE_ME_SUCCESS,
    UPDATE_ME_ERROR,
    UPDATE_PROFILE_PIC_IN_PROGRESS,
    UPDATE_PROFILE_PIC_SUCCESS,
    UPDATE_PROFILE_PIC_ERROR,
    BANNER_IMAGE_FETCH_IN_PROGRESS,
    BANNER_IMAGE_FETCH_SUCCESS,
    BANNER_IMAGE_FETCH_ERROR,
} from '../constants/me';

const updateMe = (state = {
    inProgress: false,
    message: '',
}, action) => {
    switch (action.type) {
    case UPDATE_ME_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case UPDATE_ME_SUCCESS:
        return {
            inProgress: false,
            message: action.message,
        };
    case UPDATE_ME_ERROR:
        return {
            message: action.message,
            inProgress: false,
        };
    default:
        return state;
    }
};

const updateProfilePic = (state = {
    inProgress: false,
    message: '',
}, action) => {
    switch (action.type) {
    case UPDATE_PROFILE_PIC_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case UPDATE_PROFILE_PIC_SUCCESS:
        return {
            ...state,
            inProgress: false,
            message: action.message,
        };
    case UPDATE_PROFILE_PIC_ERROR:
        return {
            ...state,
            message: action.message,
            inProgress: false,
        };
    default:
        return state;
    }
};

const bannerImage = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case BANNER_IMAGE_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case BANNER_IMAGE_FETCH_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case BANNER_IMAGE_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

export default combineReducers({
    updateMe,
    updateProfilePic,
    bannerImage,
});
