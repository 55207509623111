import React, { useContext } from 'react';
import { withRouter } from 'react-router';
import moment from 'moment';
import * as PropTypes from 'prop-types';
import { SocketContext } from '../../context/socket';
import NotificationImg from './NotificationImg';
// import StakedImg from '../../assets/images/staked.png';

const NotificationModal = (props) => {
    const {
        close,
        history,
        open,
        unread,
        notifications,
        fetchNotifications,
        showAllNotifs,
        toggleShowAllNotifs,
        toggleShowUnreadOnly,
    } = props;

    const socket = useContext(SocketContext);

    const itemClick = (notif) => {
        const {
            _id,
            is_read: isRead,
            reference,
            reference_type: referenceType,
            parent_reference: parentReference,
        } = notif;

        if (!isRead) {
            socket.emit('read-notification', { id: _id }, (error, result) => {
                if (result) {
                    fetchNotifications();
                    if (referenceType === 'contributor') {
                        history.push(`/deals/${parentReference}/contributors/${reference}`);
                        close();
                    } else if (referenceType === 'task') {
                        history.push('/tasks');
                        close();
                    } else if (referenceType === 'message') {
                        history.push(`/inbox/${parentReference || reference}`);
                        close();
                    }
                } else if (error) {
                    console.error(error);
                }
            });
        } else {
            if (referenceType === 'contributor') {
                history.push(`/deals/${parentReference}/contributors/${reference}`);
                close();
            } else if (referenceType === 'task') {
                history.push('/tasks');
                close();
            }
        }
    };

    const renderItem = (notif, idx) => {
        if (idx > 20) {
            return '';
        }

        const timeFromNow = moment(notif.created_at).fromNow();

        if (notif.is_read && showAllNotifs) {
            return <div
                key={idx}
                className="notification__notif-item cursor-pointer"
                onClick={() => itemClick(notif)}
            >
                <NotificationImg notif={notif} />
                <div className="flex flex-col w-full pl-2">
                    <div className="notification__notif-detail shrink-0 col-span-2">
                        <p>
                            {notif.content}
                        </p>
                    </div>
                    <div className="notification__notif-detail col-start-2 col-span-2">
                        <div className="notification__notif-detail time_from_now">{timeFromNow}</div>
                    </div>
                </div>
            </div>;
        }

        return <div
            key={idx}
            className={`notification__notif-item ${showAllNotifs ? 'showAllNotifs' : 'showUnreadOnly'} shrink-0 cursor-pointer`}
            onClick={() => itemClick(notif)}
        >
            <NotificationImg notif={notif} />
            <div className="flex flex-col w-full pl-2">
                <div className="notification__notif-detail col-span-2">
                    <p>
                        {notif.content}
                    </p>
                </div>
                <div className="notification__notif-detail col-start-2 col-span-2">
                    <div className="notification__notif-detail time_from_now">{timeFromNow}</div>
                </div>
            </div>
        </div>;
    };

    return (
        <div className={open ? 'notification__container active' : 'notification__container'}>
            <div className="notification__wrapper">
                <div className="notification__header flex">
                    <div className="notification__header-box flex justify-between items-center w-full px-2">
                        <h2 className="notification__title text-base mr-3 text-white font-semibold font-oswald-text tracking-wider child">Notifications</h2>
                        { unread > 0 && <span className="notification__num child">
                            <span>{unread}</span>
                        </span> }
                    </div>
                </div>
                <div className="flex gap-4 mx-6 mt-2 mb-4">
                    <button className="flex justify-center items-center" onClick={toggleShowAllNotifs} >
                        <span className={showAllNotifs ? 'notification__read active' : 'notification__read inactive'}>All</span>
                        {showAllNotifs && <span className="notification__read_filter"/>}
                    </button>
                    <button className="flex justify-center items-center" onClick={toggleShowUnreadOnly}>
                        <span className={!showAllNotifs ? 'notification__read active' : 'notification__read inactive'}>Unread</span>
                        {!showAllNotifs && <span className="notification__read_filter"/>}
                    </button>
                </div>
                <div className="notification__body text-left">
                    {
                        notifications.map((notification, index) => (
                            renderItem(notification, index)
                        ))
                    }
                </div>
                {/* <div className="notification__footer text-center py-3">
                    <span className="text-base mr-3 font-semibold opacity-60 font-oswald-text">More</span>
                </div> */}
            </div>
        </div>
    );
};

NotificationModal.propTypes = {
    close: PropTypes.func.isRequired,
    fetchNotifications: PropTypes.func.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    notifications: PropTypes.array.isRequired,
    open: PropTypes.bool.isRequired,
    showAllNotifs: PropTypes.bool.isRequired,
    toggleShowAllNotifs: PropTypes.func.isRequired,
    toggleShowUnreadOnly: PropTypes.func.isRequired,
    unread: PropTypes.number.isRequired,
};

export default withRouter(NotificationModal);
