import React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';

const FromDialog = (props) => {
    const { image, content } = props;

    return (
        <div className="flex text-white mb-3 w-full items-center justify-end mb-4">
            <div className="rounded-full w-auto bg-custom-dark-mode-red font-oswald-text py-2 px-4 text-base font-light">
                <div dangerouslySetInnerHTML={{ __html: content }} />
            </div>
            <div className="rounded-full bg-white overflow-hidden w-7 h-7 ml-2">
                <img alt="profile" className="w-7 h-full object-cover object-center" src={image}/>
            </div>
        </div>
    );
};

FromDialog.propTypes = {
    content: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
};

export default connect()(FromDialog);
