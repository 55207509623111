import {
    FILE_DOWNLOAD_ERROR,
    FILE_DOWNLOAD_IN_PROGRESS,
    FILE_DOWNLOAD_SUCCESS,
    STAKED_REQUEST_FILTER_SET,
    UN_STAKE_REPORT_FETCH_ERROR,
    UN_STAKE_REPORT_FETCH_IN_PROGRESS,
    UN_STAKE_REPORT_FETCH_SUCCESS,
    UN_STAKED_REQUEST_FILTER_SET,
} from '../constants/unstakeRequests';
import Axios from 'axios';
import { unstakeReportURL } from '../constants/url';

export const setStakeFilter = (value) => {
    return {
        type: STAKED_REQUEST_FILTER_SET,
        value,
    };
};

export const setUnstakeFilter = (value) => {
    return {
        type: UN_STAKED_REQUEST_FILTER_SET,
        value,
    };
};

const fetchUnstakeReportInProgress = () => {
    return {
        type: UN_STAKE_REPORT_FETCH_IN_PROGRESS,
    };
};

const fetchUnstakeReportSuccess = (value, skip, limit, searchKey, sortBy, order) => {
    return {
        type: UN_STAKE_REPORT_FETCH_SUCCESS,
        value,
        skip,
        limit,
        searchKey,
        sortBy,
        order,
    };
};

const fetchUnstakeReportError = (message) => {
    return {
        type: UN_STAKE_REPORT_FETCH_ERROR,
        message,
    };
};

export const fetchUnstakeReport = (skip, limit, searchKey, sortBy, order) => (dispatch) => {
    dispatch(fetchUnstakeReportInProgress());

    const URL = unstakeReportURL(skip, limit, searchKey, sortBy, order);
    Axios.get(URL, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
    })
        .then((res) => {
            dispatch(fetchUnstakeReportSuccess(res.data && res.data.result,
                skip, limit, searchKey, sortBy, order));
        })
        .catch((error) => {
            dispatch(fetchUnstakeReportError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const downloadFileInProgress = () => {
    return {
        type: FILE_DOWNLOAD_IN_PROGRESS,
    };
};

const downloadFileSuccess = (message) => {
    return {
        type: FILE_DOWNLOAD_SUCCESS,
        message,
    };
};

const downloadFileError = (message) => {
    return {
        type: FILE_DOWNLOAD_ERROR,
        message,
    };
};

export const downloadFile = (url, fileName) => (dispatch) => {
    dispatch(downloadFileInProgress());

    Axios.get(url, {
        responseType: 'blob',
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
    })
        .then((res) => {
            const data = new Blob([res.data], { type: 'text/csv' });
            const csvURL = window.URL.createObjectURL(data);
            const tempLink = document.createElement('a');
            tempLink.href = csvURL;
            tempLink.setAttribute('download', fileName + '.xlsx');
            tempLink.click();
            dispatch(downloadFileSuccess('Success'));
        })
        .catch((error) => {
            dispatch(downloadFileError(
                error.response &&
                error.response.data &&
                error.response.data.result
                    ? error.response.data.result
                    : 'Canceled File Download!',
            ));
        });
};
