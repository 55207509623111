import React, {
    useState,
    useEffect,
    // useContext,
} from 'react';
import { MenuItem, Select, Tab, Tabs } from '@material-ui/core';
import Button from '../../components/Button';
import * as PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { connect } from 'react-redux';
import TabPanel from '../../components/TabPanel';
import OpenTaskTable from './OpenTaskTable';
import CloseTaskTable from './CloseTaskTable';
import ArchiveTaskTable from './ArchiveTaskTable';
import TaskDialog from './TaskDialog';
import ArchiveDialog from './ArchiveDialog';
import { fetchTaskList } from '../../actions/tasks';
// import { SocketContext } from '../../context/socket';
import './index.css';
// import twitterImage from '../../assets/images/twitter-image.png';
// import oval from '../../assets/images/oval.png';

const Tasks = ({ taskList, fetchTaskList }) => {
    const [value, setValue] = useState(0);
    const [selectedValue, setSelectedValue] = useState('all');
    const [item, setItem] = useState({});
    const [openArchive, setOpenArchive] = useState(false);
    const [isTaskListUpdated, setIsTaskListUpdated] = useState(true);
    const [taskType, setTaskType] = useState('OPEN');
    const [isArchive, setIsArchive] = useState(false);
    // const socket = useContext(SocketContext);

    useEffect(() => {
        fetchTaskList(`status=${taskType}&isArchive=false`);
    }, []);

    useEffect(() => {
        if (isTaskListUpdated) {
            fetchTaskList(`status=${taskType}&isArchive=false`);
            setIsTaskListUpdated(false);
        }
    }, [isTaskListUpdated]);

    // socket.once('return-new-notification', () => {
    //     setIsTaskListUpdated(true);
    // });

    const [openTask, setOpenTask] = useState(false);

    const handleCloseTask = () => {
        setOpenTask(false);
    };

    const handleOpenTask = () => {
        setOpenTask(true);
    };

    const handleCreateTask = () => {
        setItem({});
        handleOpenTask();
    };

    const updateTaskList = () => {
        setIsTaskListUpdated(true);
        setTaskType('OPEN');
        fetchTaskList(`status=${taskType}&isArchive=false`);
        setSelectedValue('all');
        setValue(0);
        setIsArchive(0);
        setItem({});
    };

    const a11yProps = (index) => {
        return {
            id: `settings-tab-${index}`,
            'aria-controls': `settings-tab-panel-${index}`,
        };
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
        const typeValue = (selectedValue !== 'all') ? selectedValue.toUpperCase() : '';
        const type = typeValue === '' ? '' : `type=${typeValue}`;
        if (newValue === 0) {
            setTaskType('OPEN');
            fetchTaskList(`status=OPEN&${type}&isArchive=false`);
        } else if (newValue === 1) {
            setTaskType('CLOSED');
            fetchTaskList(`status=CLOSED&${type}&isArchive=false`);
        } else if (newValue === 2) {
            setTaskType('');
            fetchTaskList(`${type}&isArchive=true`);
        }
    };

    const handleSelect = (event) => {
        const selected = event.target.value;
        setSelectedValue(selected);

        if (value === 2) {
            setIsArchive(true);
        } else {
            setIsArchive(false);
        }

        if (event.target.value === 'all') {
            const status = taskType ? `status=${taskType}&` : '';
            fetchTaskList(`${status}isArchive=${isArchive}`);
        } else {
            const status = taskType ? `status=${taskType}&` : '';
            fetchTaskList(`${status}type=${(event.target.value) !== 'all' ? (event.target.value).toUpperCase() : ''}&isArchive=${isArchive}`);
        }
    };

    const handleEdit = (id) => {
        setItem(taskList.list.find((value) => value._id === id));
        setOpenTask(true);
    };

    const handleArchive = (id) => {
        setItem(taskList.list.find((value) => value._id === id));
        setOpenArchive(true);
    };

    const handleCloseArchive = () => {
        setOpenArchive(false);
    };

    return (
        <div className="tasks bg-custom-dark-2 w-full h-full mx-auto py-10">
            <div className="w-4/5 mx-auto text-white">
                <h1 className="heading_text text-3xl md:text-4xl font-semibold font-oswald-text mb-4">Tasks</h1>
                <div className="relative">
                    <div className="tabs_section">
                        <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between">
                            <Tabs
                                className="vertical_tabs"
                                orientation="horizontal"
                                value={value}
                                variant="scrollable"
                                onChange={handleChange}>
                                <Tab
                                    className={ClassNames('tab', value === 0 ? 'active' : '')}
                                    label="Open Tasks"
                                    {...a11yProps(0)} />
                                <Tab
                                    className={ClassNames('tab', value === 1 ? 'active' : '')}
                                    label="Closed Tasks"
                                    {...a11yProps(1)} />
                                <Tab
                                    className={ClassNames('tab', value === 2 ? 'active' : '')}
                                    label="Archive Tasks"
                                    {...a11yProps(1)} />
                            </Tabs>
                            <div className="flex flex-col lg:flex-row lg:items-center mb-0 lg:mb-4 mt-4 lg:mt-0">
                                <div className="flex md:justify-center items-center mr-2 mb-4 md:mb-0">
                                    <span className="pr-4">Filter: </span>
                                    <Select
                                        className="task-select"
                                        id="filter-task"
                                        labelId="filter-task-label"
                                        value={selectedValue}
                                        onChange={handleSelect}
                                    >
                                        <MenuItem value="all">All</MenuItem>
                                        <MenuItem value="twitter">Twitter</MenuItem>
                                        <MenuItem value="manual">Manual</MenuItem>
                                    </Select>
                                </div>
                                <Button
                                    color="primary"
                                    size="sm"
                                    onClick={handleCreateTask}
                                >
                                    + Create
                                </Button>
                            </div>
                        </div>
                        <TabPanel
                            className="tab_content tab_content--no-border"
                            index={0}
                            value={value}>
                            <OpenTaskTable handleArchive={handleArchive} handleEdit={handleEdit} handleOpen={handleOpenTask} tableData={taskList.list}/>
                        </TabPanel>
                        <TabPanel
                            className="tab_content tab_content--no-border"
                            index={1}
                            value={value}>
                            <CloseTaskTable handleArchive={handleArchive} handleEdit={handleEdit} handleOpen={handleOpenTask} tableData={taskList.list}/>
                        </TabPanel>
                        <TabPanel
                            className="tab_content tab_content--no-border"
                            index={2}
                            value={value}>
                            <ArchiveTaskTable handleArchive={handleArchive} handleEdit={handleEdit} handleOpen={handleOpenTask} tableData={taskList.list}/>
                        </TabPanel>
                    </div>
                    <TaskDialog handleClose={handleCloseTask} item={item} open={openTask} updateTaskList={updateTaskList}/>
                    <ArchiveDialog handleClose={handleCloseArchive} item={item} open={openArchive} updateTaskList={updateTaskList} />
                    <div className={`absolute w-full h-full left-0 top-0 bg-custom-dark z-99 opacity-90 ${taskList.inProgress ? 'flex' : 'hidden'} items-center justify-center`}>
                        <span className="font-semibold text-2xl">Loading...</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

Tasks.propTypes = {
    fetchTaskList: PropTypes.func.isRequired,
    taskList: PropTypes.object.isRequired,
};

const stateToProps = (state) => {
    return {
        taskList: state.tasks.taskList,
    };
};

const actionToProps = {
    fetchTaskList,
};

export default connect(stateToProps, actionToProps)(Tasks);
