export const FORUM_FETCH_IN_PROGRESS = 'FORUM_FETCH_IN_PROGRESS';
export const FORUM_FETCH_SUCCESS = 'FORUM_FETCH_SUCCESS';
export const FORUM_FETCH_ERROR = 'FORUM_FETCH_ERROR';

export const FORUM_CREATE_IN_PROGRESS = 'FORUM_CREATE_IN_PROGRESS';
export const FORUM_CREATE_SUCCESS = 'FORUM_CREATE_SUCCESS';
export const FORUM_CREATE_ERROR = 'FORUM_CREATE_ERROR';

export const FORUM_GET_IN_PROGRESS = 'FORUM_GET_IN_PROGRESS';
export const FORUM_GET_SUCCESS = 'FORUM_GET_SUCCESS';
export const FORUM_GET_ERROR = 'FORUM_GET_ERROR';

export const FORUM_DELETE_IN_PROGRESS = 'FORUM_DELETE_IN_PROGRESS';
export const FORUM_DELETE_SUCCESS = 'FORUM_DELETE_SUCCESS';
export const FORUM_DELETE_ERROR = 'FORUM_DELETE_ERROR';

export const COMMENT_CREATE_IN_PROGRESS = 'COMMENTS_CREATE_IN_PROGRESS';
export const COMMENT_CREATE_SUCCESS = 'COMMENTS_CREATE_SUCCESS';
export const COMMENT_CREATE_ERROR = 'COMMENTS_CREATE_ERROR';

export const COMMENTS_FETCH_IN_PROGRESS = 'COMMENTS_CREATE_IN_PROGRESS';
export const COMMENTS_FETCH_SUCCESS = 'COMMENTS_CREATE_SUCCESS';
export const COMMENTS_FETCH_ERROR = 'COMMENTS_CREATE_ERROR';

export const COMMENT_DELETE_IN_PROGRESS = 'COMMENT_DELETE_IN_PROGRESS';
export const COMMENT_DELETE_SUCCESS = 'COMMENT_DELETE_SUCCESS';
export const COMMENT_DELETE_ERROR = 'COMMENT_DELETE_ERROR';

export const USER_IMAGE_FETCH_IN_PROGRESS = 'USER_IMAGE_FETCH_IN_PROGRESS';
export const USER_IMAGE_FETCH_SUCCESS = 'USER_IMAGE_FETCH_SUCCESS';
export const USER_IMAGE_FETCH_ERROR = 'USER_IMAGE_FETCH_ERROR';
