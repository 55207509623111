import React from 'react';
import * as PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import check from '../../assets/images/check.png';

const DoneDialog = (props) => {
    return (
        <Dialog
            aria-describedby="account-type-dialog-description"
            aria-labelledby="account-type-dialog-title"
            className="dialog account_verification"
            open={props.open}
            onClose={props.handleClose}>
            <DialogContent className="tasks-dialog content">
                <div className="flex flex-col items-center py-6">
                    <div className="mx-auto w-2/5 md:w-1/3 mb-8">
                        <img alt="photo image" className="w-full" src={check} />
                    </div>
                    <div className="flex flex-col text-white text-center">
                        <h3 className="font-bold font-oswald-text text-2xl md:text-3xl">Woot Hoo!</h3>
                        <span className="font-oswald-text opacity-50 text-xs md:text-sm">{props.message || 'Success.'}</span>
                    </div>
                </div>
            </DialogContent>
            <DialogActions className="tasks-actions mt-0 text-center mb-12 flex flex-col">
                <Button className="w-1/2 button-okay text-xl md:text-2xl bg-custom-dark-mode-red" onClick={props.handleClose}>Okay</Button>
            </DialogActions>
        </Dialog>
    );
};

DoneDialog.propTypes = {
    handleClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    message: PropTypes.string,
};

export default DoneDialog;
