import { combineReducers } from 'redux';
import {
    TIPPING_LIST_FETCH_ERROR,
    TIPPING_LIST_FETCH_IN_PROGRESS,
    TIPPING_LIST_FETCH_SUCCESS,
    USER_TIPS_FETCH_IN_PROGRESS,
    USER_TIPS_FETCH_SUCCESS,
    USER_TIPS_FETCH_ERROR,
} from '../constants/tipping';

const tableData = (state = {
    inProgress: false,
    list: [],
}, action) => {
    switch (action.type) {
    case TIPPING_LIST_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case TIPPING_LIST_FETCH_SUCCESS:
        return {
            inProgress: false,
            list: action.value,
        };
    case TIPPING_LIST_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const userTips = (state = {
    inProgress: false,
    tips: {},
}, action) => {
    switch (action.type) {
    case USER_TIPS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case USER_TIPS_FETCH_SUCCESS:
        return {
            inProgress: false,
            tips: action.value,
        };
    case USER_TIPS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

export default combineReducers({
    tableData,
    userTips,
});
