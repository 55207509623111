import Axios from 'axios';
import { CREATE_FORUM_URL, CREATE_COMMENT_URL, commentsURL, getForum, forumsURL, deleteForumURL, deleteComment, specificUserimage } from '../constants/url';
import {
    FORUM_FETCH_IN_PROGRESS,
    FORUM_FETCH_SUCCESS,
    FORUM_FETCH_ERROR,
    FORUM_CREATE_IN_PROGRESS,
    FORUM_CREATE_SUCCESS,
    FORUM_CREATE_ERROR,
    FORUM_GET_IN_PROGRESS,
    FORUM_GET_SUCCESS,
    FORUM_GET_ERROR,
    COMMENT_CREATE_IN_PROGRESS,
    COMMENT_CREATE_SUCCESS,
    COMMENT_CREATE_ERROR,
    COMMENTS_FETCH_IN_PROGRESS,
    COMMENTS_FETCH_SUCCESS,
    COMMENTS_FETCH_ERROR,
    FORUM_DELETE_IN_PROGRESS,
    FORUM_DELETE_SUCCESS,
    FORUM_DELETE_ERROR,
    COMMENT_DELETE_IN_PROGRESS,
    COMMENT_DELETE_SUCCESS,
    COMMENT_DELETE_ERROR,
    USER_IMAGE_FETCH_IN_PROGRESS,
    USER_IMAGE_FETCH_SUCCESS,
    USER_IMAGE_FETCH_ERROR,
} from '../constants/forum';

const fetchForumInProgress = () => {
    return {
        type: FORUM_FETCH_IN_PROGRESS,
    };
};

const fetchForumsSuccess = (value) => {
    return {
        type: FORUM_FETCH_SUCCESS,
        value,
    };
};

const fetchForumsError = (message) => {
    return {
        type: FORUM_FETCH_ERROR,
        message,
    };
};

export const fetchForums = (page) => (dispatch) => {
    dispatch(fetchForumInProgress());
    const URL = forumsURL(page);

    Axios.get(URL, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
    }).then((res) => {
        dispatch(fetchForumsSuccess(res.data));
    }).catch((error) => {
        dispatch(fetchForumsError(
            error.response &&
          error.response.data &&
          error.response.data.message
                ? error.response.data.message
                : 'Failed!',
        ));
    });
};

const createForumInProgress = () => {
    return {
        type: FORUM_CREATE_IN_PROGRESS,
    };
};

const createForumsSuccess = (message) => {
    return {
        type: FORUM_CREATE_SUCCESS,
        message,
    };
};

const createForumsError = (message) => {
    return {
        type: FORUM_CREATE_ERROR,
        message,
    };
};

export const createThread = (data) => (dispatch) => {
    dispatch(createForumInProgress());

    Axios.post(CREATE_FORUM_URL, data, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
            'Content-Type': 'application/json',
        },
    })
        .then((res) => {
            dispatch(createForumsSuccess(res.data.success, 'Success'));
            // cb(null, res.data && res.data.result && res.data.result._id);
        })
        .catch((error) => {
            dispatch(createForumsError(
                error.response &&
          error.response.data &&
          error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const getForumByIdInProgress = () => {
    return {
        type: FORUM_GET_IN_PROGRESS,
    };
};

const getForumByIdSuccess = (value) => {
    return {
        type: FORUM_GET_SUCCESS,
        value,
    };
};

const getForumByIdError = (message) => {
    return {
        type: FORUM_GET_ERROR,
        message,
    };
};

export const getForumByID = (id) => (dispatch) => {
    const URL = getForum(id);
    dispatch(getForumByIdInProgress());

    Axios.get(URL, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
    }).then((res) => {
        dispatch(getForumByIdSuccess(res.data));
    }).catch((error) => {
        dispatch(getForumByIdError(
            error.response &&
      error.response.data &&
      error.response.data.message
                ? error.response.data.message
                : 'Failed!',
        ));
    });
};

const createCommentInProgress = () => {
    return {
        type: COMMENT_CREATE_IN_PROGRESS,
    };
};

const createCommentSuccess = (value) => {
    return {
        type: COMMENT_CREATE_SUCCESS,
        value,
    };
};

const createCommentError = (message) => {
    return {
        type: COMMENT_CREATE_ERROR,
        message,
    };
};

export const createComment = (data) => (dispatch) => {
    dispatch(createCommentInProgress());
    console.log('hit create comment');
    Axios.post(CREATE_COMMENT_URL, data, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
            'Content-Type': 'application/json',
        },
    }).then((res) => {
        dispatch(createCommentSuccess(res.data.result, 'Success'));
    }).catch((error) => {
        dispatch(createCommentError(
            error.response &&
      error.response.data &&
      error.response.data.message
                ? error.response.data.message
                : 'Failed!',
        ));
    });
};

const fetchCommentsInProgress = () => {
    return {
        type: COMMENTS_FETCH_IN_PROGRESS,
    };
};

const fetchCommentsSuccess = (value) => {
    return {
        type: COMMENTS_FETCH_SUCCESS,
        value,
    };
};

const fetchCommentsError = (message) => {
    return {
        type: COMMENTS_FETCH_ERROR,
        message,
    };
};

export const fetchComments = (ID, pageNo, limit) => (dispatch) => {
    const URL = commentsURL(ID, pageNo, limit);
    dispatch(fetchCommentsInProgress());

    Axios.get(URL, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
    }).then((res) => {
        dispatch(fetchCommentsSuccess(res.data));
    }).catch((error) => {
        dispatch(fetchCommentsError(
            error.response &&
      error.response.data &&
      error.response.data.message
                ? error.response.data.message
                : 'Failed!',
        ));
    });
};

const deleteForumInProgress = () => {
    return {
        type: FORUM_DELETE_IN_PROGRESS,
    };
};

const deleteForumSuccess = (message) => {
    return {
        type: FORUM_DELETE_SUCCESS,
        message,
    };
};

const deleteForumError = (message) => {
    return {
        type: FORUM_DELETE_ERROR,
        message,
    };
};

export const deleteThread = (ID) => (dispatch) => {
    const URL = deleteForumURL(ID);

    dispatch(deleteForumInProgress());

    Axios.delete(URL, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
            'Content-Type': 'application/json',
        },
    }).then((res) => {
        dispatch(deleteForumSuccess(res.data.result, 'Success'));
    }).catch((error) => {
        dispatch(deleteForumError(
            error.response &&
      error.response.data &&
      error.response.data.message
                ? error.response.data.message
                : 'Failed!',
        ));
    });
};

const deleteCommentInProgress = () => {
    return {
        type: COMMENT_DELETE_IN_PROGRESS,
    };
};

const deleteCommentSuccess = (message) => {
    return {
        type: COMMENT_DELETE_SUCCESS,
        message,
    };
};

const deleteCommentError = (message) => {
    return {
        type: COMMENT_DELETE_ERROR,
        message,
    };
};

export const deleteCommentByID = (ID) => (dispatch) => {
    const URL = deleteComment(ID);

    dispatch(deleteCommentInProgress());
    Axios.delete(URL, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
            'Content-Type': 'application/json',
        },
    }).then((res) => {
        console.log(res);
        dispatch(deleteCommentSuccess(res.data.success, 'Success'));
    }).catch((error) => {
        dispatch(deleteCommentError(
            error.response &&
      error.response.data &&
      error.response.data.message
                ? error.response.data.message
                : 'Failed!',
        ));
    });
};

const fetchUserImageInProgress = () => {
    return {
        type: USER_IMAGE_FETCH_IN_PROGRESS,
    };
};

const fetchUserImageSuccess = (value) => {
    return {
        type: USER_IMAGE_FETCH_SUCCESS,
        value,
    };
};

const fetchUserImageError = (message) => {
    return {
        type: USER_IMAGE_FETCH_ERROR,
        message,
    };
};

export const fetchUserImage = (ID, pageNo, limit) => (dispatch) => {
    const URL = specificUserimage(ID, pageNo, limit);

    dispatch(fetchUserImageInProgress());

    Axios.get(URL, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
    }).then((res) => {
        dispatch(fetchUserImageSuccess(res.data));
    }).catch((error) => {
        dispatch(fetchUserImageError(
            error.response &&
      error.response.data &&
      error.response.data.message
                ? error.response.data.message
                : 'Failed!',
        ));
    });
};
