import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import variables from '../../utils/variables';
import Button from '../../components/Button';
import { fetchProfileImage } from '../../actions/navigationBar';
// import profileImage from '../../assets/images/Oval.png';

const Bio = (props) => {
    const {
        history,
        profileDetails,
        profileImage,
        fetchProfilePic,
        lang,
    } = props;
    const [profilePic, setProfilePic] = useState('');

    useEffect(() => {
        fetchProfilePic();
    }, []);

    useEffect(() => {
        if (!isEmpty(profileImage.value)) {
            if (!isEmpty(profileImage.value.url)) {
                setProfilePic(profileImage.value.url);
            }
        }
    }, [profileImage, profilePic]);

    const handleClick = () => {
        history.push({
            pathname: '/profile/edit',
            state: {
                profileDetails,
                profileImage,
            },
        });
    };

    return (
        <div className="profile-page__bio flex-col md:flex-row mb-10 lg:mb-16">
            <div className="bio__photo">
                <img alt="User Image" className="bio__image mx-auto md:mx-0" src={profilePic} />
            </div>
            <div className="bio__details">
                <span className="bio__name">{profileDetails.name ? profileDetails.name : 'No Name'}</span>
                <span className="bio__email">{profileDetails.email_address}</span>
                <Button
                    className="btn__bio"
                    color="primary"
                    onClick={handleClick}
                >
                    {variables[lang].edit_profile}
                </Button>
            </div>
        </div>
    );
};

Bio.propTypes = {
    fetchProfilePic: PropTypes.func.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    lang: PropTypes.string.isRequired,
    profileDetails: PropTypes.object.isRequired,
    profileImage: PropTypes.object.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        profileDetails: state.navigationBar.profileDetails.value,
        profileImage: state.navigationBar.profileImage,
    };
};

const actionToProps = {
    fetchProfilePic: fetchProfileImage,
};

export default withRouter(connect(stateToProps, actionToProps)(Bio));
