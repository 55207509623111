import React from 'react';
import './index.css';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import variables from '../../utils/variables';
import EmailTextField from '../SignIn/EmailTextField';
import ResetButton from './ResetButton';
import { withRouter } from 'react-router';
import { addRecoveryEmail } from '../../actions/forgotPassword';
import FormInfo from '../../components/FormInfo';
import BackLink from '../SignIn/BackLink';

const ForgotPassword = (props) => {
    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && !disable) {
            handleRecoveryEmail();
        }
    };

    const handleRecoveryEmail = () => {
        const data = {
            emailAddress: props.email,
        };

        props.addRecoveryEmail(data, (error) => {
            if (!error) {
                props.history.push('/login');
            }
        });
    };

    const disable = props.email === '' || props.inProgress;

    return (
        <div className="w-full flex items-center lg:items-start bg-custom-dark">
            <div className=" flex flex-col-reverse lg:flex-row-reverse bg-custom-dark h-screen lg:h-auto items-center w-full">
                <div className="flex items-center justify-center lg:w-1/2 w-full bg-custom-dark md:mb-10 md:mb-auto h-screen items-center md:items">
                    <form
                        noValidate
                        autoComplete="off"
                        className="card w-4/5 md:w-3/5 text-white"
                        onKeyPress={handleKeyPress}>
                        <div className="hidden lg:block">
                            <BackLink/>
                        </div>
                        <h2 className="heading font-oswald-text font-semibold text-3xl mb-3">
                            {variables[props.lang]['forgot_your_password']}
                        </h2>
                        <p className="text-base mb-5 font-semibold opacity-60 mb-12 block">
                            {variables[props.lang]['enter_email']}
                        </p>
                        <EmailTextField/>
                        <div className="md:mt-8">
                            <ResetButton disable={disable} lang={props.lang} onClick={handleRecoveryEmail}/>
                        </div>
                    </form>
                    <div className="lg:hidden flex justify-center block pt-5">
                        <BackLink/>
                    </div>
                </div>
                <FormInfo/>
            </div>
        </div>
    );
};

ForgotPassword.propTypes = {
    addRecoveryEmail: PropTypes.func.isRequired,
    email: PropTypes.string.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    inProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
};

const stateToProps = (state) => {
    return {
        email: state.signIn.emailId,
        inProgress: state.forgotPassword.recoveryEmailInProgress,
        lang: state.language,
    };
};

const actionToProps = {
    addRecoveryEmail,
};

export default withRouter(connect(stateToProps, actionToProps)(ForgotPassword));
