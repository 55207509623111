import React, { useState, useContext } from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import * as PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import Snackbar from '../../../components/Snackbar';
import DotsLoading from '../../../components/DotsLoading';
import TextField from '../../../components/TextField';
import { SocketContext } from '../../../context/socket';
import variables from '../../../utils/variables';
import {
    addDistributionDetails,
    updateDistributionDetails,
} from '../../../actions/distribution';
import './index.css';

const DistributionModal = (props) => {
    const {
        open,
        onClose,
        lang,
        addInProgress,
        updateInProgress,
        addDistributionDetails,
        dealId,
        onSuccess,
        fetchDefault,
        distributionDetails,
        updateDistributionDetails,
        contributorData,
        profileDetails: {
            _id,
        },
    } = props;
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [vestingDetails, setVestingDetails] = useState('');

    const socket = useContext(SocketContext);

    let isEdit = false;

    if (!isEmpty(distributionDetails)) {
        isEdit = true;
    }

    const clearForm = () => {
        setVestingDetails('');
    };

    const openSnackbarOnError = (message) => {
        setSnackbarMessage(message);
        setOpenSnackbar(true);
    };

    const submitDistribution = () => {
        if (isEdit) {
            const { vesting_details: vd } = distributionDetails;
            if (vd && vd === vestingDetails) {
                openSnackbarOnError(variables[lang]['fields_update_warn']);
            } else {
                const { _id } = distributionDetails;
                const data = { vestingDetails };

                updateDistributionDetails(dealId, _id, data, (error, result) => {
                    if (result && !updateInProgress) {
                        onSuccess(variables[lang]['update_distribution_success']);
                        clearForm();
                        fetchDefault();
                        onClose();
                    } else if (error) {
                        openSnackbarOnError(`${error}`);
                    }
                });
            }
        } else {
            if (!vestingDetails) {
                openSnackbarOnError(variables[lang]['vesting_details_required']);
                return;
            }

            const data = {
                id: dealId,
                vestingDetails,
            };

            addDistributionDetails(data, (error, result) => {
                if (result && !addInProgress) {
                    onSuccess(variables[lang]['add_distribution_details_success']);
                    const contributorIds = [];

                    if (!isEmpty(contributorData)) {
                        contributorData.map((contri) => {
                            contributorIds.push(contri.user._id);
                        });
                    }

                    const contributor = [...new Set(contributorIds)];
                    const data = {
                        from: _id,
                        fromType: 'admin_user',
                        reference: result,
                        referenceType: 'distribution',
                        parentReference: dealId,
                        parentReferenceType: 'deal',
                        content: 'Distribution is created',
                        isTaskUpdate: false,
                        contributor,
                    };

                    socket.emit('new-notification', data, (error) => {
                        if (error) {
                            openSnackbarOnError(`${error}`);
                        } else {
                            clearForm();
                            fetchDefault();
                            onClose();
                        }
                    });
                } else if (error) {
                    openSnackbarOnError(`${error}`);
                }
            });
        }
    };

    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };

    return (
        <>
            <Snackbar
                message={snackbarMessage}
                open={openSnackbar}
                severity="error"
                onClose={handleSnackbarClose}
            />
            <Dialog
                aria-describedby="deal-dialog-description"
                aria-labelledby="deal-dialog-title"
                className="dialog distribution_dialog rounded-lg"
                open={open}
                onClose={onClose}
            >
                <DialogContent className="content relative">
                    <h2 className="font-oswald-text text-white font-bold text-2xl mb-4">{variables[lang]['distribution_details']}</h2>
                    <form noValidate autoComplete="off" className="form">
                        <div className="flex flex-col mb-4 font-oswald-text">
                            <p className="block text-base font-semibold">
                                <span className="text-white opacity-50">
                                    {variables[lang]['vesting_details']}
                                </span>
                            </p>
                            <TextField
                                id="vesting_details"
                                multiline={true}
                                name="vestingDetails"
                                value={vestingDetails}
                                onChange={setVestingDetails}
                            />
                        </div>
                    </form>
                </DialogContent>
                <DialogActions className="button_div  w-4/5 mx-auto flex justify-between">
                    {isEdit
                        ? <>
                            {updateInProgress
                                ? <DotsLoading/>
                                : <>
                                    <Button className="cancel_button  w-1/2 mx-2 font-oswald-text" onClick={onClose}>
                                        {variables[lang].cancel}
                                    </Button>
                                    <Button className="close_submit  w-1/2 mx-2 font-oswald-text" onClick={submitDistribution}>
                                        {variables[lang]['update_details']}
                                    </Button>
                                </>
                            }
                        </> : <>
                            {addInProgress
                                ? <DotsLoading/>
                                : <>
                                    <Button className="cancel_button  w-1/2 mx-2 font-oswald-text" onClick={onClose}>
                                        {variables[lang].cancel}
                                    </Button>
                                    <Button className="close_submit  w-1/2 mx-2 font-oswald-text" onClick={submitDistribution}>
                                        {variables[lang]['add_details']}
                                    </Button>
                                </>
                            }
                        </>
                    }
                </DialogActions>
            </Dialog>
        </>
    );
};

DistributionModal.propTypes = {
    addDistributionDetails: PropTypes.func.isRequired,
    addInProgress: PropTypes.bool.isRequired,
    dealId: PropTypes.string.isRequired,
    distributionDetails: PropTypes.object.isRequired,
    fetchDefault: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    profileDetails: PropTypes.object.isRequired,
    updateDistributionDetails: PropTypes.func.isRequired,
    updateInProgress: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    contributorData: PropTypes.array,
};

const stateToProps = (state) => {
    return {
        addInProgress: state.distribution.addDistributionDetails.inProgress,
        updateInProgress: state.distribution.updateDistribution.inProgress,
        lang: state.language,
        profileDetails: state.navigationBar.profileDetails.value,
    };
};

const actionToProps = {
    addDistributionDetails,
    updateDistributionDetails,
};

export default connect(stateToProps, actionToProps)(DistributionModal);
