import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import variables from '../../../utils/variables';
import Icon from '../../../components/Icon';

const Settings = (props) => {
    const handleClick = () => {
        props.history.push('/settings');

        props.onClick();
    };

    return (
        <Button
            className="sign_out_button settings_button"
            onClick={handleClick}>
            <Icon
                className="settings"
                icon="settings"/>
            {variables[props.lang].settings}
        </Button>
    );
};

Settings.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    lang: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default withRouter(Settings);
