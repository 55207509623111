import React from 'react';
import './index.css';
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import { hideAuthenticationSwitchDialog, updateTwoFactorAuth } from '../../../../actions/settings';
import { connect } from 'react-redux';
import AuthenticationCodeTextField from '../Dialog/AuthenticationCodeTextField';
import VerifyButton from './VerifyButton';
import { fetchProfileDetails } from '../../../../actions/navigationBar';
import variables from '../../../../utils/variables';

const SwitchDialog = (props) => {
    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && !disable) {
            handleVerifyAndUpdate();
        }
    };

    const handleVerifyAndUpdate = () => {
        props.update(props.switchValue ? 'enable' : 'disable', { token: props.value },
            (error) => {
                if (error) {
                    return;
                }

                props.handleClose();
                props.fetch();
            },
        )
        ;
    };

    const disable = !props.token || props.token.length < 6;

    return (
        <Dialog
            aria-describedby="authenticate-switch-dialog-description"
            aria-labelledby="authenticate-switch-dialog-title"
            className="dialog switch_dialog text-white"
            open={props.open}
            onClose={props.handleClose}>
            <DialogContent className="content">
                <p className="font-oswald-text font-bold text-2xl mb-4 text-white">{variables[props.lang]['enter_two_factor_auth']}</p>
                <form
                    noValidate
                    autoComplete="off"
                    className=" w-full"
                    onKeyPress={handleKeyPress}
                    onSubmit={(e) => {
                        e.preventDefault();
                    }}>
                    <AuthenticationCodeTextField/>
                </form>
            </DialogContent>
            <DialogActions className="button_div">
                <VerifyButton disable={disable} lang={props.lang} onClick={handleVerifyAndUpdate}/>
            </DialogActions>
        </Dialog>
    );
};

SwitchDialog.propTypes = {
    fetch: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    switchValue: PropTypes.bool.isRequired,
    update: PropTypes.func.isRequired,
    token: PropTypes.string,
    value: PropTypes.string,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        open: state.settings.switchVerifyDialog,
        token: state.settings.authCode,
        switchValue: state.settings.switchValue,
        value: state.settings.authCode,
    };
};

const actionToProps = {
    handleClose: hideAuthenticationSwitchDialog,
    update: updateTwoFactorAuth,
    fetch: fetchProfileDetails,
};

export default connect(stateToProps, actionToProps)(SwitchDialog);
