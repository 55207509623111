import React, { useState, useEffect, useContext } from 'react';
import { Input, FormLabel, Link } from '@material-ui/core';
import InputField from '../../../components/Global/InputField';
import Snackbar from '../../../components/Snackbar';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import { SocketContext } from '../../../context/socket';
import { getUsers, fetchRecipient } from '../../../actions/message';
import variables from '../../../utils/variables';

const Edit = (props) => {
    const {
        fetchRecipient,
        recipientdata,
        userData,
        lang,
        match: {
            params: {
                id,
                messageID,
            },
        },
        isAdmin,
    } = props;
    const [formstate, setFormState] = useState({ });
    const socket = useContext(SocketContext);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [recipientID, setRecipientID] = useState('');
    const [recipientEmail, setRecipientEmail] = useState('');
    const [recipientType, setRecipientType] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('');
    const [messageSent, setMessageSent] = useState(false);
    const [displayClass, setDisplayClass] = useState('hidden');
    const [recipientSelected, setRecipientSelected] = useState(false);

    useEffect(() => {
        const fetch = async (id) => {
            await fetchRecipient(id);
        };

        if (id) {
            fetch(id);
        } else {
            setRecipientID('');
            setRecipientEmail('');
        }
    }, []);

    useEffect(() => {
        if (userData?._id) {
            console.log('hit');
            setFormState((prevState) => ({ ...prevState, sender: userData?._id, sender_type: 'user' }));
        }
    }, [userData._id]);

    useEffect(() => {
        setFormState((prevState) => ({ ...prevState, parent_id: messageID }));
    }, [id]);

    useEffect(() => {
        if (recipientdata) {
            if (!isAdmin) {
                setRecipientType('user');
            } else {
                setRecipientType('admin_user');
            }

            if (recipientSelected) {
                setRecipientID(recipientdata?._id);
                setRecipientEmail(recipientdata?.email_address);
            }

            if (id) {
                setRecipientID(recipientdata?._id);
                setRecipientEmail(recipientdata?.email_address);
            }
        }
    }, [recipientdata]);

    useEffect(() => {
        if (messageSent) {
            const redirect = setTimeout(() => {
                setRecipientEmail('');
                setRecipientID('');
                props.history.push('/inbox');
            }, 1000);

            return () => clearTimeout(redirect);
        }
    }, [messageSent]);

    useEffect(() => {
        if (searchQuery !== '') {
            socket.on('searchResults', (results) => {
                setSearchResults(results);
                setDisplayClass('flex flex-col');
            });
        } else {
            setSearchResults([]);
            setDisplayClass('hidden');
        }
    }, [searchQuery]);

    useEffect(() => {
        setFormState((prevState) => ({ ...prevState, recipient: recipientID, receiver_type: recipientType }));
    }, [recipientID]);

    const handleForm = (name, value) => {
        setFormState((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleSubmit = () => {
        if (validateForm(formstate)) {
            socket.emit('send-message', formstate, (error, result) => {
                if (error) {
                    setSnackbarMessage(error);
                    setSnackbarOpen(true);
                    setSnackbarSeverity('error');
                } else {
                    const doc = {
                        from: result.sender,
                        fromType: result.sender_type,
                        to: result.receiver,
                        toType: result.receiver_type,
                        reference: result._id,
                        referenceType: 'message',
                        content: 'New Message Received',
                    };

                    if (result.parentId) {
                        doc.parentReference = result.parentId;
                        doc.parentReferenceType = 'message';
                    }

                    socket.emit('new-notification', doc, (error) => {
                        if (error) {
                            setSnackbarMessage(error);
                            setSnackbarOpen(true);
                            setSnackbarSeverity('error');
                        } else {
                            setSnackbarMessage('Message Sent!');
                            setSnackbarOpen(true);
                            setSnackbarSeverity('success');
                            setMessageSent(true);
                        }
                    });
                }
            });
        }
    };

    const validateForm = () => {
        if (!formstate.sender || typeof formstate.sender === 'undefined') {
            setSnackbarMessage(variables[lang].message_validate_sender);
            setSnackbarOpen(true);
            setSnackbarSeverity('error');
            return false;
        }

        if (!formstate.recipient || typeof formstate.recipient === 'undefined') {
            setSnackbarMessage(variables[lang].message_validate_recipient);
            setSnackbarOpen(true);
            setSnackbarSeverity('error');
            return false;
        }

        if (!formstate.receiver_type || typeof formstate.receiver_type === 'undefined') {
            setSnackbarMessage(variables[lang].message_validate_receiver_type);
            setSnackbarOpen(true);
            setSnackbarSeverity('error');
            return false;
        }

        if (!formstate.sender_type || typeof formstate.sender_type === 'undefined') {
            setSnackbarMessage(variables[lang].message_validate_sender_type);
            setSnackbarOpen(true);
            setSnackbarSeverity('error');
            return false;
        }

        if (!formstate.subject || typeof formstate.subject === 'undefined') {
            setSnackbarMessage(variables[lang].message_validate_subject);
            setSnackbarOpen(true);
            setSnackbarSeverity('error');
            return false;
        }

        if (!formstate.content || typeof formstate.content === 'undefined') {
            setSnackbarMessage(variables[lang].message_validate_content);
            setSnackbarOpen(true);
            setSnackbarSeverity('error');
            return false;
        }
        return true;
    };

    const handleSearch = (event) => {
        const searchTerm = event.target.value;
        setSearchQuery(searchTerm);

        // Emit a search event to the server
        socket.emit('search', searchQuery);
    };

    const selectRecipient = async (e) => {
        const id = e.target.getAttribute('data-id');
        setRecipientSelected(true);
        setSearchResults([]);
        setDisplayClass('hidden');
        await fetchRecipient(id);
    };

    const snackbarOnClose = () => {
        setSnackbarOpen(false);
    };
    return (
        <>
            <div className="flex flex-col mx-auto w-4/5 pb-10 mb-4 pt-10 ">
                <div className="flex justify-between items-center mb-10">
                    <Link className="text-bold font-oswald-text text-custom-white" href="/inbox">{variables[lang].back}</Link>
                </div>
                <div className="bg-custom-dark font-oswald-text text-custom-white p-20 flex justify-center">
                    <form>
                        <div className="search flex flex-col font-oswald-text text-custom-white">
                            {!id && (
                                <><FormLabel className="font-oswald-text text-custom-white">{variables[lang].search}</FormLabel>
                                    <Input
                                        className="border rounded border-custom-dark px-4 py-2 bg-slate-200 bg-custom-dark font-oswald-text text-custom-white text-lg"
                                        label={variables[lang].recipient}
                                        sx={{
                                            '&::before': {
                                                border: '1.5px solid rgba(255, 255, 255, 0.5)',
                                                transform: 'scaleX(0)',
                                                left: '2.5px',
                                                right: '2.5px',
                                                bottom: 0,
                                                top: 'unset',
                                                transition: 'transform .15s cubic-bezier(0.1,0.9,0.2,1)',
                                                borderRadius: 0,
                                                borderBottomLeftRadius: '64px 20px',
                                                borderBottomRightRadius: '64px 20px',
                                            },
                                            '&::after': {
                                                border: '1.5px solid rgba(255, 255, 255, 0.5)',
                                                transform: 'scaleX(0)',
                                                left: '2.5px',
                                                right: '2.5px',
                                                bottom: 0,
                                                top: 'unset',
                                                transition: 'transform .15s cubic-bezier(0.1,0.9,0.2,1)',
                                                borderRadius: 0,
                                                borderBottomLeftRadius: '64px 20px',
                                                borderBottomRightRadius: '64px 20px',
                                            },
                                            '&:focus-within::before': {
                                                transform: 'scaleX(1)',
                                            },
                                        }}
                                        type="text"
                                        value={searchQuery}
                                        onChange={handleSearch} /></>
                            )}
                        </div>
                        <div className={`${displayClass} bg-custom-dark font-oswald-text text-custom-white p-4`}>
                            {Object.keys(searchResults).length > 0 && (
                                searchResults.map((result, index) => (
                                    <div key={index} className="cursor-pointer" data-id={result._id} onClick={selectRecipient}>
                                        {result.email_address}
                                    </div>
                                ))
                            )}
                        </div>
                        <div className="my-4">
                            <h3 className="capitalize">{variables[lang].recipients}:</h3>
                            {recipientEmail}
                        </div>
                        <InputField
                            fieldName="subject"
                            handleForm={handleForm}
                            label={variables[lang].subject}
                        />
                        <InputField
                            fieldClass="h-80 message-editor"
                            fieldName="content"
                            handleForm={handleForm}
                            label={variables[lang].create_message}
                            type="textarea"
                        />
                        <div className="flex justify-center">
                            <button className="btn--primary btn--small border border-custom-dark-mode-red bg-custom-dark-mode-red rounded-md px-6 py-2" type="button" onClick={handleSubmit}>
                                <span className="text-white font-oswald-text capitalize">{variables[lang].submit}</span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <Snackbar
                message={snackbarMessage}
                open={snackbarOpen}
                severity={snackbarSeverity}
                onClose={snackbarOnClose}
            />
        </>
    );
};

Edit.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    lang: PropTypes.string.isRequired,
    fetchRecipient: PropTypes.func,
    getUsers: PropTypes.func,
    isAdmin: PropTypes.bool,
    match: PropTypes.shape({
        params: PropTypes.object.isRequired,
    }),
    recipientdata: PropTypes.object,
    userData: PropTypes.object,
    usersList: PropTypes.object,
};

const stateToProps = (state) => {
    const { value } = state.navigationBar.profileDetails;
    const { result } = state.messaging.usersList.usersList;
    const { recipient: recipientdata, isAdmin } = state.messaging.messageRecipient.recipient;

    return {
        userData: value,
        usersList: result,
        recipientdata,
        lang: state.language,
        isAdmin,
    };
};

const actionToProps = {
    getUsers,
    fetchRecipient,
};

export default connect(stateToProps, actionToProps)(Edit);
