import React from 'react';
import front from '../../assets/images/front.png';
import logo from '../../assets/images/exnt-logo.svg';
import './index.scss';

const FormInfo = () => {
    return (
        <div className="info w-full h-auto lg:h-full lg:w-1/2 flex lg:items-center">
            <div className="h-full flex flex-col lg:h-full h-auto lg:min-h-screen w-full relative">
                <div className="info__content text-white absolute top-12 hidden lg:block z-10">
                    <div>
                        <img alt="logo" className="xl:w-20" src={logo} />
                    </div>
                    <h1 className="tracking-wider text-3xl md:text-5xl xl:text-7xl font-extrabold mb-3">Exnetwork<br/>Community App</h1>
                    <p className="tracking-wide leading-1 md:leading-6 text-sm md:text-base">The world&apos;s first
                        social finance (SOFI) powered community. Sign up or log in to stake our EXNT SOFI token and
                        start getting social with your DEFI.</p>
                </div>
                <div className="info__image hidden lg:block w-full absolute z-0 h-full overflow-hidden">
                    <img alt="boxing bear" className="w-full h-full object-cover" src={front}/>
                </div>
            </div>
        </div>
    );
};

export default FormInfo;
