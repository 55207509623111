import React from 'react';
import * as PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import variables from '../../utils/variables';

const RecoveryButton = (props) => {
    return (
        <Button
            className="reset_button w-full"
            disabled={props.disable}
            variant="contained"
            onClick={props.onClick}>
            {variables[props.lang].recover}
        </Button>
    );
};

RecoveryButton.propTypes = {
    disable: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default RecoveryButton;
