import React, { useState, useContext, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, FormLabel, Input } from '@material-ui/core';
import { connect } from 'react-redux';
import variables from '../../../utils/variables';
import { SocketContext } from '../../../context/socket';
import InputField from '../../../components/Global/InputField';
import Snackbar from '../../../components/Snackbar';
import { fetchRecipient } from '../../../actions/message';

const CreateMessage = (props) => {
    const {
        fetchRecipient,
        handleClose,
        lang,
        // id,
        handleCreateNewMessage,
        recipientdata,
        isAdmin,
    } = props;
    const [searchQuery, setSearchQuery] = useState('');
    const socket = useContext(SocketContext);
    const [displayClass, setDisplayClass] = useState('hidden');
    const [searchResults, setSearchResults] = useState([]);
    const [recipientEmail, setRecipientEmail] = useState([]);
    const [recipientType, setRecipientType] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('');
    const [recipientID, setRecipientID] = useState([]);
    const [recipientSelected, setRecipientSelected] = useState(false);

    const [formstate, setFormState] = useState({ });

    useEffect(() => {
        const fetch = async (id) => {
            await fetchRecipient(id);
        };
        fetch('619cce04b97c3476149f3671');
    }, []);

    useEffect(() => {
        if (searchQuery !== '') {
            socket.on('searchResults', (results) => {
                setSearchResults(results);
                setDisplayClass('flex flex-col');
            });
        } else {
            setSearchResults([]);
            setDisplayClass('hidden');
        }
    }, [searchQuery]);

    useEffect(() => {
        if (recipientdata) {
            if (!isAdmin) {
                setRecipientType([...recipientType, 'user']);
            } else {
                setRecipientType([...recipientType, 'admin_user']);
            }

            if (recipientSelected) {
                setRecipientID([...recipientID, recipientdata?._id]);
                setRecipientEmail([...recipientEmail, recipientdata?.email_address]);
            }

            // if (id) {
            //     setRecipientID([...recipientID, recipientdata?._id]);
            //     setRecipientEmail([...recipientEmail, recipientdata?.email_address]);
            // }
        }
    }, [recipientdata]);

    useEffect(() => {
        setFormState((prevState) => ({ ...prevState, recipient: recipientID, receiver_type: recipientType, email_address: recipientEmail }));
    }, [recipientID]);

    const handleSearch = (event) => {
        const searchTerm = event.target.value;
        setSearchQuery(searchTerm);
        // Emit a search event to the server
        socket.emit('search', searchQuery);
    };

    const selectRecipient = async (e) => {
        const id = e.target.getAttribute('data-id');
        setSearchResults([]);
        setRecipientSelected(true);
        setDisplayClass('hidden');
        await fetchRecipient(id);
    };

    const handleForm = (name, value) => {
        setFormState((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleSubmit = async () => {
        if (validateForm(formstate)) {
            handleCreateNewMessage(formstate);
            setRecipientID([]);
            setRecipientEmail([]);
            setRecipientSelected(false);
            setSearchQuery('');
        }
    };
    const validateForm = () => {
        if (!formstate.recipient || typeof formstate.recipient === 'undefined') {
            setSnackbarMessage(variables[lang].message_validate_recipient);
            setSnackbarOpen(true);
            setSnackbarSeverity('error');
            return false;
        }

        if (!formstate.subject || typeof formstate.subject === 'undefined') {
            setSnackbarMessage(variables[lang].message_validate_subject);
            setSnackbarOpen(true);
            setSnackbarSeverity('error');
            return false;
        }
        return true;
    };

    const snackbarOnClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <>
            <Dialog
                aria-describedby="stake-dialog-description"
                aria-labelledby="stake-dialog-title"
                className="dialog text-white"
                open={props.open}
                onClose={handleClose}>
                <DialogContent className="content">
                    <h3 className="font-oswald-text font-bold text-2xl text-white mb-4">
                        {variables[props.lang]['create_message']}
                    </h3>
                    <form>
                        <div className="search flex flex-col font-oswald-text text-white">
                            {(
                                <><FormLabel className="font-oswald-text text-white mb-2">{variables[lang].search}</FormLabel>
                                    <Input
                                        className="border rounded border-custom-white px-4 py-2 bg-custom-dark font-oswald-text text-custom-white text-lg"
                                        label={variables[lang].recipient}
                                        sx={{
                                            '&::before': {
                                                border: '1.5px solid rgba(255, 255, 255, 0.5)',
                                                transform: 'scaleX(0)',
                                                left: '2.5px',
                                                right: '2.5px',
                                                bottom: 0,
                                                top: 'unset',
                                                transition: 'transform .15s cubic-bezier(0.1,0.9,0.2,1)',
                                                borderRadius: 0,
                                                borderBottomLeftRadius: '64px 20px',
                                                borderBottomRightRadius: '64px 20px',
                                            },
                                            '&::after': {
                                                border: '1.5px solid rgba(255, 255, 255, 0.5)',
                                                transform: 'scaleX(0)',
                                                left: '2.5px',
                                                right: '2.5px',
                                                bottom: 0,
                                                top: 'unset',
                                                transition: 'transform .15s cubic-bezier(0.1,0.9,0.2,1)',
                                                borderRadius: 0,
                                                borderBottomLeftRadius: '64px 20px',
                                                borderBottomRightRadius: '64px 20px',
                                            },
                                            '&:focus-within::before': {
                                                transform: 'scaleX(1)',
                                            },
                                        }}
                                        type="text"
                                        value={searchQuery}
                                        onChange={handleSearch} /></>
                            )}
                        </div>
                        <div className={`${displayClass} bg-black font-oswald-text text-white p-4`}>
                            {Object.keys(searchResults).length > 0 && (
                                searchResults.map((result, index) => (
                                    <div key={index} className="cursor-pointer" data-email={result.email_address} data-id={result._id} onClick={selectRecipient}>
                                        {result.email_address}
                                    </div>
                                ))
                            )}
                        </div>
                        <div className="my-4">
                            <h3 className="capitalize text-white opacity-50">{variables[lang].recipients}:</h3>
                            {Object.keys(recipientEmail).length > 0 && (
                                <span className="text-white ">{recipientEmail.join(', ')}</span>
                            )}
                        </div>
                        <InputField
                            fieldName="subject"
                            handleForm={handleForm}
                            label={variables[lang].subject}
                        />
                        <div className="flex justify-center">
                            <button className="btn--primary btn--small border border-custom-dark-mode-red bg-custom-dark-mode-red rounded-md px-6 py-2" type="button" onClick={handleSubmit}>
                                <span className="text-white font-oswald-text capitalize">{variables[lang].submit}</span>
                            </button>
                        </div>
                    </form>
                </DialogContent>
                <DialogActions className="button_div">

                </DialogActions>
            </Dialog>
            <Snackbar
                message={snackbarMessage}
                open={snackbarOpen}
                severity={snackbarSeverity}
                onClose={snackbarOnClose}
            />
        </>
    );
};

CreateMessage.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleCreateNewMessage: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    fetchRecipient: PropTypes.func,
    id: PropTypes.string,
    isAdmin: PropTypes.bool,
    recipientdata: PropTypes.object,
};

const stateToProps = (state) => {
    const { recipient: recipientdata, isAdmin } = state.messaging.messageRecipient.recipient;
    return {
        lang: state.language,
        recipientdata,
        isAdmin,
    };
};

const actionToProps = {
    fetchRecipient,
};

export default connect(stateToProps, actionToProps)(CreateMessage);
