import { Card } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import variables from '../../../utils/variables';
import StakedFilter from './StakedFilter';
import UnstakedFilter from './UnstakeFilter';
import { fetchCardsInfo } from '../../../actions/dashboard';
import DotsLoading from '../../../components/DotsLoading';
import exnt1 from '../../../assets/images/exnt-1.png';
import exnt2 from '../../../assets/images/exnt-2.png';
import './index.css';

class Cards extends Component {
    componentDidMount () {
        if (this.props.inProgress || Object.keys(this.props.data).length > 0) {
            return;
        }

        this.props.fetch();
    }

    componentDidUpdate (pp, ps, ss) {
        if (this.props.auth && (pp.auth !== this.props.auth)) {
            this.props.fetch();
        }
    }

    render () {
        return (
            <div className="cards  -mx-4">
                <Card className="w-full lg:w-1/2 special-card mb-6 lg:mb-4">
                    <div className="bg-custom-dark mx-4 rounded-lg p-4 relative h-full">
                        <StakedFilter/>
                        <p className="mb-10 block text-base font-oswald-text font-semibold text-white name">{variables[this.props.lang]['staked_exnt'] + ' ' + this.props.stakeFilter}</p>
                        <div className="">
                            <span className="font-extrabold text-white font-oswald-text text-3xl break-all value">
                            NA
                            </span>
                        </div>
                        <div className="absolute bottom-0 right-0 w-5/12 opacity-40">
                            <img alt="exnt_1" className="w-full" src={exnt1}/>
                        </div>
                    </div>
                </Card>
                <Card className="w-full lg:w-1/2 special-card mb-6 lg:mb-4">
                    <div className="bg-custom-dark mx-4 rounded-lg p-4 relative h-full">
                        <UnstakedFilter/>
                        <p className="mb-10 block text-base font-oswald-text font-semibold text-white name">{variables[this.props.lang]['un_staked_exnt'] + ' ' + this.props.unstakeFilter}</p>
                        <div className="">
                            <span className="font-extrabold text-white font-oswald-text text-3xl break-all value">
                            NA
                            </span>
                        </div>
                        <div className="absolute bottom-0 right-0 w-5/12 opacity-40">
                            <img alt="exnt_2" className="w-full" src={exnt2}/>
                        </div>
                    </div>
                </Card>
                <Card className="w-full lg:w-1/2 special-card mb-6 lg:mb-4">
                    <div className="bg-custom-dark mx-4 rounded-lg p-4 relative h-full">
                        <span
                            className="mb-10 block text-base font-oswald-text font-semibold text-white name">
                            {this.props.inProgress
                                ? <DotsLoading/>
                                : this.props.data && (this.props.data.staked_count ||
                                    this.props.data.staked_count === 0)
                                    ? this.props.data.staked_count + ' '
                                    : null}
                            {variables[this.props.lang]['staked_exnt']}
                        </span>
                        <div className="">
                            {this.props.inProgress
                                ? <DotsLoading/>
                                : this.props.data && (this.props.data.staked ||
                                    this.props.data.staked === 0)
                                    ? <span
                                        className="font-extrabold font-oswald-text text-3xl text-white break-all value"
                                        title={this.props.data.staked * Math.pow(10, -16)}>
                                        {this.props.data.staked * Math.pow(10, -16)}
                                    </span>
                                    : <span className="font-extrabold font-oswald-text text-white text-2xl break-all value">
                                        NA
                                    </span>}
                        </div>
                        <div className="absolute bottom-0 right-0 w-5/12 opacity-40">
                            <img alt="exnt_1" className="w-full" src={exnt1}/>
                        </div>
                    </div>
                </Card>
                <Card className="w-full lg:w-1/2 special-card mb-6 lg:mb-4">
                    <div className="bg-custom-dark mx-4 rounded-lg p-4 relative h-full">
                        <span
                            className="mb-10 block text-base font-oswald-text font-semibold text-white name">
                            {this.props.inProgress
                                ? <DotsLoading/>
                                : this.props.data && (this.props.data.unstaked_count ||
                                    this.props.data.unstaked_count === 0)
                                    ? this.props.data.unstaked_count + ' '
                                    : null}
                            {variables[this.props.lang]['un_staked_exnt']}
                        </span>
                        <div>
                            {this.props.inProgress
                                ? <DotsLoading/>
                                : this.props.data && (this.props.data.unstaked ||
                                    this.props.data.unstaked === 0)
                                    ? <span
                                        className="font-extrabold font-oswald-text text-3xl text-white break-all value"
                                        title={this.props.data.unstaked * Math.pow(10, -16)}>
                                        {this.props.data.unstaked * Math.pow(10, -16)}
                                    </span>
                                    : <span className="font-extrabold font-oswald-text text-3xl text-white break-all value">
                                        NA
                                    </span>}
                        </div>
                        <div className="absolute bottom-0 right-0 w-5/12 opacity-40">
                            <img alt="exnt_2" className="w-full" src={exnt2}/>
                        </div>
                    </div>
                </Card>
            </div>
        );
    }
}

Cards.propTypes = {
    data: PropTypes.object.isRequired,
    fetch: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    stakeFilter: PropTypes.string.isRequired,
    unstakeFilter: PropTypes.string.isRequired,
    auth: PropTypes.string,
};

const stateToProps = (state) => {
    return {
        auth: state.accessToken.value,
        data: state.dashboard.cards.value,
        inProgress: state.dashboard.cards.inProgress,
        lang: state.language,
        stakeFilter: state.unstakeRequests.stakeFilter,
        unstakeFilter: state.unstakeRequests.unstakeFilter,
    };
};

const actionToProps = {
    fetch: fetchCardsInfo,
};

export default connect(stateToProps, actionToProps)(Cards);
