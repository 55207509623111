import {
    CONFIRM_PASSWORD_SET,
    EMAIL_ID_SET,
    PASSWORD_SET,
    REMEMBER_PASSWORD_SET,
    USER_SIGN_IN_ERROR,
    USER_SIGN_IN_IN_PROGRESS,
    USER_SIGN_IN_SUCCESS,
} from '../constants/signIn';
import Axios from 'axios';
import { USER_SIGN_IN } from '../constants/url';

export const setEmailId = (value) => {
    return {
        type: EMAIL_ID_SET,
        value,
    };
};

export const setPassword = (value) => {
    return {
        type: PASSWORD_SET,
        value,
    };
};

export const setRememberPassword = (value) => {
    return {
        type: REMEMBER_PASSWORD_SET,
        value,
    };
};

const userSignInInProgress = () => {
    return {
        type: USER_SIGN_IN_IN_PROGRESS,
    };
};

const userSignInSuccess = (data, message) => {
    return {
        type: USER_SIGN_IN_SUCCESS,
        data,
        message,
    };
};

const userSignInError = (message) => {
    return {
        type: USER_SIGN_IN_ERROR,
        message,
    };
};

export const userSignIn = (data, cb) => (dispatch) => {
    dispatch(userSignInInProgress());

    Axios.post(USER_SIGN_IN, data,
        { headers: { Accept: 'application/json' } })
        .then((res) => {
            if (res.data.result) {
                localStorage.setItem('accessToken', res.data.result['access_token']);
                localStorage.setItem('refreshToken', res.data.result['refresh_token']);
                localStorage.setItem('ticket', res.data.result.ticket);
            }
            dispatch(userSignInSuccess(res.data && res.data.result, 'Successfully login'));
            cb(null);
        })
        .catch((error) => {
            dispatch(userSignInError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            cb(error);
        });
};

export const setConfirmPassword = (value) => {
    return {
        type: CONFIRM_PASSWORD_SET,
        value,
    };
};
