import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { isEmpty } from 'lodash';
import moment from 'moment';
import * as PropTypes from 'prop-types';
import { SocketContext } from '../../context/socket';

const InboxModal = (props) => {
    const {
        history,
        open,
        messages,
        getRecievedMessages,
        userId,
    } = props;

    const socket = useContext(SocketContext);

    const itemClick = (id, isRead) => {
        history.push(`/inbox/${id}`);
        if (isRead) {
            history.push(`/inbox/${id}`);
        } else {
            socket.emit('read-message', { id }, (error, result) => {
                if (result) {
                    getRecievedMessages(userId);
                } else if (error) {
                    console.error(error);
                }

                history.push(`/inbox/${id}`);
            });
        }
        close();
    };

    const renderItem = (item, idx) => {
        const {
            _id: id,
            created_at: createdAt,
            message,
            is_read: isRead,
        } = item;
        const timeFromNow = moment(createdAt).fromNow();
        return <div
            key={idx}
            className={`inbox__item shrink-0 cursor-pointer ${!isRead && 'unread'}`}
            onClick={() => itemClick(id, isRead)}
        >
            <div className="grid grid-cols-2 w-full">
                <div className="inbox__item--detail text-left">
                    <div dangerouslySetInnerHTML={{ __html: message }} className="inner-text" />
                </div>
                <div className="inbox__item--time text-right">
                    {timeFromNow}
                </div>
            </div>
        </div>;
    };

    return (
        <div className={open ? 'inbox__container active' : 'inbox__container'}>
            <div className="inbox__wrapper">
                <div className="inbox__header flex">
                    <div className="inbox__header-box flex justify-between items-center w-full px-2">
                        <h2 className="inbox__title text-base mr-3 text-white font-semibold font-oswald-text tracking-wider child">Emails</h2>
                        <span className="inbox__see_all child">
                            <Link to="/inbox">See all emails</Link>
                        </span>
                    </div>
                </div>
                <div className="inbox__body text-left">
                    {
                        messages && !isEmpty(messages) && messages.map((message, index) => (
                            renderItem(message, index)
                        ))
                    }
                </div>
            </div>
        </div>
    );
};

InboxModal.propTypes = {
    getRecievedMessages: PropTypes.func.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    open: PropTypes.bool.isRequired,
    messages: PropTypes.any,
    userId: PropTypes.string,
};

export default withRouter(InboxModal);
