import { combineReducers } from 'redux';
import {
    AUTHENTICATION_CODE_SET,
    AUTHENTICATION_DIALOG_HIDE,
    AUTHENTICATION_DIALOG_SHOW,
    AUTHENTICATION_ERROR_DIALOG_HIDE,
    AUTHENTICATION_ERROR_DIALOG_SHOW,
    AUTHENTICATION_SUCCESS_DIALOG_HIDE,
    AUTHENTICATION_SUCCESS_DIALOG_SHOW,
    AUTHENTICATION_SWITCH_VALUE_SET,
    AUTHENTICATION_SWITCH_VERIFY_DIALOG_HIDE,
    AUTHENTICATION_SWITCH_VERIFY_DIALOG_SHOW,
    CHANGE_PASSSWORD_DIALOG_HIDE,
    CHANGE_PASSSWORD_DIALOG_SHOW,
    HIDE_DEAL_FORM,
    PASSWORD_UPDATE_ERROR,
    PASSWORD_UPDATE_IN_PROGRESS,
    PASSWORD_UPDATE_SUCCESS,
    SETTING_DEAL_TIER_LIMIT,
    SETTING_DEAL_TIME,
    SETTING_TIMEZONE,
    SETTING_TIMEZONE_VALUE,
    SETTINGS_TAB_VALUE_SET,
    SHOW_DEAL_FORM,
    TWO_FACTOR_AUTH_ADD_ERROR,
    TWO_FACTOR_AUTH_ADD_IN_PROGRESS,
    TWO_FACTOR_AUTH_ADD_SUCCESS,
    TWO_FACTOR_AUTH_VERIFY_ERROR,
    TWO_FACTOR_AUTH_VERIFY_IN_PROGRESS,
    TWO_FACTOR_AUTH_VERIFY_SUCCESS,
} from '../constants/settings';
import { USER_SIGN_IN_SUCCESS } from '../constants/signIn';

const changePassword = (state = {
    inProgress: false,
}, action) => {
    switch (action.type) {
    case PASSWORD_UPDATE_IN_PROGRESS:
        return {
            inProgress: true,
        };
    case PASSWORD_UPDATE_SUCCESS:
    case PASSWORD_UPDATE_ERROR:
        return {
            inProgress: false,
        };
    default:
        return state;
    }
};

const changePasswordDialog = (state = false, action) => {
    switch (action.type) {
    case CHANGE_PASSSWORD_DIALOG_SHOW:
        return true;
    case CHANGE_PASSSWORD_DIALOG_HIDE:
        return false;
    default:
        return state;
    }
};

const tabValue = (state = 0, action) => {
    if (action.type === SETTINGS_TAB_VALUE_SET) {
        return action.value;
    }

    return state;
};

const switchValue = (state = false, action) => {
    if (action.type === AUTHENTICATION_SWITCH_VALUE_SET) {
        return action.value;
    }

    return state;
};

const dialog = (state = false, action) => {
    switch (action.type) {
    case AUTHENTICATION_DIALOG_SHOW:
        return true;
    case AUTHENTICATION_DIALOG_HIDE:
        return false;
    default:
        return state;
    }
};

const authCode = (state = '', action) => {
    switch (action.type) {
    case AUTHENTICATION_CODE_SET:
        return action.value;
    case AUTHENTICATION_DIALOG_HIDE:
        return '';
    default:
        return state;
    }
};

const successDialog = (state = false, action) => {
    switch (action.type) {
    case AUTHENTICATION_SUCCESS_DIALOG_SHOW:
        return true;
    case AUTHENTICATION_SUCCESS_DIALOG_HIDE:
        return false;
    default:
        return state;
    }
};

const errorDialog = (state = false, action) => {
    switch (action.type) {
    case AUTHENTICATION_ERROR_DIALOG_SHOW:
        return true;
    case AUTHENTICATION_ERROR_DIALOG_HIDE:
        return false;
    default:
        return state;
    }
};

const twoFactorAuthStatus = (state = 'DISABLED', action) => {
    if (action.type === USER_SIGN_IN_SUCCESS) {
        return action.data && action.data.ticket ? 'ENABLED' : 'DISABLED';
    }

    return state;
};

const addTwoFactorAuth = (state = {
    inProgress: false,
    data: {},
}, action) => {
    switch (action.type) {
    case TWO_FACTOR_AUTH_ADD_SUCCESS:
        return {
            inProgress: false,
            data: action.data,
        };
    case TWO_FACTOR_AUTH_ADD_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case TWO_FACTOR_AUTH_ADD_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const verifyTwoFactorAuth = (state = {
    inProgress: false,
    data: {},
}, action) => {
    switch (action.type) {
    case TWO_FACTOR_AUTH_VERIFY_SUCCESS:
        return {
            inProgress: false,
            data: action.data,
        };
    case TWO_FACTOR_AUTH_VERIFY_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case TWO_FACTOR_AUTH_VERIFY_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const switchVerifyDialog = (state = false, action) => {
    switch (action.type) {
    case AUTHENTICATION_SWITCH_VERIFY_DIALOG_SHOW:
        return true;
    case AUTHENTICATION_SWITCH_VERIFY_DIALOG_HIDE:
        return false;
    default:
        return state;
    }
};

// Deals Settings

const selectedTimezone = (state = {}, action) => {
    if (action.type === SETTING_TIMEZONE) {
        return action.value;
    }

    return state;
};

const selectedTimezoneValue = (state = '', action) => {
    if (action.type === SETTING_TIMEZONE_VALUE) {
        return action.value;
    }

    return state;
};

const selectedTime = (state = '7:30', action) => {
    if (action.type === SETTING_DEAL_TIME) {
        return action.value;
    }

    return state;
};

const selectedTierLimit = (state = 'T1 (Top 20)', action) => {
    if (action.type === SETTING_DEAL_TIER_LIMIT) {
        return action.value;
    }

    return state;
};

const dealFormDialog = (state = false, action) => {
    switch (action.type) {
    case SHOW_DEAL_FORM:
        return true;
    case HIDE_DEAL_FORM:
        return false;
    default:
        return state;
    }
};

export default combineReducers({
    changePassword,
    tabValue,
    switchValue,
    dialog,
    authCode,
    successDialog,
    errorDialog,
    twoFactorAuthStatus,
    addTwoFactorAuth,
    verifyTwoFactorAuth,
    switchVerifyDialog,
    changePasswordDialog,
    selectedTimezone,
    selectedTimezoneValue,
    selectedTime,
    selectedTierLimit,
    dealFormDialog,
});
