import { INFO_MESSAGE_SHOW, SNACKBAR_HIDE } from '../constants/snackbar';

export const hideSnackbar = () => {
    return {
        type: SNACKBAR_HIDE,
    };
};

export const showInfoMessage = (message) => {
    return {
        type: INFO_MESSAGE_SHOW,
        message,
    };
};
