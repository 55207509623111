import React from 'react';
import * as PropTypes from 'prop-types';
import { MenuItem, Tab, Tooltip, withStyles } from '@material-ui/core';
import variables from '../../utils/variables';
import Icon from '../../components/Icon';

const list = [{
    name: 'Unstake Request',
    link: 'unstake',
}];

const CustomTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#fff',
        color: '#000',
        boxShadow: theme.shadows[1],
        margin: 'unset',
        padding: '0',
    },
}))(Tooltip);

const RequestsTab = (props) => {
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleNav = (link) => {
        props.handleChange('requests', link);
        handleClose();
    };

    return (
        <CustomTooltip
            interactive
            id="requests-tab-tooltip"
            open={open}
            title={list.map((value) => {
                return (
                    <MenuItem
                        key={value.name}
                        onClick={() => handleNav(value.link)}>
                        {value.name}
                    </MenuItem>
                );
            })}
            onClose={handleClose}
            onOpen={handleClick}>
            <Tab
                className={'tab sub_menu ' + (props.value === 'requests' ? 'active_tab' : '')}
                icon={<Icon
                    className={'requests' + (open ? ' active' : '')}
                    icon="requests"/>}
                label={variables[props.lang].requests}
                value="requests"
                {...props.a11yProps(1)} />
        </CustomTooltip>
    );
};

RequestsTab.propTypes = {
    a11yProps: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
};

export default RequestsTab;
