import { INFO_MESSAGE_SHOW, SNACKBAR_HIDE } from '../constants/snackbar';
import { USER_SIGN_IN_ERROR, USER_SIGN_IN_SUCCESS } from '../constants/signIn';
import {
    PROFILE_DETAILS_FETCH_ERROR,
    PROFILE_IMAGE_FETCH_ERROR,
    SIGN_OUT_ERROR,
    SIGN_OUT_SUCCESS,
} from '../constants/navigationBar';
import {
    PASSWORD_UPDATE_ERROR,
    PASSWORD_UPDATE_SUCCESS,
    TWO_FACTOR_AUTH_ADD_ERROR,
    TWO_FACTOR_AUTH_UPDATE_ERROR,
    TWO_FACTOR_AUTH_VERIFY_BACKUP_TOKEN_ERROR,
    TWO_FACTOR_AUTH_VERIFY_BACKUP_TOKEN_SUCCESS,
    TWO_FACTOR_AUTH_VERIFY_ERROR,
} from '../constants/settings';
import {
    RECOVERY_EMAIL_ADD_ERROR,
    RECOVERY_EMAIL_ADD_SUCCESS,
    RECOVERY_PASSWORD_ERROR,
    RECOVERY_PASSWORD_SUCCESS,
} from '../constants/forgotPassword';
import { FILE_DOWNLOAD_ERROR, FILE_DOWNLOAD_SUCCESS, UN_STAKE_REPORT_FETCH_ERROR } from '../constants/unstakeRequests';
import { USERS_LIST_FETCH_ERROR } from '../constants/users';
import { CARDS_INFO_FETCH_ERROR, EXNG_CLAIMS_FETCH_ERROR } from '../constants/dashboard';
import { TIPPING_LIST_FETCH_ERROR } from '../constants/tipping';

const snackbar = (state = {
    open: false,
    message: '',
}, action) => {
    switch (action.type) {
    case USER_SIGN_IN_SUCCESS:
    case USER_SIGN_IN_ERROR:
    case PROFILE_DETAILS_FETCH_ERROR:
    case PROFILE_IMAGE_FETCH_ERROR:
    case PASSWORD_UPDATE_ERROR:
    case PASSWORD_UPDATE_SUCCESS:
    case RECOVERY_EMAIL_ADD_ERROR:
    case RECOVERY_EMAIL_ADD_SUCCESS:
    case SIGN_OUT_ERROR:
    case SIGN_OUT_SUCCESS:
    case INFO_MESSAGE_SHOW:
    case RECOVERY_PASSWORD_ERROR:
    case RECOVERY_PASSWORD_SUCCESS:
    case TWO_FACTOR_AUTH_ADD_ERROR:
    case TWO_FACTOR_AUTH_VERIFY_ERROR:
    case TWO_FACTOR_AUTH_UPDATE_ERROR:
    case TWO_FACTOR_AUTH_VERIFY_BACKUP_TOKEN_ERROR:
    case TWO_FACTOR_AUTH_VERIFY_BACKUP_TOKEN_SUCCESS:
    case FILE_DOWNLOAD_SUCCESS:
    case FILE_DOWNLOAD_ERROR:
    case USERS_LIST_FETCH_ERROR:
    case CARDS_INFO_FETCH_ERROR:
    case UN_STAKE_REPORT_FETCH_ERROR:
    case EXNG_CLAIMS_FETCH_ERROR:
    case TIPPING_LIST_FETCH_ERROR:
        return {
            open: true,
            message: action.message,
        };
    case SNACKBAR_HIDE:
        return {
            open: false,
            message: '',
        };
    default:
        return state;
    }
};

export default snackbar;
