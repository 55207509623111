import React, {
    useState,
    useEffect,
    useContext,
} from 'react';
import * as PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { Button, Dialog, DialogActions, DialogContent, FormControlLabel, RadioGroup, Radio } from '@material-ui/core';
import { connect } from 'react-redux';
import Snackbar from '../../components/Snackbar';
import TextField from '../../components/TextField';
import close from '../../assets/images/close.svg';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { addTask, updateTask } from '../../actions/tasks';
import { SocketContext } from '../../context/socket';

const TaskDialog = (props) => {
    const {
        addTask,
        updateTask,
        updateTaskList,
        profileDetails: {
            _id,
        },
    } = props;
    const [title, setTitle] = useState('');
    const [link, setLink] = useState('');
    const [instruction, setInstruction] = useState('');
    const [taskType, setTaskType] = useState('TWITTER');
    const [hasParticipants, setHasParticipants] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackBarType, setSnackBarType] = useState('success');
    const currentDate = new Date();
    const [deadline, setDeadline] = useState(currentDate);
    const [modalTitle, setModalTitle] = useState('Create');
    const socket = useContext(SocketContext);

    useEffect(() => {
        if (!isEmpty(props.item)) {
            setTitle(props.item.title);
            setLink(props.item.link);
            setInstruction(props.item.description);
            setTaskType(props.item.type);
            setDeadline(props.item.deadline_at ? new Date(props.item.deadline_at) : currentDate);
            setModalTitle('Edit');
            setHasParticipants(!isEmpty(props.item.participants));
        } else {
            setTitle('');
            setLink('');
            setInstruction('');
            setTaskType('TWITTER');
            setDeadline(props.item.deadline_at ? new Date(props.item.deadline_at) : currentDate);
            setModalTitle('Create');
            setHasParticipants(false);
        }
    }, [props.item]);

    const handleChange = (value) => {
        setTaskType(value.target.value);
    };

    const openSnackbarOnError = (message) => {
        setSnackbarMessage(message);
        setSnackbarOpen(true);
    };

    const openSnackbarOnSuccess = (message) => {
        setSnackbarMessage(message);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleForm = () => {
        if (!title) {
            openSnackbarOnError('title required');
        }

        if (!link) {
            openSnackbarOnError('link required');
        }

        const data = {
            type: taskType,
            title,
            link,
            description: instruction,
            deadlineAt: deadline,
            ...isEmpty(props.item) && { owner: _id },
        };

        if (isEmpty(props.item)) {
            addTask(data, (error, result) => {
                if (!error) {
                    openSnackbarOnSuccess('Task Successfully added.');
                    setSnackBarType('success');

                    const data = {
                        from: _id,
                        fromType: 'admin_user',
                        reference: result,
                        referenceType: 'task',
                        content: 'New task created',
                        isTaskUpdate: false,
                    };

                    socket.emit('new-notification', data, (error) => {
                        if (error) {
                            openSnackbarOnError(`${error}`);
                        } else {
                            resetForm();
                            props.handleClose();
                            updateTaskList();
                        }
                    });
                } else {
                    openSnackbarOnError(`${error}`);
                    setSnackBarType('error');
                }
            });
        } else {
            updateTask(props.item._id, data, (error) => {
                if (!error) {
                    openSnackbarOnSuccess('Task Successfully updated.');
                    resetForm();
                    setSnackBarType('success');
                    props.handleClose();
                    updateTaskList();
                } else {
                    openSnackbarOnError(`${error}`);
                    setSnackBarType('error');
                }
            });
        }
    };

    const resetForm = () => {
        setTitle('');
        setLink('');
        setTaskType('TWITTER');
        setInstruction('');
        setDeadline(currentDate);
        setHasParticipants(false);
    };

    return (
        <>
            <Snackbar
                message={snackbarMessage}
                open={snackbarOpen}
                severity={snackBarType}
                onClose={handleSnackbarClose}
            />
            <Dialog
                aria-describedby="account-type-dialog-description"
                aria-labelledby="account-type-dialog-title"
                className="dialog account_verification"
                open={props.open}
                onClose={props.handleClose}>
                <DialogContent className="tasks-dialog content">
                    <div className="flex flex-row justify-between items-center mb-2">
                        <div className="flex flex-col">
                            <span className="font-semibold font-oswald-text text-xl text-white">{ modalTitle } Task</span>
                        </div>
                        <img alt="close" className="task-close" src={close} onClick={props.handleClose} />
                    </div>
                    <div className="py-2 md:py-4">
                        <span className="font-oswald-text text-md block text-white">Select the type of task</span>
                        <RadioGroup
                            row
                            aria-label="task-type"
                            defaultValue={taskType}
                            name="radio-buttons-group"
                            onChange={handleChange}
                        >
                            <FormControlLabel className="task-radio text-white" control={<Radio />} disabled={true} label="Twitter" value="TWITTER" />
                            <FormControlLabel className="task-radio text-white" control={<Radio />} disabled={true} label="Manual" value="MANUAL" />
                        </RadioGroup>
                    </div>
                    <div className="task-dialog__field pt-2">
                        <label className="font-oswald-text text-white text-xs mb-0 block">Title</label>
                        <TextField
                            className="rounded-md bg-custom-semi-gray"
                            id="title"
                            name="title"
                            placeholder="Enter title of the task"
                            value={title}
                            onChange={setTitle}
                        />
                    </div>
                    {(taskType === 'TWITTER') &&
                    <div className="task-dialog__field pt-2">
                        <label className="font-oswald-text text-xs mb-0 block">Paste link to tweet</label>
                        <TextField
                            className="rounded-md bg-custom-semi-gray"
                            disable={hasParticipants}
                            id="link"
                            name="link"
                            placeholder="Add your link here"
                            value={link}
                            onChange={setLink}
                        />
                    </div>}
                    {(taskType === 'MANUAL') &&
                    <>
                        <div className="task-dialog__field pt-2 md:pt-4">
                            <label className="font-oswald-text text-xs mb-0 block">Link</label>
                            <TextField
                                className="rounded-md bg-custom-semi-gray"
                                disable={hasParticipants}
                                id="link"
                                name="link"
                                placeholder="Add your link here"
                                value={link}
                                onChange={setLink}
                            />
                        </div>
                        <div className="task-dialog__field pt-2 md:pt-4">
                            <label className="font-oswald-text text-xs mb-0 block">Instructions</label>
                            <TextField
                                className="rounded-md bg-custom-semi-gray"
                                id="link"
                                multiline={true}
                                name="link"
                                placeholder="Add your link here"
                                value={instruction}
                                onChange={setInstruction}
                            />
                        </div>
                    </>}
                    <div className="task-dialog__field pt-2">
                        <label className="font-oswald-text text-xs mb-2 block">Deadline</label>
                        <DatePicker className="p-3 rounded-md bg-custom-semi-gray" selected={deadline} onChange={(date) => setDeadline(date)}/>
                    </div>
                </DialogContent>
                <DialogActions className="tasks-actions mt-0 text-center mb-8 flex flex-col">
                    <Button className="w-full button-upload text-2xl" onClick={handleForm}>{isEmpty(props.item) ? 'Post Task' : 'Update Task'}</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

TaskDialog.propTypes = {
    addTask: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    profileDetails: PropTypes.object.isRequired,
    updateTask: PropTypes.func.isRequired,
    updateTaskList: PropTypes.func.isRequired,
    item: PropTypes.object,
};

const stateToProps = (state) => {
    return {
        profileDetails: state.navigationBar.profileDetails.value,
    };
};

const actionToProps = {
    addTask,
    updateTask,
};

export default connect(stateToProps, actionToProps)(TaskDialog);
