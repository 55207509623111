import Axios from 'axios';
import {
    CONTRIBUTOR_FETCH_ERROR,
    CONTRIBUTOR_FETCH_IN_PROGRESS,
    CONTRIBUTOR_FETCH_SUCCESS,
    CONTRIBUTORS_FETCH_ERROR,
    CONTRIBUTORS_FETCH_IN_PROGRESS,
    CONTRIBUTORS_FETCH_SUCCESS,
    DEAL_FETCH_ERROR,
    DEAL_FETCH_IN_PROGRESS,
    DEAL_FETCH_SUCCESS,
    DEALS_FETCH_ERROR,
    DEALS_FETCH_IN_PROGRESS,
    DEALS_FETCH_SUCCESS,
    GET_CONTRIBUTOR_ACCOUNT_ERROR,
    GET_CONTRIBUTOR_ACCOUNT_SUCCESS,
    NEW_DEAL_ERROR,
    NEW_DEAL_IN_PROGRESS,
    NEW_DEAL_SUCCESS,
    UPDATE_DEAL_ERROR,
    UPDATE_DEAL_IN_PROGRESS,
    UPDATE_DEAL_SUCCESS,
} from '../constants/deals';
import {
    contributorsListURL,
    dealsListURL,
    getContributorAccountURL,
    NEW_DEAL_URL,
    singleContributorURL,
    singleDealURL,
} from '../constants/url';

const fetchDealsInProgress = () => {
    return {
        type: DEALS_FETCH_IN_PROGRESS,
    };
};

const fetchDealsSuccess = (value, skip, limit, searchKey, sortBy, order) => {
    return {
        type: DEALS_FETCH_SUCCESS,
        value,
        skip,
        limit,
        searchKey,
        sortBy,
        order,
    };
};

const fetchDealsError = (message) => {
    return {
        type: DEALS_FETCH_ERROR,
        message,
    };
};

const fetchDealInProgress = () => {
    return {
        type: DEAL_FETCH_IN_PROGRESS,
    };
};

const fetchDealSuccess = (item) => {
    return {
        type: DEAL_FETCH_SUCCESS,
        item,
    };
};

const fetchDealError = (message) => {
    return {
        type: DEAL_FETCH_ERROR,
        message,
    };
};

const addDealInProgress = () => {
    return {
        type: NEW_DEAL_IN_PROGRESS,
    };
};

const addDealSuccess = (message) => {
    return {
        message,
        type: NEW_DEAL_SUCCESS,
    };
};

const addDealError = (message) => {
    return {
        message,
        type: NEW_DEAL_ERROR,
    };
};

const fetchContributorsInProgress = () => {
    return {
        type: CONTRIBUTORS_FETCH_IN_PROGRESS,
    };
};

const fetchContributorsSuccess = (value, skip, limit, searchKey, sortBy, order) => {
    return {
        type: CONTRIBUTORS_FETCH_SUCCESS,
        value,
        skip,
        limit,
        searchKey,
        sortBy,
        order,
    };
};

const fetchContributorsError = (message) => {
    return {
        type: CONTRIBUTORS_FETCH_ERROR,
        message,
    };
};

const fetchContributorInProgress = () => {
    return {
        type: CONTRIBUTOR_FETCH_IN_PROGRESS,
    };
};

const fetchContributorSuccess = (item) => {
    return {
        type: CONTRIBUTOR_FETCH_SUCCESS,
        item,
    };
};

const fetchContributorError = (message) => {
    return {
        type: CONTRIBUTOR_FETCH_ERROR,
        message,
    };
};

const updateDealInProgress = () => {
    return {
        type: UPDATE_DEAL_IN_PROGRESS,
    };
};

const updateDealSuccess = (message, item) => {
    return {
        type: UPDATE_DEAL_SUCCESS,
        item,
    };
};

const updateDealError = (message) => {
    return {
        type: UPDATE_DEAL_ERROR,
        message,
    };
};

const fetchContributorAccountSuccess = (item) => {
    return {
        type: GET_CONTRIBUTOR_ACCOUNT_SUCCESS,
        item,
    };
};

const fetchContributorAccountError = (message) => {
    return {
        type: GET_CONTRIBUTOR_ACCOUNT_ERROR,
        message,
    };
};

export const fetchDeals = (skip, limit, searchKey, sortBy, order) => (dispatch) => {
    dispatch(fetchDealsInProgress());

    const URL = dealsListURL(skip, limit, searchKey, sortBy, order);
    Axios.get(URL, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
    }).then((res) => {
        dispatch(fetchDealsSuccess(res.data && res.data.result,
            skip, limit, searchKey, sortBy, order));
    }).catch((error) => {
        dispatch(fetchDealsError(
            error.response &&
            error.response.data &&
            error.response.data.message
                ? error.response.data.message
                : 'Failed!',
        ));
    });
};

export const fetchDeal = (ID) => (dispatch) => {
    dispatch(fetchDealInProgress());

    const URL = singleDealURL(ID);
    Axios.get(URL, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
    }).then((res) => {
        dispatch(fetchDealSuccess(res.data.result));
    }).catch((error) => {
        dispatch(fetchDealError(
            error.response &&
            error.response.data &&
            error.response.data.message
                ? error.response.data.message
                : 'Failed!',
        ));
    });
};

export const addNewDeal = (data, cb) => (dispatch) => {
    dispatch(addDealInProgress());

    const URL = NEW_DEAL_URL;
    Axios.post(URL, data, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
    })
        .then((res) => {
            dispatch(addDealSuccess('New deal added successfully.'));
            cb(null, res.data && res.data.result && res.data.result._id);
        })
        .catch((error) => {
            dispatch(addDealError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            cb(error);
        });
};

export const updateDeal = (id, data, cb) => (dispatch) => {
    dispatch(updateDealInProgress());

    const URL = singleDealURL(id);
    Axios.put(URL, data, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
    })
        .then((res) => {
            dispatch(updateDealSuccess('Deal updated successfully!'));
            cb(null, res.data && res.data.result && res.data.result._id);
        })
        .catch((error) => {
            dispatch(updateDealError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            cb(error);
        });
};

export const fetchContributors = (id, skip, limit, searchKey, sortBy, order) => (dispatch) => {
    dispatch(fetchContributorsInProgress());

    const URL = contributorsListURL(id, skip, limit, searchKey, sortBy, order);
    Axios.get(URL, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
    }).then((res) => {
        dispatch(fetchContributorsSuccess(res.data && res.data.result,
            skip, limit, searchKey, sortBy, order));
    }).catch((error) => {
        dispatch(fetchContributorsError(
            error.response &&
            error.response.data &&
            error.response.data.message
                ? error.response.data.message
                : 'Failed!',
        ));
    });
};

export const fetchContributor = (ID, contributorID, cb) => (dispatch) => {
    dispatch(fetchContributorInProgress());

    const URL = singleContributorURL(ID, contributorID);
    Axios.get(URL, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
    }).then((res) => {
        dispatch(fetchContributorSuccess(res.data.result));
        cb(null, res.data && res.data.result && res.data.result.user);
    }).catch((error) => {
        dispatch(fetchContributorError(
            error.response &&
            error.response.data &&
            error.response.data.message
                ? error.response.data.message
                : 'Failed!',
        ));
    });
};

export const getContributorAccount = (ID) => (dispatch) => {
    const URL = getContributorAccountURL(ID);
    Axios.get(URL, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
    }).then((res) => {
        dispatch(fetchContributorAccountSuccess(res.data.result));
    }).catch((error) => {
        dispatch(fetchContributorAccountError(
            error.response &&
            error.response.data &&
            error.response.data.message
                ? error.response.data.message
                : 'Failed!',
        ));
    });
};
