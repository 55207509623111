import { CARDS_INFO_URL, EXNGClaimsURL } from '../constants/url';
import Axios from 'axios';
import {
    CARDS_INFO_FETCH_ERROR,
    CARDS_INFO_FETCH_IN_PROGRESS,
    CARDS_INFO_FETCH_SUCCESS,
    EXNG_CLAIMS_FETCH_ERROR,
    EXNG_CLAIMS_FETCH_IN_PROGRESS,
    EXNG_CLAIMS_FETCH_SUCCESS,
} from '../constants/dashboard';

const fetchEXNGClaimsInProgress = () => {
    return {
        type: EXNG_CLAIMS_FETCH_IN_PROGRESS,
    };
};

const fetchEXNGClaimsSuccess = (value, skip, limit, searchKey, sortBy, order) => {
    return {
        type: EXNG_CLAIMS_FETCH_SUCCESS,
        value,
        skip,
        limit,
        searchKey,
        sortBy,
        order,
    };
};

const fetchEXNGClaimsError = (message) => {
    return {
        type: EXNG_CLAIMS_FETCH_ERROR,
        message,
    };
};

export const fetchEXNGClaims = (skip, limit, searchKey, sortBy, order) => (dispatch) => {
    dispatch(fetchEXNGClaimsInProgress());

    const URL = EXNGClaimsURL(skip, limit, searchKey, sortBy, order);
    Axios.get(URL, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
    })
        .then((res) => {
            dispatch(fetchEXNGClaimsSuccess(res.data && res.data.result,
                skip, limit, searchKey, sortBy, order));
        })
        .catch((error) => {
            dispatch(fetchEXNGClaimsError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const fetchCardsInfoInProgress = () => {
    return {
        type: CARDS_INFO_FETCH_IN_PROGRESS,
    };
};

const fetchCardsInfoSuccess = (value) => {
    return {
        type: CARDS_INFO_FETCH_SUCCESS,
        value,
    };
};

const fetchCardsInfoError = (message) => {
    return {
        type: CARDS_INFO_FETCH_ERROR,
        message,
    };
};

export const fetchCardsInfo = () => (dispatch) => {
    dispatch(fetchCardsInfoInProgress());

    Axios.get(CARDS_INFO_URL, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
    })
        .then((res) => {
            dispatch(fetchCardsInfoSuccess(res.data && res.data.result));
        })
        .catch((error) => {
            dispatch(fetchCardsInfoError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};
