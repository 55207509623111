import { combineReducers } from 'redux';
import {
    RECOVERY_EMAIL_ADD_ERROR,
    RECOVERY_EMAIL_ADD_IN_PROGRESS,
    RECOVERY_EMAIL_ADD_SUCCESS,
    RECOVERY_PASSWORD_ERROR,
    RECOVERY_PASSWORD_IN_PROGRESS,
    RECOVERY_PASSWORD_SUCCESS,
} from '../constants/forgotPassword';

const recoveryEmailInProgress = (state = false, action) => {
    switch (action.type) {
    case RECOVERY_EMAIL_ADD_IN_PROGRESS:
        return true;
    case RECOVERY_EMAIL_ADD_SUCCESS:
    case RECOVERY_EMAIL_ADD_ERROR:
        return false;
    default:
        return state;
    }
};

const recoveryPasswordInProgress = (state = false, action) => {
    switch (action.type) {
    case RECOVERY_PASSWORD_IN_PROGRESS:
        return true;
    case RECOVERY_PASSWORD_SUCCESS:
    case RECOVERY_PASSWORD_ERROR:
        return false;
    default:
        return state;
    }
};

export default combineReducers({
    recoveryEmailInProgress,
    recoveryPasswordInProgress,
});
