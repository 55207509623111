import { Slide, Snackbar as MaterialSnackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import * as PropTypes from 'prop-types';
import React from 'react';
import './index.css';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../utils/variables';
import ErrorDialog from '../../containers/Tasks/ErrorDialog';
import DoneDialog from '../../containers/Tasks/DoneDialog';

const TransitionUp = (props) => <Slide direction="up" {...props}/>;

const Snackbar = (props) => {
    return (
        <>
            {
                (
                    props.message && ERROR_MESSAGE.includes(props.message)
                ) ? (
                        <ErrorDialog
                            handleClose={props.onClose}
                            open={props.open}/>
                    ) : (
                        (props.message && SUCCESS_MESSAGE.includes(props.message))
                            ? (
                                <DoneDialog
                                    handleClose={props.onClose}
                                    message={props.message}
                                    open={props.open}/>
                            ) : (
                                props.severity ? (
                                    <MaterialSnackbar
                                        ContentProps={{
                                            'aria-describedby': 'message-id',
                                        }}
                                        TransitionComponent={TransitionUp}
                                        autoHideDuration={5000}
                                        open={props.open}
                                        onClose={props.onClose}
                                    >
                                        <Alert severity={props.severity} sx={{ width: '100%' }} onClose={props.onClose}>
                                            {props.message}
                                        </Alert>
                                    </MaterialSnackbar>
                                ) : (
                                    <MaterialSnackbar
                                        ContentProps={{
                                            'aria-describedby': 'message-id',
                                        }}
                                        TransitionComponent={TransitionUp}
                                        autoHideDuration={5000}
                                        className="snackbar"
                                        message={<span id="message-id">{props.message}</span>}
                                        open={props.open}
                                        onClose={props.onClose}
                                    />
                                )
                            )
                    )
            }
        </>
    );
};

Snackbar.propTypes = {
    message: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    severity: PropTypes.string,
};

export default Snackbar;
