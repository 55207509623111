import { Popover, Typography, createTheme, ThemeProvider } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';
import SignOut from './SignOut';
// import AddAccount from './AddAccount';
import Settings from './Settings';
import ProfilePage from './ProfilePage';
// import Inbox from './Inbox';

const Profile = (props) => {
    const {
        profileDetails: {
            name,
            email_address: emailAddress,
        },
        imageUrl,
    } = props;

    const theme = createTheme({
        typography: {
            fontFamily: 'Oswald',
        },
    });

    const displayName = name || (emailAddress
        ? emailAddress &&
            emailAddress.split('@')[0]
            ? emailAddress.split('@')[0]
            : emailAddress
        : 'Account1');

    const handleClick = () => {
        if (props.show) {
            props.hideSideBar();
        }

        props.handleClose();
    };

    return (
        <Popover
            anchorEl={props.anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            className="profile_popover"
            id={props.id}
            open={props.open}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            onClose={props.handleClose}>
            <div className="profile__info flex flex-row ml-5">
                <div className="navigation__profile">
                    <img alt="profile" className="w-12" src={imageUrl}/>
                </div>
                <div className="flex flex-col justify-center">
                    <p className="name ml-3.5" title={displayName}>
                        {displayName}
                    </p>
                    <span className="ml-3.5 text-xs normal-case truncate">{emailAddress}</span>
                </div>
            </div>
            <hr className="w-4/5 mt-4 mb-1 mx-auto profile__separator" />
            <ThemeProvider theme={theme}>
                <Typography className="list_button">
                    <ProfilePage lang={props.lang} onClick={handleClick}/>
                </Typography>
                <Typography className="list_button">
                    <Settings lang={props.lang} onClick={handleClick}/>
                </Typography>
                <Typography className="list_button sign_out">
                    <SignOut lang={props.lang} onClick={handleClick}/>
                </Typography>
            </ThemeProvider>
        </Popover>
    );
};

Profile.propTypes = {
    handleClose: PropTypes.func.isRequired,
    hideSideBar: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    profileDetails: PropTypes.object.isRequired,
    show: PropTypes.bool.isRequired,
    anchorEl: PropTypes.any,
    id: PropTypes.string,
    imageUrl: PropTypes.string,
};

export default withRouter(Profile);
