export const STAKED_REQUEST_FILTER_SET = 'STAKED_REQUEST_FILTER_SET';
export const UN_STAKED_REQUEST_FILTER_SET = 'UN_STAKED_REQUEST_FILTER_SET';

export const UN_STAKE_REPORT_FETCH_IN_PROGRESS = 'UN_STAKE_REPORT_FETCH_IN_PROGRESS';
export const UN_STAKE_REPORT_FETCH_SUCCESS = 'UN_STAKE_REPORT_FETCH_SUCCESS';
export const UN_STAKE_REPORT_FETCH_ERROR = 'UN_STAKE_REPORT_FETCH_ERROR';

export const FILE_DOWNLOAD_IN_PROGRESS = 'FILE_DOWNLOAD_IN_PROGRESS';
export const FILE_DOWNLOAD_SUCCESS = 'FILE_DOWNLOAD_SUCCESS';
export const FILE_DOWNLOAD_ERROR = 'FILE_DOWNLOAD_ERROR';
