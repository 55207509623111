import React, { useState, useEffect } from 'react';
// import { useLocation } from 'react-router-dom';
import { withRouter } from 'react-router';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import TextField from '../../../components/TextField';
import { updateMe, updateProfilePic } from '../../../actions/me';
import { fetchProfileImage } from '../../../actions/navigationBar';
import variables from '../../../utils/variables';
import { Button } from '@material-ui/core';
import Icon from '../../../components/Icon';
import Snackbar from '../../../components/Snackbar';
import './index.css';

const Edit = (props) => {
    const [name, setName] = useState('');
    const [bio, setBio] = useState('');
    const [profilePicture, setProfilePicture] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarType, setSnackbarType] = useState('success');
    const [snackbarMessage, setSnackbarMessage] = useState('');
    // const [selectedFile, setSelectedFile] = useState();
    // const [isFilePicked, setIsFilePicked] = useState(false);
    const hiddenFileInput = React.useRef(null);

    const {
        update,
        lang,
        history,
        inProgress,
        upload,
        profileImage,
        profileDetails,
        fetchProfilePic,
    } = props;

    useEffect(() => {
        if (!isEmpty(profileImage)) {
            setProfilePicture(profileImage.url);
        }
    }, [profileImage]);

    useEffect(() => {
        if (!isEmpty(profileDetails)) {
            if (profileDetails.name) {
                setName(profileDetails.name);
            }

            if (profileDetails.bio) {
                setBio(profileDetails.bio);
            }
        }
    }, [profileDetails]);

    const onSuccess = () => {
        setSnackbarType('success');
        setSnackbarMessage(variables[lang]['success_me_update']);
        setSnackbarOpen(true);
    };

    const onError = (message) => {
        setSnackbarType('error');
        setSnackbarMessage(message);
        setSnackbarOpen(true);
    };

    const handleClick = () => {
        const data = {};

        if (name && name !== profileDetails.name) {
            data.name = name;
        }

        if (bio && bio !== profileDetails.bio) {
            data.bio = bio;
        }

        update(data, (error) => {
            if (!error && !inProgress) {
                onSuccess();
                history.goBack();
            } else if (error) {
                onError(`${error}`);
            }
        });
    };

    const handleUpload = () => {
        hiddenFileInput.current.click();
    };

    const changeHandler = (event) => {
        const file = new FormData();
        file.append('file', event.target.files[0]);
        upload(file, async (error) => {
            if (!error && !inProgress) {
                await onSuccess();
                fetchProfilePic();
            } else if (error) {
                onError(`${error}`);
            }
        });
    };

    const snackbarOnClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <div className="edit-profile p-16">
            <div className="edit-profile__form">
                <div className="edit-profile__photo flex flex-col justify-center items-center mb-5">
                    <div className="edit-profile__photobox mb-3">
                        <img alt="Profile Photo" src={profilePicture} />
                    </div>
                    <input
                        ref={hiddenFileInput}
                        name="profile-photo"
                        style={{ display: 'none' }}
                        type="file"
                        onChange={changeHandler}
                    />
                    <Button
                        className="upload-btn"
                        onClick={handleUpload}
                    >
                        <Icon
                            className="camera mr-2"
                            icon="camera"
                        />
                        {variables[props.lang].upload_photo}
                    </Button>
                </div>
                <label className="text-custom-gray text-sm mt-5 block font-semibold">{variables[props.lang].enter_name}</label>
                <TextField
                    className="rounded-md bg-custom-semi-gray"
                    id="full_name"
                    name="fullName"
                    placeholder={variables[props.lang].enter_name}
                    value={name}
                    onChange={setName}
                />

                <label className="text-custom-gray text-sm mt-5 block font-semibold">{variables[props.lang].bio}</label>
                <TextField
                    className="rounded-md bg-custom-semi-gray"
                    id="bio"
                    name="bio"
                    placeholder={variables[props.lang].short_desc}
                    value={bio}
                    onChange={setBio}
                />

                {/* <label className="text-custom-gray text-sm mt-5 block font-semibold">{variables[props.lang].interest}</label>
                <TextField
                    className="rounded-md bg-custom-semi-gray"
                    id="full_name"
                    name="fullName"
                    placeholder={variables[props.lang].short_desc}
                    value={props.value}
                /> */}
                {/* <label className="text-custom-gray text-sm mt-5 block font-semibold">{variables[props.lang].twitter}</label>
                <TextField
                    className="rounded-md bg-custom-semi-gray"
                    id="twitter"
                    name="twitter"
                    placeholder={variables[props.lang].twitter_url}
                    value={twitter}
                    onChange={setTwitter}
                /> */}
                {/* <label className="text-custom-gray text-sm mt-5 block font-semibold">{variables[props.lang].medium}</label>
                <TextField
                    className="rounded-md bg-custom-semi-gray"
                    id="full_name"
                    name="fullName"
                    placeholder={variables[props.lang].medium_url}
                    value={props.value}
                /> */}
                <div className="flex items-center justify-evenly mt-5">
                    <Button
                        className="btn__cancel"
                        onClick={history.goBack}
                    >
                        {variables[props.lang].cancel}
                    </Button>
                    <Button
                        className="btn__save"
                        onClick={handleClick}
                    >
                        {variables[props.lang].save}
                    </Button>
                </div>
            </div>
            <Snackbar
                message={snackbarMessage}
                open={snackbarOpen}
                severity={snackbarType}
                onClose={snackbarOnClose}
            />
        </div>
    );
};

Edit.propTypes = {
    history: PropTypes.shape({
        goBack: PropTypes.func.isRequired,
    }).isRequired,
    inProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    profileDetails: PropTypes.object.isRequired,
    profileImage: PropTypes.object.isRequired,
    update: PropTypes.func.isRequired,
    upload: PropTypes.func.isRequired,
    fetchProfilePic: PropTypes.func,
};

const stateToProps = (state) => {
    return {
        inProgress: state.me.updateMe.inProgress,
        lang: state.language,
        profileDetails: state.navigationBar.profileDetails.value,
        profileImage: state.navigationBar.profileImage.value,
    };
};

const actionToProps = {
    fetchProfilePic: fetchProfileImage,
    update: updateMe,
    upload: updateProfilePic,
};

export default withRouter(connect(stateToProps, actionToProps)(Edit));
