import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import TextField from '../../components/TextField';
import variables from '../../utils/variables';
import { setConfirmPassword } from '../../actions/signIn';

const ConfirmPasswordTextField = (props) => {
    return (
        <>
            <label
                className="text-white font-oswald-text text-lg mb-1 font-normal block">{variables[props.lang].confirm_password_label}</label>
            <TextField
                className="rounded-md bg-custom-dark-2 w-full"
                error={props.value !== '' && props.value !== props.password}
                errorText={props.value !== '' && props.value !== props.password ? 'Passwords Needs to be Same!' : ''}
                id="confirm_password_text_field"
                name="confirmPassword"
                placeholder={variables[props.lang]['confirm_password']}
                type="password"
                value={props.value}
                onChange={props.onChange}/>
        </>
    );
};

ConfirmPasswordTextField.propTypes = {
    lang: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        password: state.signIn.password,
        value: state.signIn.confirmPassword,
    };
};

const actionsToProps = {
    onChange: setConfirmPassword,
};

export default connect(stateToProps, actionsToProps)(ConfirmPasswordTextField);
