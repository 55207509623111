import React, {
    useContext,
    useEffect,
    useState,
} from 'react';
import * as PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import { IconButton } from '@material-ui/core';
import Icon from '../../components/Icon';
import { getRecievedMessages } from '../../actions/message';
import { fetchProfileDetails } from '../../actions/navigationBar';
import { SocketContext } from '../../context/socket';
import InboxModal from './InboxModal';
import './index.scss';

const Inbox = (props) => {
    const socket = useContext(SocketContext);
    const [open, setOpen] = useState(false);
    const [messages, setMessages] = useState([]);
    const [messageCount, setMessageCount] = useState(0);

    const {
        userData,
        getRecievedMessages,
        recievedMessages,
        fetchProfileDetails,
    } = props;

    useEffect(() => {
        fetchProfileDetails();
    }, []);

    useEffect(() => {
        const fetch = async () => {
            await getRecievedMessages(userData?._id);
        };
        if (Object.keys(userData).length > 0) {
            fetch();
        }

        socket.on('new-message', async (result) => {
            if (userData._id) {
                await getRecievedMessages(userData._id);
            }
        });
    }, [userData]);

    useEffect(() => {
        if (Object.keys(recievedMessages).length > 0) {
            setMessages(recievedMessages?.result);
        }
    }, [recievedMessages]);

    useEffect(() => {
        if (!isEmpty(messages)) {
            countMessages();
        }
    }, [messages]);

    const handleClick = () => {
        setOpen(!open);
    };

    const countMessages = () => {
        let messageQty = 0;
        messages.forEach((message) => {
            if (!message.is_read) {
                messageQty++;
            }
        });

        setMessageCount(messageQty);
    };

    return (
        <>
            <IconButton className="inbox" onClick={handleClick}>
                <Icon
                    className="inbox"
                    icon="inbox"/>
                <span className="circle">
                    {
                        messageCount > 0 && <span>{messageCount}</span>
                    }
                </span>
            </IconButton>
            <InboxModal
                close={handleClick}
                getRecievedMessages={getRecievedMessages}
                messages={messages}
                open={open}
                userId={userData._id}
            />
        </>
    );
};

Inbox.propTypes = {
    fetchProfileDetails: PropTypes.func.isRequired,
    getRecievedMessages: PropTypes.func,
    recievedMessages: PropTypes.any,
    userData: PropTypes.object,
};

const stateToProps = (state) => {
    const { value } = state.navigationBar.profileDetails;
    const { recievedMessages } = state.messaging.recievedMessages;
    return {
        userData: value,
        recievedMessages,
    };
};

const actionToProps = {
    fetchProfileDetails,
    getRecievedMessages,
};

export default connect(stateToProps, actionToProps)(Inbox);
