import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { Editor, EditorState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import { fetchComments, getForumByID } from '../../actions/forum';
import InputField from '../../components/Global/InputField';
import Snackbar from '../../components/Snackbar';
import Modal from '../../components/Global/Modal';

import CommentRow from '../../components/Forum/CommentRow';
import Breadcrumbs from '../../components/Forum/Breadcrumbs';

const ForumDetail = (props) => {
    const {
        comments,
        page,
        totalPage,
        userData,
        fetchComments,
        success,
        getForumByID,
        forum,
        match: {
            params: {
                id,
            },
        },
    } = props;
    const [showComment, setShowComment] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState('');
    // const [formstate, setFormState] = useState({});

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (success) {
            await fetchComments(id.toString(), page);
        }
    };

    const handleDelete = async (res) => {
        if (res) {
            await fetchComments(id.toString(), page);
            setSnackbarMessage('Comment Deleted');
            setSnackbarOpen(true);
            setSnackbarSeverity('success');
        }
    };

    useEffect(() => {
        const fetch = async () => {
            await fetchComments(id.toString(), page);
            await getForumByID(id.toString());
        };
        const addOns = {};
        fetch();
        if (id) { addOns.forum = id; }
    }, []);

    const handleForm = (name, value) => {
    };
    let commentContent;
    if (!success) {
        commentContent = <span>Loading...</span>;
    } else if (comments.length !== 0) {
        commentContent = comments.map((comment) => (
            <CommentRow
                key={comment._id}
                comment={comment.content}
                commentDate={comment.createdAt}
                commenter={comment.owner.email_address}
                handleDelete={handleDelete}
                id={comment._id}
            />
        ));
    } else {
        commentContent = <span>No Data Found</span>;
    }

    const handlePageClick = async (event) => {
        const selectedPage = event.selected + 1;
        await fetchComments(id.toString(), selectedPage);
        // setPageNo(selectedPage);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <>
            <Snackbar
                message={snackbarMessage}
                open={snackbarOpen}
                severity={snackbarSeverity}
                onClose={handleSnackbarClose}
            />
            <div className="w-4/5 mx-auto pt-10 pb-10">
                <Breadcrumbs forumId={id} />
                <div className="comment mb-4 rounded-xl border border-custom-white flex flex-col w-full py-3 md:py-4 px-8 text-white font-oswald-text tracking-wider">
                    {forum && (
                        <>
                            <div>
                                <h1 className="heading_text text-3xl md:text-2xl font-semibold font-oswald-text mb-4 text-white">{forum.title}</h1>
                            </div>
                            <div className="md:max-h-auto text-slate-500 text-lg w-full">
                                <Editor
                                    readOnly
                                    editorState={EditorState.createWithContent(
                                        stateFromHTML(forum.content),
                                    )} />
                            </div>
                        </>
                    )}
                </div>
                <div className="comment mb-4 rounded-xl border border-custom-white text-white font-oswald-text flex flex-col w-full">
                    <div className="flex py-3 md:py-4 px-8 text-white uppercase text-sm lg:text-lg font-bold rounded-t-xl">
                        <span>Comment</span>
                    </div>
                    <div className="comment__container px-6 md:px-10 py-4 md:py-8 text-lg">
                        {commentContent}
                    </div>
                    <div className="comment__footer px-10 py-8">
                        {Object.keys(userData).length > 0 && (
                            <Modal
                                isOpen={showComment}
                                modalTitle="Comment"
                                setIsOpen={setShowComment}
                            >
                                <form onSubmit={handleSubmit}>
                                    <InputField
                                        fieldClass="h-80"
                                        fieldName="content"
                                        handleForm={handleForm}
                                        label="Comment"
                                        type="textarea" />
                                    <div className="flex justify-end">
                                        <button className="btn--primary btn--small border border-custom-dark-mode-red bg-custom-dark-mode-red text-white rounded-md px-6 py-2" type="submit">
                                            <span className="text-black">Submit</span>
                                        </button>
                                    </div>
                                </form>
                            </Modal>
                        )}
                    </div>
                </div>

                {totalPage > 1 && (
                    <ReactPaginate
                        activeClassName="text-fuchsia-700 border-fuchsia-700"
                        breakClassName="page-item p-2 md:py-4 md:px-4"
                        breakLabel="..."
                        breakLinkClassName="page-link text-sm md:text-base"
                        containerClassName="pagination flex mt-4 justify-center gap-2 md:gap-3"
                        forcePage={page - 1}
                        marginPagesDisplayed={2}
                        nextClassName="page-item border rounded-xl w-14 text-center"
                        nextLabel="»"
                        nextLinkClassName="page-link text-sm md:text-base p-2 md:py-4 md:px-4 block"
                        pageClassName="page-item border rounded-xl w-14 text-center"
                        pageCount={totalPage}
                        pageLinkClassName="page-link text-sm md:text-base p-2 md:py-4 md:px-4 block"
                        pageRangeDisplayed={0}
                        previousClassName="page-item border rounded-xl w-14 text-center"
                        previousLabel="«"
                        previousLinkClassName="page-link text-sm md:text-base p-2 md:py-4 md:px-4 block"
                        onPageChange={handlePageClick} />
                )}
            </div></>
    );
};

ForumDetail.propTypes = {
    comments: PropTypes.array,
    commentsIsValidating: PropTypes.bool,
    createComment: PropTypes.func,
    fetchComments: PropTypes.func,
    forum: PropTypes.object,
    getForumByID: PropTypes.func,
    id: PropTypes.string,
    isCreated: PropTypes.string,
    match: PropTypes.shape({
        params: PropTypes.object.isRequired,
    }),
    page: PropTypes.number,
    success: PropTypes.bool,
    totalPage: PropTypes.number,
    userData: PropTypes.object,
};

const stateToProps = (state) => {
    const { value } = state.navigationBar.profileDetails;
    const { message } = state.forums.createForum;
    const { comments } = state.forums.commentsList;
    const { thread } = state.forums.forum;
    return {
        comments: comments.comments,
        userData: value,
        isCreated: message,
        totalPage: comments.totalPage,
        page: comments.page,
        success: comments.success,
        forum: thread.forum,
    };
};

const actionToProps = {
    // createComment,
    fetchComments,
    getForumByID,
};

export default connect(stateToProps, actionToProps)(ForumDetail);
