import Axios from 'axios';
import { PROFILE_DETAILS_FETCH, PROFILE_IMAGE_FETCH, USER_SIGN_OUT_URL } from '../constants/url';
import {
    HIDE_SIDE_BAR_SET,
    PROFILE_DETAILS_FETCH_ERROR,
    PROFILE_DETAILS_FETCH_IN_PROGRESS,
    PROFILE_DETAILS_FETCH_SUCCESS,
    PROFILE_IMAGE_FETCH_ERROR,
    PROFILE_IMAGE_FETCH_IN_PROGRESS,
    PROFILE_IMAGE_FETCH_SUCCESS,
    SHOW_SIDE_BAR_SET,
    SIGN_OUT_ERROR,
    SIGN_OUT_IN_PROGRESS,
    SIGN_OUT_SUCCESS,
} from '../constants/navigationBar';

const fetchProfileDetailsInProgress = () => {
    return {
        type: PROFILE_DETAILS_FETCH_IN_PROGRESS,
    };
};

const fetchProfileDetailsSuccess = (value) => {
    return {
        type: PROFILE_DETAILS_FETCH_SUCCESS,
        value,
    };
};

const fetchProfileDetailsError = (message) => {
    return {
        type: PROFILE_DETAILS_FETCH_ERROR,
        message,
    };
};

export const fetchProfileDetails = (cb) => (dispatch) => {
    dispatch(fetchProfileDetailsInProgress());

    Axios.get(PROFILE_DETAILS_FETCH, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
    })
        .then((res) => {
            dispatch(fetchProfileDetailsSuccess(res.data.result));
            if (cb) {
                cb(res.data.result);
            }
        })
        .catch((error) => {
            dispatch(fetchProfileDetailsError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            if (cb) {
                cb(null);
            }
        });
};

const fetchProfileImageInProgress = () => {
    return {
        type: PROFILE_IMAGE_FETCH_IN_PROGRESS,
    };
};

const fetchProfileImageSuccess = (value) => {
    return {
        type: PROFILE_IMAGE_FETCH_SUCCESS,
        value,
    };
};

const fetchProfileImageError = (message) => {
    return {
        type: PROFILE_IMAGE_FETCH_ERROR,
        message,
    };
};

export const fetchProfileImage = () => (dispatch) => {
    dispatch(fetchProfileImageInProgress());

    Axios.get(PROFILE_IMAGE_FETCH, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
    })
        .then((res) => {
            const imageUrl = { url: res.data.result };
            dispatch(fetchProfileImageSuccess(imageUrl));
        })
        .catch((error) => {
            dispatch(fetchProfileImageError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const signOutInProgress = () => {
    return {
        type: SIGN_OUT_IN_PROGRESS,
    };
};

const signOutSuccess = (message) => {
    return {
        type: SIGN_OUT_SUCCESS,
        message,
    };
};

const signOutError = (message) => {
    return {
        type: SIGN_OUT_ERROR,
        message,
    };
};

export const signOut = (cb) => (dispatch) => {
    dispatch(signOutInProgress());

    const data = {
        refreshToken: localStorage.getItem('refreshToken'),
    };

    Axios.post(USER_SIGN_OUT_URL, data, {
        headers: {
            Accept: 'application/json',
        },
    })
        .then((res) => {
            dispatch(signOutSuccess('Successfully signout'));
            cb(null);
        })
        .catch((error) => {
            dispatch(signOutError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            cb(error);
        });
};

export const showSideBar = () => {
    return {
        type: SHOW_SIDE_BAR_SET,
    };
};

export const hideSideBar = () => {
    return {
        type: HIDE_SIDE_BAR_SET,
    };
};
