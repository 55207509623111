import React from 'react';
import '../SignIn/index.css';
import './index.css';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AuthenticationCodeTextField from '../Settings/SecurityTab/Dialog/AuthenticationCodeTextField';
import { twoFactorAuthVerifyBackupToken, verifyTwoFactorAuth } from '../../actions/settings';
import VerifyButton from './VerifyButton';
import variables from '../../utils/variables';
import FormInfo from '../../components/FormInfo';

const VerifyAuthentication = (props) => {
    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && !disable) {
            handleVerify();
        }
    };

    const handleVerify = () => {
        if (props.token.length === 6) {
            props.verify({
                token: props.token,
                ticket: localStorage.getItem('ticket'),
            }, (error) => {
                if (error) {
                    return;
                }

                props.history.push('/dashboard');
            });
        } else {
            props.twoFactorAuthVerifyBackupToken({
                token: props.token,
                ticket: localStorage.getItem('ticket'),
            }, (error) => {
                if (error) {
                    return;
                }

                props.history.push('/dashboard');
            });
        }
    };

    const disable = !props.token || props.token.length < 6;

    return (
        <div className="w-full flex items-center lg:items-start bg-custom-dark">
            <div className=" flex flex-col-reverse lg:flex-row-reverse bg-custom-dark h-screen lg:h-auto items-center w-full">
                <div className="flex items-center justify-center lg:w-1/2 w-full bg-custom-dark md:mb-10 md:mb-auto h-screen items-center md:items">
                    <form
                        noValidate
                        autoComplete="off"
                        className="card w-4/5 md:w-3/5 text-white"
                        onKeyPress={handleKeyPress}
                        onSubmit={(e) => {
                            e.preventDefault();
                        }}>
                        <h3 className="heading font-oswald-text font-semibold text-3xl mb-3">
                            {variables[props.lang].verification}
                        </h3>
                        <p className="text-base mb-5 font-semibold opacity-60 mb-12 block">
                            {variables[props.lang].verification_text}
                        </p>
                        <AuthenticationCodeTextField backUp={true}/>
                        <VerifyButton
                            disable={disable}
                            lang={props.lang}
                            onClick={handleVerify}/>
                    </form>
                </div>
                <FormInfo/>
            </div>
        </div>
    );
};

VerifyAuthentication.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    lang: PropTypes.string.isRequired,
    twoFactorAuthVerifyBackupToken: PropTypes.func.isRequired,
    verify: PropTypes.func.isRequired,
    token: PropTypes.string,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        verification: state.settings.verifyTwoFactorAuth,
        token: state.settings.authCode,
    };
};

const actionToProps = {
    verify: verifyTwoFactorAuth,
    twoFactorAuthVerifyBackupToken,
};

export default connect(stateToProps, actionToProps)(VerifyAuthentication);
