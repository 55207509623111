import React, { Component } from 'react';
import '../ForgotPassword/index.css';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import variables from '../../utils/variables';
import PasswordTextField from '../SignIn/PasswordTextField';
import ConfirmPasswordTextField from './ConfirmPasswordTextField';
import RecoveryButton from './RecoveryButton';
import { withRouter } from 'react-router';
import { recoveryPassword } from '../../actions/forgotPassword';
import { encryptToken } from '../../utils/crypto';
import FormInfo from '../../components/FormInfo';

class RecoveryPassword extends Component {
    constructor (props) {
        super(props);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleRecover = this.handleRecover.bind(this);

        this.state = {
            id: '',
            code: '',
        };
    }

    componentDidMount () {
        this.setState({
            id: this.props.match.params.id,
            code: this.props.match.params.code,
        });
    }

    handleKeyPress (event) {
        const disable = this.props.password === '' || this.props.confirmPassword === '' ||
            this.props.password !== this.props.confirmPassword || this.props.password.length < 8 || this.props.inProgress;

        if (event.key === 'Enter' && !disable) {
            this.handleRecover();
        }
    }

    handleRecover () {
        const data = {
            recoveryId: this.state.id,
            recoveryCode: this.state.code,
            password: encryptToken(this.props.password),
        };

        this.props.recoveryPassword(data, (error) => {
            if (!error) {
                this.props.history.push('/login');
            }
        });
    }

    render () {
        const disable = this.props.password === '' || this.props.confirmPassword === '' ||
            this.props.password !== this.props.confirmPassword || this.props.password.length < 8 || this.props.inProgress;

        return (
            <div className="w-full flex items-center lg:items-start bg-custom-dark">
                <div className="flex flex-col-reverse lg:flex-row-reverse bg-custom-dark h-screen lg:h-auto items-center w-full">
                    <div className="flex items-center justify-center lg:w-1/2 w-full bg-custom-dark md:mb-10 md:mb-auto h-screen items-center md:items">
                        <form
                            noValidate
                            autoComplete="off"
                            className="card w-4/5 md:w-3/5 text-white"
                            onKeyPress={this.handleKeyPress}
                            onSubmit={(e) => {
                                e.preventDefault();
                            }}>
                            <h2 className="heading font-oswald-text font-semibold text-3xl mb-3">
                                {variables[this.props.lang]['recovery_password']}
                            </h2>
                            <PasswordTextField/>
                            <ConfirmPasswordTextField/>
                            <RecoveryButton disable={disable} lang={this.props.lang} onClick={this.handleRecover}/>
                        </form>
                    </div>
                    <FormInfo />
                </div>
            </div>
        );
    }
}

RecoveryPassword.propTypes = {
    confirmPassword: PropTypes.string.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    inProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    match: PropTypes.shape({
        params: PropTypes.object.isRequired,
    }).isRequired,
    password: PropTypes.string.isRequired,
    recoveryPassword: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        inProgress: state.forgotPassword.recoveryPasswordInProgress,
        confirmPassword: state.signIn.confirmPassword,
        password: state.signIn.password,
    };
};

const actionToProps = {
    recoveryPassword,
};

export default withRouter(connect(stateToProps, actionToProps)(RecoveryPassword));
