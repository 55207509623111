import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchContributor, getContributorAccount } from '../../actions/deals';
import CircularProgress from '../../components/CircularProgress';
import CopyButton from '../../components/CopyButton';
import Icon from '../../components/Icon';
import './index.css';

class ContributorDetails extends Component {
    constructor (props) {
        super(props);

        this.goBack = this.goBack.bind(this);
        this.handleFetchAccount = this.handleFetchAccount.bind(this);
    }

    componentDidMount () {
        const {
            match: {
                params: {
                    id,
                    contributorId,
                },
            },
            fetch,
        } = this.props;

        fetch(id, contributorId, (error, result) => {
            if (!error) {
                const { _id } = result;
                this.handleFetchAccount(_id);
            }
        });
    }

    handleFetchAccount (id) {
        const { fetchAccount } = this.props;
        if (id) {
            fetchAccount(id);
        }
    }

    goBack () {
        const { history } = this.props;

        history.goBack();
    }

    render () {
        const {
            inProgress,
            data,
            account,
            dealData,
        } = this.props;

        return (
            <div className="contributor_details content_padding">
                <Button className="flex items-center" onClick={this.goBack}>
                    <Icon
                        className="dark-back-arrow mr-2"
                        icon="dark-back-arrow"/>
                    <span className="font-oswald-text font-bold text-white">Back</span>
                </Button>
                {inProgress
                    ? <CircularProgress/>
                    : <div className="mt-6">
                        <div className="bg-custom-dark text-white rounded-lg shadow-md p-4">
                            <div className="contributor_details__header w-full mx-auto py-4 md:py-8 px-4 md:px-10 border-b">
                                <h1 className="font-oswald-text font-bold text-2xl md:text-3xl">Contributor Details</h1>
                            </div>
                            <div className="w-full pt-6 md:pt-14 pb-8 px-4 md:px-10 mb-8">
                                <div className="md:flex mb-8 items-top md:items-center">
                                    <p className="font-oswald-text text-sm md:text-base font-bold w-full md:w-1/6 text-custom-light-gray">Deal Name</p>
                                    <p className="font-oswald-text text-sm md:text-base font-bold w-full md:w-5/6 text-custom-light-gray">
                                        <Link replace={true} to={`/deals/${dealData._id}`}>{dealData.name}</Link>
                                    </p>
                                </div>
                                {
                                    data.user ? (
                                        <>
                                            <div className="flex mb-8 items-top md:items-center">
                                                <p className="font-oswald-text text-sm md:text-base font-bold w-full md:w-1/6 text-custom-light-gray">Tier Group</p>
                                                <p className="font-oswald-text text-sm md:text-base font-bold w-full md:w-5/6">{data.user.top_tier}</p>
                                            </div>
                                            <div className="flex mb-8 items-top md:items-center">
                                                <p className="font-oswald-text text-sm md:text-base font-bold w-full md:w-1/6 text-custom-light-gray">TG Username</p>
                                                <p className="font-oswald-text text-sm md:text-base font-bold w-full md:w-5/6">@{data.user.telegram.username}</p>
                                            </div>
                                        </>
                                    ) : ''
                                }
                                <div className="md:flex mb-8 items-top md:items-center">
                                    <p className="font-oswald-text text-sm md:text-base font-bold w-full md:w-1/6 text-custom-light-gray">Wallet Address</p>
                                    <p className="font-oswald-text text-sm md:text-base font-bold w-full md:w-5/6 break-words">{account.address}</p>
                                </div>
                                <div className="flex mb-8 items-top md:items-center">
                                    <p className="font-oswald-text text-sm md:text-base font-bold w-full md:w-1/6 text-custom-light-gray">Contribution</p>
                                    <p className="font-oswald-text text-sm md:text-base font-bold w-full md:w-5/6">{data.contribution && data.contribution.toLocaleString('en-US')}</p>
                                </div>
                                <div className="md:flex mb-8 items-top md:items-center">
                                    <p className="font-oswald-text text-sm md:text-base font-bold w-full md:w-1/6 text-custom-light-gray">Etherscan URL</p>
                                    <div className="icon_div icon_div font-oswald-text text-sm md:text-base font-bold w-full md:w-5/6 items-center">
                                        <a
                                            className="id_text font-oswald-text text-sm md:text-base break-all text-custom-link-blue"
                                            href={data.etherscan}
                                            rel="noopener noreferrer"
                                            target="_blank"
                                            title={data.etherscan}>
                                            {data.etherscan}
                                        </a>
                                        <CopyButton data={data.etherscan}/>
                                    </div>
                                </div>
                                <div className="flex mb-8 items-top md:items-center">
                                    <p className="font-oswald-text text-sm md:text-base font-bold w-full md:w-1/6 text-custom-light-gray">Status</p>
                                    <p className="font-oswald-text text-sm md:text-base font-bold w-full md:w-5/6">{data.status}</p>
                                </div>
                                <div className="md:flex mb-8 items-top md:items-center">
                                    <p className="font-oswald-text text-sm md:text-base font-bold w-full md:w-1/6 text-custom-light-gray">Notes</p>
                                    <pre className="font-oswald-text text-sm md:text-base font-bold w-full md:w-5/6">{data.notes}</pre>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

ContributorDetails.propTypes = {
    account: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    dealData: PropTypes.object.isRequired,
    fetch: PropTypes.func.isRequired,
    fetchAccount: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    match: PropTypes.shape({
        params: PropTypes.object.isRequired,
    }).isRequired,
    history: PropTypes.shape({
        goBack: PropTypes.func.isRequired,
    }),
};

const stateToProps = (state) => {
    return {
        account: state.deals.account.result,
        inProgress: state.deals.singleContributor.inProgress,
        data: state.deals.singleContributor.result,
        dealData: state.deals.singleDeal.result,
    };
};

const actionToProps = {
    fetch: fetchContributor,
    fetchAccount: getContributorAccount,
};

export default withRouter(connect(stateToProps, actionToProps)(ContributorDetails));
