import Axios from 'axios';
import { RECOVERY_EMAIL_URL, RECOVERY_PASSWORD_URL } from '../constants/url';
import {
    RECOVERY_EMAIL_ADD_ERROR,
    RECOVERY_EMAIL_ADD_IN_PROGRESS,
    RECOVERY_EMAIL_ADD_SUCCESS,
    RECOVERY_PASSWORD_ERROR,
    RECOVERY_PASSWORD_IN_PROGRESS,
    RECOVERY_PASSWORD_SUCCESS,
} from '../constants/forgotPassword';

const addRecoveryEmailInProgress = () => {
    return {
        type: RECOVERY_EMAIL_ADD_IN_PROGRESS,
    };
};

const addRecoveryEmailSuccess = (message) => {
    return {
        type: RECOVERY_EMAIL_ADD_SUCCESS,
        message,
    };
};

const addRecoveryEmailError = (message) => {
    return {
        type: RECOVERY_EMAIL_ADD_ERROR,
        message,
    };
};

export const addRecoveryEmail = (data, cb) => (dispatch) => {
    dispatch(addRecoveryEmailInProgress());

    Axios.post(RECOVERY_EMAIL_URL, data, {
        headers: {
            Accept: 'application/json',
        },
    })
        .then((res) => {
            dispatch(addRecoveryEmailSuccess('Success!'));
            cb(null);
        })
        .catch((error) => {
            dispatch(addRecoveryEmailError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            cb(error);
        });
};

const recoveryPasswordInProgress = () => {
    return {
        type: RECOVERY_PASSWORD_IN_PROGRESS,
    };
};

const recoveryPasswordSuccess = (message) => {
    return {
        type: RECOVERY_PASSWORD_SUCCESS,
        message,
    };
};

const recoveryPasswordError = (message) => {
    return {
        type: RECOVERY_PASSWORD_ERROR,
        message,
    };
};

export const recoveryPassword = (data, cb) => (dispatch) => {
    dispatch(recoveryPasswordInProgress());

    Axios.post(RECOVERY_PASSWORD_URL, data, {
        headers: {
            Accept: 'application/json',
        },
    })
        .then((res) => {
            dispatch(recoveryPasswordSuccess('Success'));
            cb(null);
        })
        .catch((error) => {
            dispatch(recoveryPasswordError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            cb(error);
        });
};
