import { combineReducers } from 'redux';
import {
    FORUM_FETCH_IN_PROGRESS,
    FORUM_FETCH_SUCCESS,
    FORUM_FETCH_ERROR,
    COMMENTS_FETCH_IN_PROGRESS,
    COMMENTS_FETCH_SUCCESS,
    COMMENTS_FETCH_ERROR,
    FORUM_GET_IN_PROGRESS,
    FORUM_GET_SUCCESS,
    FORUM_GET_ERROR,
    FORUM_CREATE_IN_PROGRESS,
    FORUM_CREATE_SUCCESS,
    FORUM_CREATE_ERROR,
    COMMENT_CREATE_IN_PROGRESS,
    COMMENT_CREATE_SUCCESS,
    COMMENT_CREATE_ERROR,
    FORUM_DELETE_IN_PROGRESS,
    FORUM_DELETE_SUCCESS,
    FORUM_DELETE_ERROR,
    COMMENT_DELETE_IN_PROGRESS,
    COMMENT_DELETE_SUCCESS,
    COMMENT_DELETE_ERROR,
    USER_IMAGE_FETCH_IN_PROGRESS,
    USER_IMAGE_FETCH_SUCCESS,
    USER_IMAGE_FETCH_ERROR,
} from '../constants/forum';

const forumList = (
    state = {
        inProgress: false,
        forums: [],
    },
    action,
) => {
    switch (action.type) {
    case FORUM_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FORUM_FETCH_SUCCESS:
        return {
            inProgress: false,
            forums: action.value,
        };
    case FORUM_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };

    default:
        return state;
    }
};

const createForum = (
    state = {
        inProgress: false,
        success: false,
    },
    action,
) => {
    switch (action.type) {
    case FORUM_CREATE_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FORUM_CREATE_SUCCESS:
        return {
            inProgress: false,
            success: action.message,
        };
    case FORUM_CREATE_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const forum = (
    state = {
        inProgress: false,
        thread: [],
    },
    action,
) => {
    switch (action.type) {
    case FORUM_GET_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FORUM_GET_SUCCESS:
        return {
            inProgress: false,
            thread: action.value,
        };
    case FORUM_GET_ERROR:
        return {
            ...state,
            inProgress: false,
        };

    default:
        return state;
    }
};

const commentsList = (
    state = {
        inProgress: false,
        comments: [],
        count: 0,
    },
    action,
) => {
    switch (action.type) {
    case COMMENTS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case COMMENTS_FETCH_SUCCESS:
        return {
            inProgress: false,
            comments: action.value,
        };
    case COMMENTS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };

    default:
        return state;
    }
};

const createComment = (
    state = {
        inProgress: false,
        comment: [],
    },
    action,
) => {
    switch (action.type) {
    case COMMENT_CREATE_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case COMMENT_CREATE_SUCCESS:
        return {
            inProgress: false,
            comment: action.value,
        };
    case COMMENT_CREATE_ERROR:
        return {
            ...state,
            inProgress: false,
        };

    default:
        return state;
    }
};

const deleteForum = (
    state = {
        inProgress: true,
        success: false,
    },
    action,
) => {
    switch (action.type) {
    case FORUM_DELETE_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FORUM_DELETE_SUCCESS:
        return {
            inProgress: false,
            success: action.message,
        };
    case FORUM_DELETE_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const deleteComment = (
    state = {
        inProgress: true,
        success: false,
    },
    action,
) => {
    switch (action.type) {
    case COMMENT_DELETE_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case COMMENT_DELETE_SUCCESS:
        return {
            inProgress: false,
            success: action.message,
        };
    case COMMENT_DELETE_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const userImage = (
    state = {
        inProgress: false,
        image: [],
    },
    action,
) => {
    switch (action.type) {
    case USER_IMAGE_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case USER_IMAGE_FETCH_SUCCESS:
        return {
            inProgress: false,
            image: action.value,
        };
    case USER_IMAGE_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };

    default:
        return state;
    }
};

export default combineReducers({
    forumList,
    commentsList,
    forum,
    createForum,
    createComment,
    deleteForum,
    deleteComment,
    userImage,
});
