import React, { useEffect, useContext } from 'react';
import './index.css';
import Tabs from './Tabs';
import Profile from './Profile';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import ClassNames from 'classnames';
import ExpansionButton from './ExpansionButton';
import logo from '../../assets/images/Exnt_Logo.svg';
import { hideSideBar, fetchProfileImage } from '../../actions/navigationBar';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { withRouter } from 'react-router';
import Notification from '../../components/Notification';
import Inbox from '../../components/Inbox';
import { SocketContext } from '../../context/socket';

const NavigationBar = (props) => {
    const { userData } = props;
    const socket = useContext(SocketContext);
    if (userData?._id) {
        socket.emit('user-connect', userData?._id);
    }
    useEffect(() => {
        props.fetchProfileImage();
    }, []);

    return (
        <div className="nav_bar nav_padding justify-between lg:justify-start">
            <div className="w-full flex items-center justify-start py-0.5">
                <Button
                    className="tab"
                    onClick={() => props.history.push('/dashboard')}>
                    <img alt="logo" className="w-full" src={logo}/>
                </Button>
                {/* <div className="relative block md:hidden pr-12">
                    <Inbox />
                    <Notification />
                </div> */}
                <ExpansionButton/>
                {props.show &&
            <span
                className="layer"
                onClick={props.handleClose}/>}
                <div className={ClassNames('nav_content', props.show ? 'show' : '')}>
                    <Button
                        aria-label="Open drawer"
                        className="hide_button"
                        color="inherit"
                        onClick={props.handleClose}>
                        <NavigateBeforeIcon/>
                    </Button>
                    <Tabs/>
                    <div className="right_content">
                        <Inbox />
                        <Notification />
                        <Profile/>
                    </div>
                </div>
            </div>
            <div className="w-full text-right xl:hidden">
                <Inbox />
                <Notification />
            </div>
        </div>
    );
};

NavigationBar.propTypes = {
    fetchProfileImage: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    show: PropTypes.bool.isRequired,
    userData: PropTypes.object,
};

const stateToProps = (state) => {
    const { value } = state.navigationBar.profileDetails;
    return {
        show: state.navigationBar.show,
        userData: value,
    };
};

const actionToProps = {
    handleClose: hideSideBar,
    fetchProfileImage,
};

export default withRouter(connect(stateToProps, actionToProps)(NavigationBar));
