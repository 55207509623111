import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { Dialog, DialogContent, Button } from '@material-ui/core';
import close from '../../assets/images/close.svg';
import { connect } from 'react-redux';
import { taskSpecifiParticipant } from '../../actions/tasks';
import ManualDialog from './ManualDialog';

const ParticipantsDialog = (props) => {
    const [open, setOpen] = useState(false);

    const handleViewDetail = (pid) => {
        props.taskSpecifiParticipant(props.taskId, pid);
        setOpen(true);
    };

    const handleCloseManual = () => {
        setOpen(false);
    };

    return (
        <Dialog
            aria-describedby="account-type-dialog-description"
            aria-labelledby="account-type-dialog-title"
            className="dialog account_verification"
            open={props.open}
            onClose={props.handleClose}>
            <DialogContent className="tasks-dialog content">
                <div className="flex flex-row justify-between items-center mb-6">
                    <div className="flex flex-col">
                        <span className="font-semibold font-oswald-text text-xl">Participants</span>
                    </div>
                    <img alt="close" className="task-close" src={close} onClick={props.handleClose} />
                </div>
                <div>
                    {
                        (typeof (props.participantItems) !== 'undefined')
                            ? (props.participantItems.length === 0)
                                ? <h2>No Participants</h2>
                                : <>
                                    <ul>
                                        {props.participantItems.map((item, index) =>
                                            (<li key={index} className="flex items-center mb-4 justify-between">
                                                <div className="flex items-center">
                                                    <div className="task-dialog__imagebox">
                                                        <img alt={item.user_id.name} src={item.user_id.profile_image_base64} />
                                                    </div>
                                                    <span className="font-semibold font-oswald-text ml-4">{item.user_id.name}</span>
                                                </div>
                                                {(item.type !== 'FOLLOW' && item.type !== 'RETWEET' && !props.isArchived) &&
                                                    <div className="task-dialog__btn">
                                                        <Button className="view_details_button" onClick={() => handleViewDetail(item.user_id._id)}>View Details</Button>
                                                    </div>
                                                }
                                            </li>),
                                        )}
                                    </ul>
                                </>
                            : <h2>No Participants</h2>
                    }
                </div>
                <ManualDialog handleClose={handleCloseManual} item={props.specificParticipantTask} open={open} taskId={props.taskId} />
            </DialogContent>
        </Dialog>
    );
};

ParticipantsDialog.propTypes = {
    handleClose: PropTypes.func.isRequired,
    isArchived: PropTypes.bool.isRequired,
    open: PropTypes.bool.isRequired,
    participantItems: PropTypes.array.isRequired,
    specificParticipantTask: PropTypes.object.isRequired,
    taskId: PropTypes.any.isRequired,
    taskSpecifiParticipant: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        specificParticipantTask: state.tasks.specificParticipantTask,
    };
};

const actionToProps = {
    taskSpecifiParticipant,
};

export default connect(stateToProps, actionToProps)(ParticipantsDialog);
