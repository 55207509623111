import { combineReducers } from 'redux';
import {
    CONFIG_UPDATE_ERROR,
    CONFIG_UPDATE_IN_PROGRESS,
    CONFIG_UPDATE_SUCCESS,
    FETCH_CONFIG_ERROR,
    FETCH_CONFIG_IN_PROGRESS,
    FETCH_CONFIG_SUCCESS,
} from '../constants/config';

const dealConfig = (state = {
    inProgress: false,
    result: {},
    message: '',
}, action) => {
    switch (action.type) {
    case FETCH_CONFIG_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_CONFIG_SUCCESS:
        return {
            inProgress: false,
            result: action.item,
        };
    case FETCH_CONFIG_ERROR:
        return {
            ...state,
            inProgress: false,
            message: action.message,
        };
    default:
        return state;
    }
};

const updateDealConfig = (state = {
    inProgress: false,
    result: {},
    message: '',
}, action) => {
    switch (action.type) {
    case CONFIG_UPDATE_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case CONFIG_UPDATE_SUCCESS:
        return {
            inProgress: false,
            result: action.item,
        };
    case CONFIG_UPDATE_ERROR:
        return {
            ...state,
            inProgress: false,
            message: action.message,
        };
    default:
        return state;
    }
};

export default combineReducers({
    dealConfig,
    updateDealConfig,
});
