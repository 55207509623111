import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from '@material-ui/core';
import { connect } from 'react-redux';
import { deleteThread } from '../../actions/forum';
import Icon from '../../components/Icon';
import Button from '../Button';
import UserImage from './UserImage';

const ForumItemDetail = (props) => {
    const {
        item,
        deleteThread,
        handleFetch,
    } = props;
    const [name, setName] = useState('');
    const [detailURL, setDetailURL] = useState('');

    useEffect(() => {
        setDetailURL(`/forum/${item._id}`);
        if (item.author.name) {
            setName(item.author.name);
        } else {
            setName(item.author.email_address);
        }
    }, [item._id]);

    const handleClick = async () => {
        await deleteThread(item._id);
        handleFetch();
    };

    return (
        <>
            <div className="accordion__column flex flex-auto flex-col md:flex-row items-center mb-4 md:mb-0 w-full md:w-4/6">
                <div className="px-4 hidden md:flex w-14  items-center justify-center">
                    <Icon className="forums text-white" icon="forums"/>
                </div>
                <div className="flex flex-col md:pl-4 w-full md:w-10/12 md:max-h-28 overflow-hidden">
                    <Link href={detailURL}><h3 className="text-2xl text-white font-bold mb-4 md:mb-0">{item.title}</h3></Link>
                    <div className="inline-block text-white font-semibold max-w-full truncate">
                        <span className="opacity-60">by&nbsp;</span><span className="text-custom-dark-mode-red opacity-100">{name}</span>
                    </div>
                </div>
            </div>
            <div className="accordion__column flex flex-row flex-none w-full md:w-1/3 md:items-center md:justify-center mb-4 md:mb-0">
                <span className="block md:hidden font-bold text-slate-500 text-lg mr-2 md:mr-auto">Comments: </span><span className="text-slate-500 text-lg font-bold">{` ${item.commentCount} `}</span>
            </div>
            <div className="accordion__column flex flex-row flex-none w-full md:w-1/6 items-center mb-4 md:mb-0">
                <div className="flex flex-col md:flex-row md:justify-center md:items-center">
                    {item?.author && (<UserImage author={ item?.author?._id } />)}
                    <div className="flex flex-col w-full">
                        <span className="flex text-base w-full">
                            <span className="inline-block text-fuchsia-400 font-medium max-w-full truncate">
                                {name}
                            </span>
                        </span>
                        <span className="text-sm text-white opacity-60 font-light">
                            {`${moment(item.created_at).startOf('second').fromNow()}, ${moment(item.created_at).format('hh:mm a')}`}
                        </span>
                    </div>
                </div>
            </div>
            <div className="flex items-start justify-start accordion__column flex flex-none w-auto md:w-1/6 md:justify-center md:items-center">
                <Button
                    className="font-oswald-text"
                    color="primary"
                    size="sm"
                    onClick={handleClick}
                >
                    Delete
                </Button>
            </div>
        </>
    );
};

ForumItemDetail.propTypes = {
    deleteForum: PropTypes.object,
    deleteThread: PropTypes.func,
    fetchComments: PropTypes.func,
    handleDelete: PropTypes.func,
    handleFetch: PropTypes.func,
    handleSnackbarClose: PropTypes.func,
    item: PropTypes.object,
};

const stateToProps = (state) => {
    const { deleteForum } = state.forums;
    return {
        deleteForum,
    };
};

const actionToProps = {
    deleteThread,
};

export default connect(stateToProps, actionToProps)(ForumItemDetail);
