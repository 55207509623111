import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import Icon from '../../../../components/Icon';
import './index.css';
import { fetchUserTips } from '../../../../actions/tipping';
import { useParams } from 'react-router-dom';

const Tips = (props) => {
    const { fetchUserTips, tipsInfo } = props;
    const params = useParams();

    useEffect(() => {
        fetchUserTips(params.id);
    }, [params]);

    return (
        <div className="newsfeed__tips p-5">
            <div className="tips__heading font-bold "><h2>Tips</h2></div>
            <div className="tips__content flex flex-col sm:flex-row justify-around p-10 lg:p-20">
                <div className="tips__items flex flex-col justify-center items-center pb-8 md:pb-0">
                    <div className="tips__image bg--blue mb-4">
                        <Icon
                            className="dollars"
                            icon="dollars"
                        />
                    </div>
                    <div className="tips__text flex flex-col justify-around align-center text-center">
                        <span className="">{tipsInfo.tips.received * Math.pow(10, -16)}</span>
                        <span>Tips Received</span>
                    </div>
                </div>
                <div className="tips__items flex flex-col justify-center items-center">
                    <div className="tips__image bg--light-green mb-4">
                        <Icon
                            className="stack"
                            icon="stack"
                        />
                    </div>
                    <div className="tips__text flex flex-col justify-center items-center text-center">
                        <span>{tipsInfo.tips.given * Math.pow(10, -16)}</span>
                        <span>Tips Given</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

Tips.propTypes = {
    fetchUserTips: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    tipsInfo: PropTypes.object.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        tipsInfo: state.tipping.userTips,
    };
};

const actionToProps = {
    fetchUserTips,
};
export default connect(stateToProps, actionToProps)(Tips);
