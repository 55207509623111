import React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './index.css';
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import {
    activateTwoFactorAuth,
    hideAuthenticationDialog,
    showAuthenticationErrorDialog,
    showAuthenticationSuccessDialog,
    updateTwoFactorAuth,
} from '../../../../actions/settings';
import { fetchProfileDetails } from '../../../../actions/navigationBar';
import AuthenticationCodeTextField from './AuthenticationCodeTextField';
import VerifyButton from './VerifyButton';

const AuthenticateDialog = (props) => {
    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && !disable) {
            handleVerify();
        }
    };

    const handleClose = () => {
        props.handleClose();
        props.showSuccessDialog();
    };

    const handleVerify = () => {
        props.verify({ token: props.token }, (error) => {
            if (error) {
                props.showErrorDialog();
                props.handleClose();

                return;
            }

            props.fetchProfileDetails();
            handleClose();
        });
    };

    const disable = !props.token || props.token.length < 6;

    return (
        <Dialog
            aria-describedby="authenticate-dialog-description"
            aria-labelledby="authenticate-dialog-title"
            className="dialog authenticate_dialog"
            open={props.open}
            onClose={props.handleClose}>
            <DialogContent className="content">
                <p className="title_text">
                    1. Please download <b>Google Authenticator</b> (or a 2FA app of your choice) for your device:
                </p>
                <div className="links_div">
                    <a
                        href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_IN"
                        rel="noopener noreferrer"
                        target="_blank">
                        Google Play Store
                    </a>
                    <span/>
                    <a
                        href="https://apps.apple.com/us/app/google-authenticator/id388497605"
                        rel="noopener noreferrer"
                        target="_blank">
                        App Store
                    </a>
                </div>
                <p className="title_text">
                    2. Scan the QR code or enter the Setup Key to add the ExntAdmin.io to your device:
                </p>
                <img alt="qr code" className="qr_code" src={props.qrData && props.qrData.qr}/>
                <p className="qr_info">Setup Key: <b>{props.qrData && props.qrData.secret}</b></p>
                <p className="note_text">(use this key to add exntAdmin.io into the Authenticator app, without scanning
                    the QR code)</p>
                <p className="title_text">
                    3. Enter the 6-digit Authentication Code and confirm installation for <b>Two Factor
                    Authentication</b>
                </p>
                <form
                    noValidate
                    autoComplete="off"
                    onKeyPress={handleKeyPress}
                    onSubmit={(e) => {
                        e.preventDefault();
                    }}>
                    <AuthenticationCodeTextField/>
                </form>
            </DialogContent>
            <DialogActions className="button_div">
                <VerifyButton disable={disable} lang={props.lang} onClick={handleVerify}/>
            </DialogActions>
        </Dialog>
    );
};

AuthenticateDialog.propTypes = {
    enable: PropTypes.func.isRequired,
    fetchProfileDetails: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    showErrorDialog: PropTypes.func.isRequired,
    showSuccessDialog: PropTypes.func.isRequired,
    verify: PropTypes.func.isRequired,
    qrData: PropTypes.shape({
        qr: PropTypes.string,
        secret: PropTypes.string,
    }),
    token: PropTypes.string,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        open: state.settings.dialog,
        qrData: state.settings.addTwoFactorAuth.data,
        token: state.settings.authCode,
    };
};

const actionToProps = {
    enable: updateTwoFactorAuth,
    handleClose: hideAuthenticationDialog,
    showErrorDialog: showAuthenticationErrorDialog,
    showSuccessDialog: showAuthenticationSuccessDialog,
    verify: activateTwoFactorAuth,
    fetchProfileDetails,
};

export default connect(stateToProps, actionToProps)(AuthenticateDialog);
