import React, { Component } from 'react';
import '../Dashboard/index.css';
import './index.css';
import UnstakeRequestTable from '../Dashboard/UnstakeRequestTable';
import Cards from './Cards';
import * as PropTypes from 'prop-types';
import { fetchUnstakeReport } from '../../actions/unstakeRequests';
import { connect } from 'react-redux';
import { DEFAULT_LIMIT, DEFAULT_ORDER, DEFAULT_SEARCH_KEY, DEFAULT_SKIP, DEFAULT_SORT_BY } from '../../constants/url';

class UnstakeRequests extends Component {
    componentDidUpdate (pp, ps, ss) {
        if (this.props.auth && (pp.auth !== this.props.auth)) {
            this.handleFetch(DEFAULT_SKIP,
                DEFAULT_LIMIT,
                DEFAULT_SEARCH_KEY,
                DEFAULT_SORT_BY,
                DEFAULT_ORDER);
        }
    }

    handleFetch (skip, limit, searchText, sortBy, order) {
        this.props.fetch(skip, limit, searchText, sortBy, order);
    }

    onScroll (e) {
        const scroll = e.target.scrollTop;
        const scrollHeight = e.target.scrollHeight;
        const height = e.target.clientHeight;

        if (this.props.data.length < this.props.count && scrollHeight - scroll <= height + 256 && !this.props.listInProgress) {
            this.handleFetch(this.props.skip,
                this.props.limit + 10,
                this.props.searchKey,
                this.props.sortBy,
                this.props.order);
        }
    }

    render () {
        return (
            <div
                className="dashboard unstake_request content_padding scroll_bar"
                onScroll={(e) => this.onScroll(e)}>
                <Cards/>
                <UnstakeRequestTable pagination/>
            </div>
        );
    }
}

UnstakeRequests.propTypes = {
    count: PropTypes.number.isRequired,
    data: PropTypes.array.isRequired,
    fetch: PropTypes.func.isRequired,
    limit: PropTypes.number.isRequired,
    listInProgress: PropTypes.bool.isRequired,
    order: PropTypes.number.isRequired,
    searchKey: PropTypes.string.isRequired,
    skip: PropTypes.number.isRequired,
    sortBy: PropTypes.string.isRequired,
    auth: PropTypes.string,
};

const stateToProps = (state) => {
    return {
        auth: state.accessToken.value,
        data: state.unstakeRequests.list.list,
        count: state.unstakeRequests.list.count,
        listInProgress: state.unstakeRequests.list.inProgress,
        limit: state.unstakeRequests.list.limit,
        order: state.unstakeRequests.list.order,
        searchKey: state.unstakeRequests.list.searchKey,
        skip: state.unstakeRequests.list.skip,
        sortBy: state.unstakeRequests.list.sortBy,
    };
};

const actionToProps = {
    fetch: fetchUnstakeReport,
};

export default connect(stateToProps, actionToProps)(UnstakeRequests);
