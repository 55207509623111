import { combineReducers } from 'redux';
import {
    STAKING_PLANS_FETCH_ERROR,
    STAKING_PLANS_FETCH_IN_PROGRESS,
    STAKING_PLANS_FETCH_SUCCESS,
} from '../constants/staking';

const stakingPlan = (state = {
    list: [],
    value: '',
    inProgress: false,
}, action) => {
    switch (action.type) {
    case STAKING_PLANS_FETCH_SUCCESS:
        return {
            ...state,
            list: action.items,
            inProgress: false,
        };
    case STAKING_PLANS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case STAKING_PLANS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

export default combineReducers({ stakingPlan });
