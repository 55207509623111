import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchUserImage } from '../../actions/forum';

const UserImage = (props) => {
    const { fetchUserImage, image, author, user } = props;
    const [userImage, setUserImage] = useState('');

    useEffect(() => {
        const fetch = async () => {
            await fetchUserImage(author);
        };
        fetch();
    }, []);

    useEffect(() => {
        if (author === user) {
            setUserImage(image);
        }
        console.log(user);
    }, [user]);

    return (
        <>
            <div className="w-14 md:w-20 h-14 bg-white rounded-full mr-4 overflow-hidden">
                <img className="w-14 md:w-20 h-14 object-cover rounded-full" src={userImage} />
            </div>
        </>
    );
};

UserImage.propTypes = {
    author: PropTypes.string,
    fetchUserImage: PropTypes.func,
    image: PropTypes.any,
    user: PropTypes.string,
};

const stateToProps = (state) => {
    const { result, user } = state.forums.userImage.image;
    return {
        image: result,
        user,
    };
};

const actionToProps = {
    fetchUserImage,
};

export default connect(stateToProps, actionToProps)(UserImage);
