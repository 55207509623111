import React, { Component } from 'react';
import DataTable from '../../components/DataTable';
import './index.css';
import variables from '../../utils/variables';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CircularProgress from '../../components/CircularProgress';
import { fetchReferralsList } from '../../actions/referrals';
import { Link } from 'react-router-dom';
import { fetchProfileDetails } from '../../actions/navigationBar';

class Table extends Component {
    constructor (props) {
        super(props);

        this.handleFetch = this.handleFetch.bind(this);
    }

    componentDidMount () {
        this.props.fetchProfileDetails();
        this.handleFetch();
    }

    componentDidUpdate (pp, ps, ss) {
        if (this.props.auth && (pp.auth !== this.props.auth)) {
            this.props.fetchProfileDetails();
            this.handleFetch();
        }
    }

    handleFetch () {
        this.props.fetch();
    }

    handleCopy (data) {
        navigator.clipboard.writeText(data);
    }

    render () {
        const options = {
            responsive: 'vertical',
            serverSide: false,
            print: false,
            selectableRows: 'none',
            searchPlaceholder: variables[this.props.lang]['search_by_account'],
            textLabels: {
                body: {
                    noMatch: this.props.inProgress
                        ? <CircularProgress/>
                        : <div className="no_data_table"> No data found </div>,
                    toolTip: 'Sort',
                },
                filter: {
                    all: 'All',
                    title: 'FILTERS',
                    reset: 'RESET',
                },
                viewColumns: {
                    title: 'Show Columns',
                    titleAria: 'Show/Hide Table Columns',
                },
            },
        };

        const columns = [{
            name: 'email_address',
            label: 'Email',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => (
                    <Link className="font-bold user-detail__link" to={`/${(this.props.user_id === value._id) ? 'profile' : 'user'}/${value._id}`}>{value.email_address}</Link>
                ),
            },
        }, {
            name: 'telegram_username',
            label: 'Telegram Username',
            options: {
                filter: false,
                sort: true,
            },
        }, {
            name: 'stake',
            label: 'EXNT Staked',
            options: {
                filter: true,
                sort: true,
            },
        }, {
            name: 'top_tier',
            label: 'Top Tier',
            options: {
                filter: true,
                sort: true,
            },
        }, {
            name: 'reward_amount',
            label: 'Rewards',
            options: {
                filter: true,
                sort: true,
            },
        }, {
            name: 'email_address',
            label: 'Referrer Email',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => (
                    <Link className="font-bold user-detail__link" to={`/${(this.props.user_id === value._id) ? 'profile' : 'user'}/${value._id}`}>{value.email_address}</Link>
                ),
            },
        }, {
            name: 'primary_account_address',
            label: 'Referrer Address',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value) => (
                    value !== 'N/A'
                        ? <div className="hash_text" title="click to copy!" onClick={() => this.handleCopy(value)}>
                            {value}
                        </div>
                        : 'N/A'
                ),
            },
        }, {
            name: 'telegram_username',
            label: 'Referrer Telegram Username',
            options: {
                filter: false,
                sort: false,
            },
        }];

        const tableData = this.props.data && this.props.data.list && this.props.data.list.length
            ? this.props.data.list.map((item) =>
                [
                    item,
                    item.telegram_username,
                    item.stake && item.stake * Math.pow(10, -16),
                    item.top_tier,
                    item.reward_amount && item.reward_amount * Math.pow(10, -16),
                    item.referrer && item.referrer,
                    item.referrer && item.referrer.primary_account_address,
                    item.referrer && item.referrer.telegram_username,
                ])
            : [];

        return (
            <div className="table">
                <DataTable
                    columns={columns}
                    data={tableData}
                    name={variables[this.props.lang].referrals}
                    options={options}/>
            </div>
        );
    }
}

Table.propTypes = {
    data: PropTypes.array.isRequired,
    fetch: PropTypes.func.isRequired,
    fetchProfileDetails: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    user_id: PropTypes.string.isRequired,
    auth: PropTypes.string,
};

const stateToProps = (state) => {
    return {
        user_id: state.navigationBar.profileDetails.value._id,
        auth: state.accessToken.value,
        lang: state.language,
        data: state.referrals.tableData.list,
        inProgress: state.referrals.tableData.inProgress,
    };
};

const actionToProps = {
    fetch: fetchReferralsList,
    fetchProfileDetails,
};

export default connect(stateToProps, actionToProps)(Table);
