import React from 'react';
import * as PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import variables from '../../utils/variables';

const VerifyButton = (props) => {
    return (
        <Button
            className="sign_in_button w-full"
            disabled={props.disable}
            variant="contained"
            onClick={props.onClick}>
            {variables[props.lang].verify}
        </Button>
    );
};

VerifyButton.propTypes = {
    disable: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default VerifyButton;
