import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import TextArea from './TextArea';

const InputField = ({
    label,
    fieldName,
    type,
    placeholder,
    value,
    fieldClass,
    handleForm,
}) => {
    const [input, setInput] = useState({ field: '' });

    useEffect(() => {
        setInput({ field: value || '' });
    }, [value]);

    const handleOnChange = (
        event,
    ) => {
        const { name, value: inputValue } = event.target;
        setInput((prevState) => ({ ...prevState, field: inputValue }));
        handleForm(name, inputValue);
    };

    const handleOnChangeTextarea = (
        name,
        content,
    ) => {
        setInput((prevState) => ({ ...prevState, field: content }));
        handleForm(name, content);
    };

    return (
        <div className="flex flex-col mb-4 font-oswald-text">
            <label htmlFor={fieldName}>
                <span className="text-base mb-2 block text-white font-oswald-text opacity-50">{label}</span>
            </label>
            {type === 'textarea' ? (
                <TextArea
                    className={`border rounded border-custom-dark px-4 py-2 bg-custom-dark font-oswald-text text-white text-lg ${fieldClass}`}
                    name={fieldName}
                    onChangeTextarea={handleOnChangeTextarea}
                />
            ) : (
                <input
                    className={`border rounded border-custom-white px-4 py-2 bg-custom-dark font-oswald-text text-white text-lg ${fieldClass}`}
                    id={fieldName}
                    name={fieldName}
                    placeholder={placeholder || ''}
                    type={type || 'text'}
                    value={input.field}
                    onChange={handleOnChange}
                />
            )}
        </div>
    );
};

InputField.propTypes = {
    fieldClass: PropTypes.string,
    fieldName: PropTypes.string,
    handleForm: PropTypes.func,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.string,
};

export default InputField;
