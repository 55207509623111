import React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Checkbox, FormControlLabel, makeStyles } from '@material-ui/core';
import { setRememberPassword } from '../../actions/signIn';
import variables from '../../utils/variables';
import classNames from 'classnames';

const useStyles = makeStyles(() => ({
    root: {
        '& .MuiCheckbox-colorSecondary': {
            '&.Mui-checked': {
                color: '#003B42',
            },
            '&.Mui-checked:hover': {
                backgroundColor: '#003b421c',
            },
        },
        '& .MuiIconButton-colorSecondary:hover': {
            backgroundColor: '#003b421c',
        },
    },
}));

const RememberButton = (props) => {
    const handleChange = (event) => {
        props.onChange(event.target.checked);
    };

    return (
        <FormControlLabel
            className={classNames(useStyles().root, 'check_box')}
            control={
                <Checkbox
                    checked={props.value}
                    name="checkedA"
                    onChange={handleChange}/>
            }
            label={variables[props.lang]['remember_me']}
        />
    );
};

RememberButton.propTypes = {
    lang: PropTypes.string.isRequired,
    value: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        value: state.signIn.rememberPassword,
    };
};

const actionToProps = {
    onChange: setRememberPassword,
};

export default connect(stateToProps, actionToProps)(RememberButton);
