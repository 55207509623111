import React from 'react';
import '../Dashboard/index.css';
import './index.css';
import Table from './Table';

const Tipping = () => {
    return (
        <div className="dashboard tipping content_padding">
            <Table/>
        </div>
    );
};

export default Tipping;
