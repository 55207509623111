import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getMessageSender } from '../../../actions/message';
import variables from '../../../utils/variables';
import moment from 'moment';

const Sender = (props) => {
    const { message, userData, lang } = props;
    const [sender, setSender] = useState('');
    const [isSentByMe, setIsSetByMe] = useState(false);

    useEffect(() => {
        if (userData?._id === message?.sender?._id) {
            setSender(message?.receiver?.email_address);
            setIsSetByMe(true);
        } else {
            setSender(message?.sender?.email_address);
            setIsSetByMe(false);
        }
    }, [message]);

    return (
        <>
            <div>
                <span className="mr-2 truncate w-full md:w-auto">
                    {isSentByMe ? (<span>{variables[lang].sent_to}</span>) : (<span>{variables[lang].sent_by}</span>)} <span className="text-white font-bold">{sender}</span>
                </span>
                <span className="text-sm text-custom-white">
                    {`${moment(message?.created_at).startOf('second').fromNow()}, ${moment(message?.created_at).format('hh:mm a')}`}
                </span>
            </div>
        </>
    );
};

Sender.propTypes = {
    lang: PropTypes.string.isRequired,
    getMessageSender: PropTypes.func,
    message: PropTypes.object,
    sender: PropTypes.any,
    userData: PropTypes.object,
};

const stateToProps = (state) => {
    const { result } = state.messaging.messageByID.message;
    const { sender } = state.messaging.messageSender.sender;

    return {
        messages: result,
        sender,
        lang: state.language,
    };
};

const actionToProps = {
    getMessageSender,
};

export default connect(stateToProps, actionToProps)(Sender);
