import { combineReducers } from 'redux';
import {
    MESSAGING_USERS_FETCH_IN_PROGRESS,
    MESSAGING_USERS_FETCH_SUCCESS,
    MESSAGING_USERS_FETCH_ERROR,
    MESSAGES_FETCH_IN_PROGRESS,
    MESSAGES_FETCH_SUCCESS,
    MESSAGES_FETCH_ERROR,
    MESSAGES_GET_BY_ID_IN_PROGRESS,
    MESSAGES_GET_BY_ID_SUCCESS,
    MESSAGES_GET_BY_ID_ERROR,
    MESSAGES_GET_SENDER_IN_PROGRESS,
    MESSAGES_GET_SENDER_SUCCESS,
    MESSAGES_GET_SENDER_ERROR,
    MESSAGES_GET_RECIPIENT_IN_PROGRESS,
    MESSAGES_GET_RECIPIENT_SUCCESS,
    MESSAGES_GET_RECIPIENT_ERROR,
} from '../constants/messaging';

const usersList = (
    state = {
        inProgress: false,
        usersList: [],
    },
    action,
) => {
    switch (action.type) {
    case MESSAGING_USERS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case MESSAGING_USERS_FETCH_SUCCESS:
        return {
            inProgress: false,
            usersList: action.value,
        };
    case MESSAGING_USERS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const recievedMessages = (
    state = {
        inProgress: false,
        recievedMessages: [],
    },
    action,
) => {
    switch (action.type) {
    case MESSAGES_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case MESSAGES_FETCH_SUCCESS:
        return {
            inProgress: false,
            recievedMessages: action.value,
        };
    case MESSAGES_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const messageByID = (
    state = {
        inProgress: false,
        message: [],
    },
    action,
) => {
    switch (action.type) {
    case MESSAGES_GET_BY_ID_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case MESSAGES_GET_BY_ID_SUCCESS:
        return {
            ...state,
            message: action.value,
        };
    case MESSAGES_GET_BY_ID_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const messageSender = (
    state = {
        inProgress: false,
        sender: [],
    },
    action,
) => {
    switch (action.type) {
    case MESSAGES_GET_SENDER_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case MESSAGES_GET_SENDER_SUCCESS:
        return {
            inProgress: false,
            sender: action.value,
        };
    case MESSAGES_GET_SENDER_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const messageRecipient = (
    state = {
        inProgress: false,
        recipient: [],
    },
    action,
) => {
    switch (action.type) {
    case MESSAGES_GET_RECIPIENT_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case MESSAGES_GET_RECIPIENT_SUCCESS:
        return {
            inProgress: false,
            recipient: action.value,
        };
    case MESSAGES_GET_RECIPIENT_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

export default combineReducers({
    usersList,
    recievedMessages,
    messageByID,
    messageSender,
    messageRecipient,
});
