import React from 'react';
import DealsTable from './DealsTable';
import { useSelector } from 'react-redux';

const Deals = () => {
    const userId = useSelector((state) => state.navigationBar.profileDetails.value._id);
    return (
        <div className="deals content_padding">
            {userId && <DealsTable />}
        </div>
    );
};

export default Deals;
