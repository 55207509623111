export const DEALS_FETCH_IN_PROGRESS = 'DEALS_FETCH_IN_PROGRESS';
export const DEALS_FETCH_SUCCESS = 'DEALS_FETCH_SUCCESS';
export const DEALS_FETCH_ERROR = 'DEALS_FETCH_ERROR';

export const DEAL_FETCH_IN_PROGRESS = 'DEAL_FETCH_IN_PROGRESS';
export const DEAL_FETCH_SUCCESS = 'DEAL_FETCH_SUCCESS';
export const DEAL_FETCH_ERROR = 'DEAL_FETCH_ERROR';

export const NEW_DEAL_IN_PROGRESS = 'NEW_DEAL_IN_PROGRESS';
export const NEW_DEAL_SUCCESS = 'NEW_DEAL_SUCCESS';
export const NEW_DEAL_ERROR = 'NEW_DEAL_ERROR';

export const UPDATE_DEAL_IN_PROGRESS = 'UPDATE_DEAL_IN_PROGRESS';
export const UPDATE_DEAL_SUCCESS = 'UPDATE_DEAL_SUCCESS';
export const UPDATE_DEAL_ERROR = 'UPDATE_DEAL_ERROR';

export const CONTRIBUTORS_FETCH_IN_PROGRESS = 'CONTRIBUTORS_FETCH_IN_PROGRESS';
export const CONTRIBUTORS_FETCH_SUCCESS = 'CONTRIBUTORS_FETCH_SUCCESS';
export const CONTRIBUTORS_FETCH_ERROR = 'CONTRIBUTORS_FETCH_ERROR';

export const CONTRIBUTOR_FETCH_IN_PROGRESS = 'CONTRIBUTOR_FETCH_IN_PROGRESS';
export const CONTRIBUTOR_FETCH_SUCCESS = 'CONTRIBUTOR_FETCH_SUCCESS';
export const CONTRIBUTOR_FETCH_ERROR = 'CONTRIBUTOR_FETCH_ERROR';

export const GET_CONTRIBUTOR_ACCOUNT_SUCCESS = 'GET_CONTRIBUTOR_ACCOUNT_SUCCESS';
export const GET_CONTRIBUTOR_ACCOUNT_ERROR = 'GET_CONTRIBUTOR_ACCOUNT_ERROR';
