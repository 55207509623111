import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import { Button, Dialog, DialogContent } from '@material-ui/core';
import './index.css';
import CloseDialog from '../../../components/CloseDialog';
import { hideDealForm } from '../../../actions/settings';
import { fetchConfig, updateConfig } from '../../../actions/config';
import TimezoneSelectField from '../../../components/TimezoneSelectField';
import TimepickerField from '../../../components/TimePickerField';
import TierLimitField from '../../../components/TierLimitField';

const DealsForm = (props) => {
    const [dealPostTime, setDealPostTime] = useState('');
    const [timezone, setTimezone] = useState({});
    const [dealPostTimezone, setDealPostTimezone] = useState('');
    const [dealPostTierLimit, setDealPostTierLimit] = useState('');

    const {
        dealConfig,
        fetch,
        updateConfig,
        onUpdateFeedback,
    } = props;

    useEffect(() => {
        if (isEmpty(dealConfig)) {
            fetch();
        } else {
            const {
                deal_post_time: dealPostTime,
                deal_post_tier_limit: dealPostTierLimit,
            } = dealConfig;

            setDealPostTime(dealPostTime);
            setDealPostTierLimit(dealPostTierLimit);
        }
    }, [dealConfig]);

    const handleKeyPress = () => {
        props.hideDealForm();
    };

    const onClose = () => {
        props.hideDealForm();
    };

    const onTimezoneChange = (e) => {
        setTimezone(e);
        setDealPostTimezone(e.value);
    };

    const onClick = () => {
        const {
            deal_post_time: postTime,
            deal_post_timezone: postTimezone,
            deal_post_tier_limit: postTierLimit,
        } = dealConfig;

        const data = {};
        if (postTime !== dealPostTime) {
            data.dealPostTime = dealPostTime;
        }

        if (dealPostTimezone && postTimezone !== dealPostTimezone) {
            data.dealPostTimezone = dealPostTimezone;
        }

        if (postTierLimit !== dealPostTierLimit) {
            data.dealPostTierLimit = dealPostTierLimit;
        }

        updateConfig(data, (error) => {
            const { inProgress } = props;
            if (!error && !inProgress) {
                onUpdateFeedback('Successfully updated config.', 'success');
                fetch();
                props.hideDealForm();
            } else if (error) {
                onUpdateFeedback(`${error}`, 'error');
            }
        });
    };

    return (
        <>
            <Dialog
                aria-describedby="change-password-description"
                aria-labelledby="change-password-title"
                className="w-full "
                open={props.dealFormDialog}>
                <DialogContent className="change-password__content w-full rounded-lg mb-4 relative">
                    <form
                        noValidate
                        autoComplete="off"
                        className="card p-4 text-white"
                        onKeyPress={handleKeyPress}
                    >
                        <h3 className="font-oswald-text font-bold text-2xl mb-4">Deals</h3>
                        <CloseDialog className="absolute top-9 right-10" onClose={onClose}/>
                        <div className="row mb-4">
                            <TimezoneSelectField value={timezone} onChange={onTimezoneChange}/>
                        </div>
                        <div className="row mb-4">
                            <TimepickerField value={dealPostTime} onChange={setDealPostTime}/>
                        </div>
                        <div className="row mb-4">
                            <TierLimitField value={dealPostTierLimit} onChange={setDealPostTierLimit}/>
                        </div>
                        <div className="button_div flex justify-between">
                            <Button
                                className="w-1/2 min-w-0 mx-2 font-oswald-text cancel_button"
                                onClick={onClose}>
                                Cancel
                            </Button>
                            <Button
                                className="change_password w-1/2 mx-2 font-oswald-text"
                                onClick={onClick}>
                                Modify
                            </Button>
                        </div>
                    </form>
                </DialogContent>
            </Dialog>
        </>
    );
};

DealsForm.propTypes = {
    dealConfig: PropTypes.object.isRequired,
    dealFormDialog: PropTypes.bool.isRequired,
    fetch: PropTypes.func.isRequired,
    hideDealForm: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    updateConfig: PropTypes.func.isRequired,
    onUpdateFeedback: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        dealFormDialog: state.settings.dealFormDialog,
        dealConfig: state.config.dealConfig.result,
        inProgress: state.config.updateDealConfig.inProgress,
    };
};

const actionToProps = {
    hideDealForm,
    updateConfig,
    fetch: fetchConfig,
};

export default connect(stateToProps, actionToProps)(DealsForm);
