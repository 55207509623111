import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './index.css';
import verifySuccess from '../../../../assets/verifySuccess.png';
import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { hideAuthenticationSuccessDialog } from '../../../../actions/settings';
import OkButton from './OkButton';
import { decryptToken } from '../../../../utils/crypto';

const AuthenticateSuccessDialog = (props) => {
    const [disable, setDisable] = useState(true);

    return (
        <Dialog
            aria-describedby="authenticate-success-dialog-description"
            aria-labelledby="authenticate-success-dialog-title"
            className="dialog authenticate_dialog success_dialog"
            open={props.open}>
            <DialogContent className="content">
                <div className="success_text">
                    <img alt="security" src={verifySuccess}/>
                    <p>Two Factor Authentication added</p>
                </div>
                <p className="note_text">
                    From now, when you sign in into your Exnetwork account, you will need to enter both
                    your Password
                    and an Authentication code.
                </p>
                <div className="row note_div">
                    <p className="text">
                        <b>NOTE</b> : Backup your key! This will help you access your account if you ever
                        damage/misplace your 2FA device
                    </p>
                    {props.qrData && props.qrData.backup_token
                        ? <Button
                            className="download_button"
                            onClick={() => setDisable(false)}>
                            <a
                                download={'EXNT_2FA_backup_key.txt'}
                                href={window.URL
                                    .createObjectURL(new Blob([JSON.stringify(
                                        decryptToken(props.qrData && props.qrData.backup_token))],
                                    { type: 'text/csv' }))}>
                                Download Key
                            </a>
                        </Button>
                        : <p className="no_data">No Backup Key found</p>}
                </div>
            </DialogContent>
            <DialogActions className="button_div">
                <OkButton disable={disable} onClose={props.handleClose}/>
            </DialogActions>
        </Dialog>
    );
};

AuthenticateSuccessDialog.propTypes = {
    handleClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    qrData: PropTypes.shape({
        backup_token: PropTypes.string,
    }),
};

const stateToProps = (state) => {
    return {
        open: state.settings.successDialog,
        qrData: state.settings.addTwoFactorAuth.data,
    };
};

const actionToProps = {
    handleClose: hideAuthenticationSuccessDialog,
};

export default connect(stateToProps, actionToProps)(AuthenticateSuccessDialog);
