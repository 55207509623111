import React, { useState, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';
import close from '../../assets/images/close.svg';
import { connect } from 'react-redux';
import { proofUpdateTask } from '../../actions/tasks';
import Snackbar from '../../components/Snackbar';

const ManualDialog = ({
    item,
    handleClose,
    open,
    proofUpdateTask,
    taskId,
}) => {
    const [previewImage, setPreviewImage] = useState('');
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackBarType, setSnackBarType] = useState('success');
    const [proofStatus, setProofStatus] = useState('PROCESSING');

    useEffect(() => {
        if (open && !item.inProgress) {
            setPreviewImage(item.participant.participants[0].proof.uploadedFile);
            setProofStatus(item.participant.participants[0].proof.status);
        }
    }, [open, item]);

    const statusUpdate = (status) => {
        proofUpdateTask(taskId, { pid: item.participant.participants[0]._id, status }, (error) => {
            if (!error) {
                openSnackbarOnSuccess('Task Successfully updated.');
                setSnackBarType('success');
                handleClose();
            } else {
                openSnackbarOnError(`${error}`);
                setSnackBarType('error');
            }
        });
    };

    const openSnackbarOnError = (message) => {
        setSnackbarMessage(message);
        setSnackbarOpen(true);
    };

    const openSnackbarOnSuccess = (message) => {
        setSnackbarMessage(message);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <>
            <Snackbar
                message={snackbarMessage}
                open={snackbarOpen}
                severity={snackBarType}
                onClose={handleSnackbarClose}
            />
            <Dialog
                aria-describedby="account-type-dialog-description"
                aria-labelledby="account-type-dialog-title"
                className="dialog account_verification"
                open={open}
                onClose={handleClose}>
                <DialogContent className="tasks-dialog content">
                    {(!isEmpty(item)
                        ? <>
                            <div className="header flex items-center pb-4  justify-between">
                                <div className="flex items-center">
                                    <div className="circle circle--yellow text-white font-semibold font-oswald-text text-2xl flex items-center justify-center">
                                        <span>M</span>
                                    </div>
                                    <div className="ml-4 flex flex-col text-center">
                                        <span className="font-semibold font-oswald-text text-xl">Manual</span>
                                        <span className="font-oswald-text text-xs opacity-50">Task Type</span>
                                    </div>
                                </div>
                                <img alt="close" className="task-close" src={close} onClick={handleClose} />
                            </div>
                            <div className="py-3 md:py-4">
                                <h2 className="font-bold font-oswald-text text-xl md:text-3xl mb-2">Youtube Video</h2>
                                <h3 className="font-semibold font-oswald-text text-sm md:text-base">Like our Youtube video.</h3>
                            </div>
                            <div className="pt-2 md:pt-4">
                                <span className="font-oswald-text text-xs opacity-50 mb-0 md:mb-2 block">Preview</span>
                                <div className="flex items-center justify-center">
                                    <img alt="" className="w-full" src={previewImage} />
                                </div>
                            </div>
                        </> : <h1>Loading...</h1>
                    )}
                </DialogContent>
                <DialogActions className="tasks-actions mt-0 text-center mb-8 flex flex-col">
                    {(proofStatus === 'PROCESSING')
                        ? <>
                            <Button className="w-full button-upload text-2xl" onClick={() => statusUpdate('APPROVED')}>Approve</Button>
                            <Button className="w-full button-cancel text-2xl" onClick={() => statusUpdate('DECLINED')}>Decline</Button>
                        </>
                        : <h1 className="font-semibold font-oswald-text text-xl">{proofStatus}</h1>
                    }
                </DialogActions>
            </Dialog>
        </>
    );
};

ManualDialog.propTypes = {
    handleClose: PropTypes.func.isRequired,
    item: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    proofUpdateTask: PropTypes.func.isRequired,
    taskId: PropTypes.string.isRequired,
};

const stateToProps = (state) => {
    return {
    };
};

const actionToProps = {
    proofUpdateTask,
};

export default connect(stateToProps, actionToProps)(ManualDialog);
