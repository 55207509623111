import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import { fetchForums } from '../../actions/forum';
import ForumContainer from '../../components/Forum/ForumContainer';
import ForumItemRow from '../../components/Forum/ForumItemRow';
import Snackbar from '../../components/Snackbar';
import './index.css';

const Forum = (props) => {
    const { forums, fetchForums, inProgress, totalPage, page } = props;
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState('');

    useEffect(() => {
        const fetchList = async () => {
            await fetchForums();
        };

        if (typeof forums === 'undefined') {
            fetchList();
        }
    }, []);

    const handlePageClick = async (event) => {
        const selectedPage = event.selected + 1;
        await fetchForums(selectedPage);
    };

    const handleFetchForums = async () => {
        await fetchForums();

        setSnackbarMessage('Thread Deleted');
        setSnackbarOpen(true);
        setSnackbarSeverity('success');
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <div className="text-white bg-custom-dark-2 mb-4 mt-10 px-10 py-10 rounded-2xl w-4/5 mx-auto pb-10">
            <Snackbar
                message={snackbarMessage}
                open={snackbarOpen}
                severity={snackbarSeverity}
                onClose={handleSnackbarClose}
            />
            <div className="accordion__column flex-auto">
                <h1 className="heading_text text-3xl md:text-4xl font-semibold font-oswald-text mb-4 text-white">Forum</h1>
            </div>
            <ForumContainer>
                <div className="">
                    { !inProgress && (
                        <ForumItemRow data={forums} handleFetchForums={handleFetchForums} />
                    )}
                </div>
                {totalPage > 1 && (
                    <ReactPaginate
                        activeClassName="text-gray-500 font-oswald-text font-bold border-fuchsia-700"
                        breakClassName="page-item p-2 md:py-4 md:px-4"
                        breakLabel="..."
                        breakLinkClassName="page-link text-sm md:text-base"
                        containerClassName="text-white pagination flex mt-4 justify-center gap-2 md:gap-3"
                        forcePage={page - 1}
                        marginPagesDisplayed={2}
                        nextClassName="page-item border rounded-xl w-14 text-center"
                        nextLabel="»"
                        nextLinkClassName="page-link text-gray-500 text-sm md:text-base p-2 md:py-4 md:px-4 block"
                        pageClassName="page-item border rounded-xl w-14 text-center"
                        pageCount={totalPage}
                        pageLinkClassName="page-link text-sm md:text-base p-2 md:py-4 md:px-4 block"
                        pageRangeDisplayed={1}
                        previousClassName="page-item border rounded-xl w-14 text-center"
                        previousLabel="«"
                        previousLinkClassName="page-link text-gray-500 text-sm md:text-base p-2 md:py-4 md:px-4 block"
                        onPageChange={handlePageClick}
                    />
                )}
            </ForumContainer>
        </div>
    );
};

Forum.propTypes = {
    fetchForums: PropTypes.func,
    forums: PropTypes.array,
    inProgress: PropTypes.bool,
    page: PropTypes.number,
    total: PropTypes.number,
    totalPage: PropTypes.number,
};

const stateToProps = (state) => {
    const { forums, inProgress } = state.forums.forumList;
    return {
        forums: forums.forum,
        page: forums.page,
        total: forums.total,
        totalPage: forums.totalPage,
        inProgress,
    };
};

const actionToProps = {
    fetchForums,
};

export default connect(stateToProps, actionToProps)(Forum);
