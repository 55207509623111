import React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './index.css';
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { hideAuthenticationErrorDialog, showAuthenticationDialog } from '../../../../actions/settings';
import TryAgainButton from './TryAgainButton';

const AuthenticateErrorDialog = (props) => {
    const handleClick = () => {
        props.handleClose();
        props.showDialog();
    };

    return (
        <Dialog
            aria-describedby="authenticate-error-dialog-description"
            aria-labelledby="authenticate-error-dialog-title"
            className="dialog authenticate_dialog error_dialog"
            open={props.open}
            onClose={props.handleClose}>
            <DialogContent className="content">
                <p className="error_text">
                    Error verifying your Two Factor Authentication.
                    <br/><br/>
                    Make sure you follow the right procedure for code verification and scane the QR only from a 2FA app.
                    Please scan the QR Code properly or enter the key to add and verify the code.
                    <br/><br/>
                    Kindly try again.
                </p>
            </DialogContent>
            <DialogActions className="button_div">
                <TryAgainButton onClose={handleClick}/>
            </DialogActions>
        </Dialog>
    );
};

AuthenticateErrorDialog.propTypes = {
    handleClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    showDialog: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        open: state.settings.errorDialog,
    };
};

const actionToProps = {
    handleClose: hideAuthenticationErrorDialog,
    showDialog: showAuthenticationDialog,
};

export default connect(stateToProps, actionToProps)(AuthenticateErrorDialog);
