import { TASK_URL, specificTask, specificArchiveTask, specificParticipantTask, proofTask } from '../constants/url';
import Axios from 'axios';
import {
    TASK_LIST_FETCH_IN_PROGRESS,
    TASK_LIST_FETCH_SUCCESS,
    TASK_LIST_FETCH_ERROR,
    TASK_SPECIFIC_FETCH_IN_PROGRESS,
    TASK_SPECIFIC_FETCH_SUCCESS,
    TASK_SPECIFIC_FETCH_ERROR,
    ADD_TASK_IN_PROGRESS,
    ADD_TASK_SUCCESS,
    ADD_TASK_ERROR,
    UPDATE_TASK_IN_PROGRESS,
    UPDATE_TASK_SUCCESS,
    UPDATE_TASK_ERROR,
    TASK_SPECIFIC_PARTICIPANT_FETCH_IN_PROGRESS,
    TASK_SPECIFIC_PARTICIPANT_FETCH_SUCCESS,
    TASK_SPECIFIC_PARTICIPANT_FETCH_ERROR,
} from '../constants/tasks';

const fetchTaskListInProgress = () => {
    return {
        type: TASK_LIST_FETCH_IN_PROGRESS,
    };
};

const fetchTaskListSuccess = (value) => {
    return {
        type: TASK_LIST_FETCH_SUCCESS,
        value,
    };
};

const fetchTaskListError = (message) => {
    return {
        type: TASK_LIST_FETCH_ERROR,
        message,
    };
};

export const fetchTaskList = (queryPara) => (dispatch) => {
    dispatch(fetchTaskListInProgress());

    const addOnQuery = queryPara;
    const url = `${TASK_URL}?${addOnQuery}`;

    Axios.get(url, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
    })
        .then((res) => {
            dispatch(fetchTaskListSuccess(res.data && res.data.result));
        })
        .catch((error) => {
            dispatch(fetchTaskListError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const fetchSpecificTaskInProgress = () => {
    return {
        type: TASK_SPECIFIC_FETCH_IN_PROGRESS,
    };
};

const fetchSpecificTaskSuccess = (value) => {
    return {
        type: TASK_SPECIFIC_FETCH_SUCCESS,
        value,
    };
};

const fetchSpecificTaskError = (message) => {
    return {
        type: TASK_SPECIFIC_FETCH_ERROR,
        message,
    };
};

export const fetchSpecificTask = (id) => (dispatch) => {
    dispatch(fetchSpecificTaskInProgress());

    const url = specificTask(id);
    Axios.get(url, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
    })
        .then((res) => {
            dispatch(fetchSpecificTaskSuccess(res.data && res.data.result));
        })
        .catch((error) => {
            dispatch(fetchSpecificTaskError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const addTaskInProgress = () => {
    return {
        type: ADD_TASK_IN_PROGRESS,
    };
};

const addTaskSuccess = (value) => {
    return {
        type: ADD_TASK_SUCCESS,
        value,
    };
};

const addTaskError = (message) => {
    return {
        type: ADD_TASK_ERROR,
        message,
    };
};

export const addTask = (data, cb) => (dispatch) => {
    dispatch(addTaskInProgress());
    Axios.post(TASK_URL, data, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
    })
        .then((res) => {
            dispatch(addTaskSuccess(res.data.result, 'Success'));
            cb(null, res.data && res.data.result && res.data.result._id);
        })
        .catch((error) => {
            dispatch(addTaskError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            cb(error);
        });
};

const updateTaskInProgress = () => {
    return {
        type: UPDATE_TASK_IN_PROGRESS,
    };
};

const updateTaskSuccess = (value) => {
    return {
        type: UPDATE_TASK_SUCCESS,
        value,
    };
};

const updateTaskError = (message) => {
    return {
        type: UPDATE_TASK_ERROR,
        message,
    };
};

export const updateTask = (id, data, cb) => (dispatch) => {
    dispatch(updateTaskInProgress());

    const url = specificTask(id);
    Axios.put(url, data, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
    })
        .then((res) => {
            dispatch(updateTaskSuccess(res.data.result, 'Success'));
            cb(null);
        })
        .catch((error) => {
            dispatch(updateTaskError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            cb(error);
        });
};

export const archiveTask = (id, data, cb) => (dispatch) => {
    dispatch(updateTaskInProgress());

    const url = specificArchiveTask(id);

    Axios.put(url, data, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
    })
        .then((res) => {
            dispatch(updateTaskSuccess(res.data.result, 'Success'));
            cb(null);
        })
        .catch((error) => {
            dispatch(updateTaskError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            cb(error);
        });
};

const taskSpecifiParticipantInProgress = () => {
    return {
        type: TASK_SPECIFIC_PARTICIPANT_FETCH_IN_PROGRESS,
    };
};

const taskSpecifiParticipantSuccess = (value) => {
    return {
        type: TASK_SPECIFIC_PARTICIPANT_FETCH_SUCCESS,
        value,
    };
};

const taskSpecifiParticipantError = (message) => {
    return {
        type: TASK_SPECIFIC_PARTICIPANT_FETCH_ERROR,
        message,
    };
};

export const taskSpecifiParticipant = (id, pid) => (dispatch) => {
    dispatch(taskSpecifiParticipantInProgress());

    const url = specificParticipantTask(id, pid);

    Axios.get(url, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
    })
        .then((res) => {
            dispatch(taskSpecifiParticipantSuccess(res.data && res.data.result));
        })
        .catch((error) => {
            dispatch(taskSpecifiParticipantError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

export const proofUpdateTask = (id, data, cb) => (dispatch) => {
    dispatch(updateTaskInProgress());

    const url = proofTask(id);

    Axios.put(url, data, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
    })
        .then((res) => {
            dispatch(updateTaskSuccess(res.data.result, 'Success'));
            cb(null);
        })
        .catch((error) => {
            dispatch(updateTaskError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            cb(error);
        });
};
