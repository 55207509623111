export const PROFILE_DETAILS_FETCH_IN_PROGRESS = 'PROFILE_DETAILS_FETCH_IN_PROGRESS';
export const PROFILE_DETAILS_FETCH_SUCCESS = 'PROFILE_DETAILS_FETCH_SUCCESS';
export const PROFILE_DETAILS_FETCH_ERROR = 'PROFILE_DETAILS_FETCH_ERROR';

export const PROFILE_IMAGE_FETCH_IN_PROGRESS = 'PROFILE_IMAGE_FETCH_IN_PROGRESS';
export const PROFILE_IMAGE_FETCH_SUCCESS = 'PROFILE_IMAGE_FETCH_SUCCESS';
export const PROFILE_IMAGE_FETCH_ERROR = 'PROFILE_IMAGE_FETCH_ERROR';

export const SIGN_OUT_IN_PROGRESS = 'SIGN_OUT_IN_PROGRESS';
export const SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS';
export const SIGN_OUT_ERROR = 'SIGN_OUT_ERROR';

export const SHOW_SIDE_BAR_SET = 'SHOW_SIDE_BAR_SET';
export const HIDE_SIDE_BAR_SET = 'HIDE_SIDE_BAR_SET';
