import { Button } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import ProfilePopover from './PopOver';
import { ExpandMore } from '@material-ui/icons';
import { fetchProfileDetails, fetchProfileImage, hideSideBar } from '../../../actions/navigationBar';
import './index.css';
// import DotsLoading from '../../../components/DotsLoading';

class Profile extends Component {
    constructor (props) {
        super(props);
        this.state = {
            anchorEl: null,
        };

        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    componentDidMount () {
        this.props.fetchProfileDetails();
    }

    componentDidUpdate (pp, ps, ss) {
        if (this.props.auth && (pp.auth !== this.props.auth)) {
            this.props.fetchProfileDetails();
        }
    }

    handleClick (event) {
        this.setState({
            anchorEl: event.currentTarget,
        });
    }

    handleClose () {
        this.setState({
            anchorEl: null,
        });
    }

    render () {
        const open = Boolean(this.state.anchorEl);
        const id = open ? 'simple-popover' : undefined;
        const profileImg = this.props.profileImage.value.url;

        return (
            <div className="profile px-4 md:px-0">
                <Button
                    aria-describedby={id}
                    className="profile_button"
                    variant="contained"
                    onClick={this.handleClick}>
                    <div className="navigation__profile">
                        <img alt="profile" src={profileImg}/>
                    </div>
                    <ExpandMore/>
                </Button>
                <ProfilePopover
                    anchorEl={this.state.anchorEl}
                    handleClose={this.handleClose}
                    hideSideBar={this.props.handleClose}
                    id={id}
                    imageUrl={profileImg}
                    lang={this.props.lang}
                    open={open}
                    profileDetails={this.props.profileDetails}
                    show={this.props.show}/>
            </div>
        );
    }
}

Profile.propTypes = {
    fetchProfileDetails: PropTypes.func.isRequired,
    fetchProfileImage: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    imageInProgress: PropTypes.bool.isRequired,
    inProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    profileDetails: PropTypes.object.isRequired,
    profileImage: PropTypes.object.isRequired,
    show: PropTypes.bool.isRequired,
    auth: PropTypes.string,
};

const stateToProps = (state) => {
    return {
        auth: state.accessToken.value,
        image: state.navigationBar.profileImage.value,
        inProgress: state.navigationBar.profileDetails.inProgress,
        imageInProgress: state.navigationBar.profileImage.inProgress,
        lang: state.language,
        profileDetails: state.navigationBar.profileDetails.value,
        profileImage: state.navigationBar.profileImage,
        show: state.navigationBar.show,
    };
};

const actionToProps = {
    fetchProfileDetails,
    fetchProfileImage,
    handleClose: hideSideBar,
};

export default connect(stateToProps, actionToProps)(Profile);
