import React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { showchangePasswordDialog, updatePassword } from '../../../actions/settings';
import { fetchProfileDetails } from '../../../actions/navigationBar';
import { Button } from '@material-ui/core';
import ChangePasswordForm from './ChangePasswordForm';

const AccountsTab = (props) => {
    const displayChangePassword = () => {
        props.showchangePasswordDialog();
    };

    return (
        <>
            <div className="settings__item-container pt-6 md:pt-10 flex items-center flex-col md:flex-row">
                <div className="flex-1 mb-4 md:mb-0">
                    <h3 className="text-base md:text-2xl font-semibold font-oswald-text mb-2">Password</h3>
                    <p className="text-sm md:text-base font-semibold font-oswald-text text-custom-light-gray">Set a
                        unique password to protect your personal account</p>
                </div>
                <div>
                    <Button className="settings__button" disabled={false} onClick={displayChangePassword}>Change
                        Password</Button>
                </div>
            </div>
            <ChangePasswordForm/>
        </>
    );
};

AccountsTab.propTypes = {
    confirmPassword: PropTypes.string.isRequired,
    fetchProfileDetails: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    showChangePassword: PropTypes.bool.isRequired,
    showchangePasswordDialog: PropTypes.func.isRequired,
    updatePassword: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        confirmPassword: state.signIn.confirmPassword,
        inProgress: state.settings.changePassword.inProgress,
        lang: state.language,
        password: state.signIn.password,
        showChangePassword: state.settings.changePasswordDialog,
    };
};

const actionToProps = {
    fetchProfileDetails,
    updatePassword,
    showchangePasswordDialog,
};

export default connect(stateToProps, actionToProps)(AccountsTab);
