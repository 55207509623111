import { combineReducers } from 'redux';
import {
    CONTRIBUTOR_FETCH_ERROR,
    CONTRIBUTOR_FETCH_IN_PROGRESS,
    CONTRIBUTOR_FETCH_SUCCESS,
    CONTRIBUTORS_FETCH_ERROR,
    CONTRIBUTORS_FETCH_IN_PROGRESS,
    CONTRIBUTORS_FETCH_SUCCESS,
    DEAL_FETCH_ERROR,
    DEAL_FETCH_IN_PROGRESS,
    DEAL_FETCH_SUCCESS,
    DEALS_FETCH_ERROR,
    DEALS_FETCH_IN_PROGRESS,
    DEALS_FETCH_SUCCESS,
    GET_CONTRIBUTOR_ACCOUNT_ERROR,
    GET_CONTRIBUTOR_ACCOUNT_SUCCESS,
    NEW_DEAL_ERROR,
    NEW_DEAL_IN_PROGRESS,
    NEW_DEAL_SUCCESS,
    UPDATE_DEAL_ERROR,
    UPDATE_DEAL_IN_PROGRESS,
    UPDATE_DEAL_SUCCESS,
} from '../constants/deals';
import {
    DEFAULT_COUNT,
    DEFAULT_LIMIT,
    DEFAULT_ORDER,
    DEFAULT_SEARCH_KEY,
    DEFAULT_SKIP,
    DEFAULT_SORT_BY,
} from '../constants/url';

const listDeals = (state = {
    inProgress: false,
    list: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    count: DEFAULT_COUNT,
    searchKey: DEFAULT_SEARCH_KEY,
    sortBy: DEFAULT_SORT_BY,
    order: DEFAULT_ORDER,
}, action) => {
    switch (action.type) {
    case DEALS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
            list: [],
        };
    case DEALS_FETCH_SUCCESS:
        return {
            inProgress: false,
            list: action.value.list,
            count: action.value.count,
            skip: action.skip,
            limit: action.limit,
            searchKey: action.searchKey,
            sortBy: action.sortBy,
            order: action.order,
        };
    case DEALS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
            count: DEFAULT_COUNT,
        };
    default:
        return state;
    }
};

const singleDeal = (state = {
    inProgress: false,
    result: {},
}, action) => {
    switch (action.type) {
    case DEAL_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case DEAL_FETCH_SUCCESS:
        return {
            inProgress: false,
            result: action.item,
        };
    case DEAL_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const addDeal = (state = {
    inProgress: false,
    message: '',
}, action) => {
    switch (action.type) {
    case NEW_DEAL_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case NEW_DEAL_SUCCESS:
        return {
            inProgress: false,
            message: action.message,
        };
    case NEW_DEAL_ERROR:
        return {
            ...state,
            inProgress: false,
            message: action.message,
        };
    default:
        return state;
    }
};

const updateDeal = (state = {
    inProgress: false,
    result: {},
    message: '',
}, action) => {
    switch (action.type) {
    case UPDATE_DEAL_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case UPDATE_DEAL_SUCCESS:
        return {
            inProgress: false,
            result: action.item,
            message: action.message,
        };
    case UPDATE_DEAL_ERROR:
        return {
            ...state,
            inProgress: false,
            message: action.message,
        };
    default:
        return state;
    }
};

const listContributors = (state = {
    inProgress: false,
    list: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    count: DEFAULT_COUNT,
    searchKey: DEFAULT_SEARCH_KEY,
    sortBy: DEFAULT_SORT_BY,
    order: DEFAULT_ORDER,
}, action) => {
    switch (action.type) {
    case CONTRIBUTORS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
            list: [],
        };
    case CONTRIBUTORS_FETCH_SUCCESS:
        return {
            inProgress: false,
            list: action.value.list,
            count: action.value.count,
            skip: action.skip,
            limit: action.limit,
            searchKey: action.searchKey,
            sortBy: action.sortBy,
            order: action.order,
        };
    case CONTRIBUTORS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
            count: DEFAULT_COUNT,
        };
    default:
        return state;
    }
};

const singleContributor = (state = {
    inProgress: false,
    result: {},
}, action) => {
    switch (action.type) {
    case CONTRIBUTOR_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case CONTRIBUTOR_FETCH_SUCCESS:
        return {
            inProgress: false,
            result: action.item,
        };
    case CONTRIBUTOR_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const account = (state = {
    message: '',
    result: {},
}, action) => {
    switch (action.type) {
    case GET_CONTRIBUTOR_ACCOUNT_SUCCESS:
        return {
            result: action.item,
        };
    case GET_CONTRIBUTOR_ACCOUNT_ERROR:
        return {
            ...state,
            message: action.message,
        };
    default:
        return state;
    }
};

export default combineReducers({
    listDeals,
    singleDeal,
    addDeal,
    listContributors,
    singleContributor,
    updateDeal,
    account,
});
