import crypto from 'crypto';

export const encryptToken = (token) => {
    const cipher = crypto.createCipheriv(
        process.env.REACT_APP_ENCRYPT_ALGORITHM,
        Buffer.from(process.env.REACT_APP_ENCRYPT_KEY),
        process.env.REACT_APP_ENCRYPT_IV,
    );
    let encrypted = cipher.update(token);
    encrypted = Buffer.concat([encrypted, cipher.final()]);

    return encrypted.toString('hex');
};

export const decryptToken = (encrypted) => {
    const encryptedText = Buffer.from(encrypted, 'hex');
    const decipher = crypto.createDecipheriv(
        process.env.REACT_APP_ENCRYPT_ALGORITHM,
        Buffer.from(process.env.REACT_APP_ENCRYPT_KEY),
        process.env.REACT_APP_ENCRYPT_IV);
    let decrypted = decipher.update(encryptedText);
    decrypted = Buffer.concat([decrypted, decipher.final()]);

    return decrypted.toString();
};
