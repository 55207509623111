import React, { Component } from 'react';
import DataTable from '../../components/DataTable';
import './index.css';
import variables from '../../utils/variables';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CircularProgress from '../../components/CircularProgress';
import moment from 'moment';
import { fetchTippingList } from '../../actions/tipping';
import { removeUnderScroll } from '../../utils/statusMessages';

class Table extends Component {
    constructor (props) {
        super(props);

        this.handleFetch = this.handleFetch.bind(this);
    }

    componentDidMount () {
        this.handleFetch();
    }

    componentDidUpdate (pp, ps, ss) {
        if (this.props.auth && (pp.auth !== this.props.auth)) {
            this.handleFetch();
        }
    }

    handleFetch () {
        this.props.fetch();
    }

    render () {
        const options = {
            responsive: 'vertical',
            serverSide: false,
            print: false,
            selectableRows: 'none',
            searchPlaceholder: variables[this.props.lang]['search_by_account'],
            textLabels: {
                body: {
                    noMatch: this.props.inProgress
                        ? <CircularProgress/>
                        : <div className="no_data_table"> No data found </div>,
                    toolTip: 'Sort',
                },
                filter: {
                    all: 'All',
                    title: 'FILTERS',
                    reset: 'RESET',
                },
                viewColumns: {
                    title: 'Show Columns',
                    titleAria: 'Show/Hide Table Columns',
                },
            },
        };

        const columns = [{
            name: 'from',
            label: 'From',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => (
                    <div className="hash_text" title={value}>
                        <p className="ellipses_text">{value}</p>
                        {value.slice(value.length - 8, value.length)}
                    </div>
                ),
            },
        }, {
            name: 'to',
            label: 'To',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => (
                    <div className="hash_text" title={value}>
                        <p className="ellipses_text">{value}</p>
                        {value.slice(value.length - 8, value.length)}
                    </div>
                ),
            },
        }, {
            name: 'value',
            label: variables[this.props.lang].value,
            options: {
                filter: false,
                sort: true,
            },
        }, {
            name: 'created_at',
            label: 'Created (UTC)',
            options: {
                filter: true,
                sort: true,
            },
        }, {
            name: 'status',
            label: variables[this.props.lang].status,
            options: {
                filter: true,
                sort: true,
            },
        }, {
            name: 'claim_status',
            label: 'Claim Status',
            options: {
                filter: true,
                sort: true,
            },
        }];

        const tableData = this.props.data && this.props.data.length
            ? this.props.data.map((item) =>
                [
                    item.from,
                    item.to,
                    item.value && item.value * Math.pow(10, -16),
                    item['created_at'] && moment(item['created_at']).utc().format('MMM DD, YYYY, H:mm:ss'),
                    item.status && removeUnderScroll(item.status),
                    item.claim_status && removeUnderScroll(item.claim_status),
                ])
            : [];

        return (
            <div className="table">
                <DataTable
                    columns={columns}
                    data={tableData}
                    name={variables[this.props.lang].tipping}
                    options={options}/>
            </div>
        );
    }
}

Table.propTypes = {
    data: PropTypes.array.isRequired,
    fetch: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    auth: PropTypes.string,
};

const stateToProps = (state) => {
    return {
        auth: state.accessToken.value,
        lang: state.language,
        data: state.tipping.tableData.list,
        inProgress: state.tipping.tableData.inProgress,
    };
};

const actionToProps = {
    fetch: fetchTippingList,
};

export default connect(stateToProps, actionToProps)(Table);
