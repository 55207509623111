import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React from 'react';
import './index.css';
import Info from './Info';
import Tips from './Tips';

const Newsfeed = (props) => {
    const { userData } = props;
    return (
        <div className="profile-page__newsfeed flex flex-col justify-between text-white lg:flex-row w-full mx-auto mb-10">
            <div className="newsfeed__left w-full lg:w-2/5  px-0 lg:pr-4 mb-8 lg:mb-0">
                <Info
                    lang={props.lang}
                    publicProfile={props.publicProfile}
                    userData={userData}
                />
            </div>
            <div className="newsfeed__right text-white w-full lg:w-3/5  px-0 lg:pl-4">
                <Tips
                    lang={props.lang}
                />
            </div>
        </div>
    );
};

Newsfeed.propTypes = {
    lang: PropTypes.string.isRequired,
    userData: PropTypes.object.isRequired,
    publicProfile: PropTypes.bool,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
    };
};

const actionToProps = {

};

export default connect(stateToProps, actionToProps)(Newsfeed);
