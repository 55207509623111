import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ArrowDown from '../../assets/images/chevron-down.svg';
import Icon from '../../components/Icon';
import ForumItemRowDetail from './ForumItemRowDetail';

const ForumItemRow = (props) => {
    const { data, handleFetchForums } = props;
    const handleFetch = () => {
        handleFetchForums();
    };

    return (
        <div>
            <Accordion className="rounded-t-2xl" defaultExpanded={true} >
                <AccordionSummary
                    className="accordion__header"
                    expandIcon={<img src={ArrowDown} />}
                >
                    <div className="flex text-white font-oswald-text py-3 md:py-4 px-8 text-black uppercase hidden md:flex text-sm lg:text-base font-bold w-full">
                        <div className="accordion__column flex-auto">
                        </div>
                        <div className="accordion__column hidden md:flex flex-none items-center justify-center w-1/3">
                            <Icon className="forums text-white ml-10" icon="forums"/>
                        </div>
                        <div className="accordion__column hidden md:flex flex-none w-1/6">
                            <Icon className="calendar text-white ml-8" icon="calendar"/>
                        </div>
                        <div className="accordion__column hidden md:flex flex-none w-1/6">
                        </div>
                    </div>
                </AccordionSummary>
                <AccordionDetails className="accordion bg-custom-dark flex-col">
                    {typeof data !== 'undefined' && data.length > 0 ? (
                        data.map((item) => (
                            <div
                                key={item._id}
                                className="accordion__row flex flex-col md:flex-row py-8 px-6 overflow-hidden border-b border-gray-600 text-white font-oswald-text w-full tracking-wider"
                            >
                                <ForumItemRowDetail key={item._id} handleFetch={handleFetch} item={item} />
                            </div>

                        ))
                    ) : (
                        <h5 className="py-8 px-10 text-center text-white">No topic found</h5>
                    )}
                </AccordionDetails>
            </Accordion>

        </div>
    );
};

ForumItemRow.propTypes = {
    data: PropTypes.array,
    deleteThread: PropTypes.func,
    fetch: PropTypes.func,
    handleFetchForums: PropTypes.func,
};

const stateToProps = (state) => {
    return {

    };
};

const actionToProps = {

};

export default connect(stateToProps, actionToProps)(ForumItemRow);
