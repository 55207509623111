import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { setPassword } from '../../actions/signIn';
import TextField from '../../components/TextField';
import variables from '../../utils/variables';

const PasswordTextField = (props) => {
    return (
        <>
            <label className="text-white font-oswald-text text-lg mb-1 font-normal block">{variables[props.lang].password_label}</label>
            <TextField
                className="rounded-md bg-custom-dark-2 w-full"
                error={props.value !== '' && props.value.length < 8}
                errorText={props.value !== '' && props.value.length < 8 ? 'Minimum 8 characters required!' : ''}
                id="password_text_field"
                name="password"
                placeholder={variables[props.lang].password}
                type="password"
                value={props.value}
                onChange={props.onChange}/>
        </>
    );
};

PasswordTextField.propTypes = {
    lang: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        value: state.signIn.password,
    };
};

const actionsToProps = {
    onChange: setPassword,
};

export default connect(stateToProps, actionsToProps)(PasswordTextField);
