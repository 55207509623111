import { IconButton } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import React from 'react';
import './index.css';
import Icon from '../Icon';

const CloseDialog = (props) => {
    return (
        <div className={`${props.className}`}>
            <IconButton
                className="close_button"
                variant="contained"
                onClick={props.onClose}>
                <Icon className="close" icon="new-close"/>
            </IconButton>
        </div>
    );
};

CloseDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    className: PropTypes.string,
};

export default CloseDialog;
