import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { Editor, EditorState } from 'draft-js';
import { connect } from 'react-redux';
import { deleteCommentByID } from '../../actions/forum';
import Snackbar from '../../components/Snackbar';
import Button from '../Button';
// eslint-disable-next-line import/no-extraneous-dependencies
import { stateFromHTML } from 'draft-js-import-html';
import moment from 'moment';

const CommentRow = (props) => {
    const {
        comment,
        commenter,
        commentDate,
        id,
        deleteCommentByID,
        handleDelete,
    } = props;

    const [deleted, setIsDeleted] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState('');

    useEffect(() => {
        if (deleted) {
            handleDelete(deleted);
        }
    }, [deleted]);

    const handleClick = async () => {
        await deleteCommentByID(id);
        setIsDeleted(true);
        setSnackbarMessage('Comment Deleted');
        setSnackbarOpen(true);
        setSnackbarSeverity('success');
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };
    return (
        <>
            <Snackbar
                message={snackbarMessage}
                open={snackbarOpen}
                severity={snackbarSeverity}
                onClose={handleSnackbarClose} />
            <div className="py-8 border-b font-oswald-text">
                <div className="mb-8 md:mb-4">
                    <Editor
                        readOnly
                        editorState={EditorState.createWithContent(stateFromHTML(comment))} />
                </div>
                <div className="flex justify-end md:items-center w-full flex-col md:flex-row text-custom-white">
                    <span className="mr-2 truncate w-full md:w-auto">
                        by <span className="text-white font-bold">{commenter}</span>
                    </span>
                    <span>{`${moment(commentDate).startOf('second').fromNow()}, ${moment(commentDate).format('hh:mm a')}`}</span>
                </div>
                <div className="flex items-center justify-end accordion__column flex flex-none w-auto md:w-full items-center pt-4">
                    <Button
                        className="font-oswald-text"
                        color="primary"
                        size="sm"
                        onClick={handleClick}
                    >
                    Delete
                    </Button>
                </div>
            </div></>
    );
};

CommentRow.propTypes = {
    comment: PropTypes.any,
    commentDate: PropTypes.string,
    commenter: PropTypes.string,
    deleteCommentByID: PropTypes.func,
    handleDelete: PropTypes.func,
    id: PropTypes.string,
    inProgress: PropTypes.bool,
    success: PropTypes.bool,
};

const stateToProps = (state) => {
    const { deleteComment } = state.forums;
    return {
        success: deleteComment.success,
        inProgress: deleteComment.inProgress,
    };
};

const actionToProps = {
    deleteCommentByID,
};

export default connect(stateToProps, actionToProps)(CommentRow);
