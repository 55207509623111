import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import Icon from '../../../components/Icon';
import isEmpty from 'lodash/isEmpty';
import './index.css';

const PublicBio = (props) => {
    const {
        userData,
        userProfilePic,
    } = props;
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [hasTwitter, setHasTwitter] = useState(false);

    useEffect(() => {
        if (!isEmpty(userData.value.result)) {
            setEmail(userData.value.result.email_address);

            if (userData.value.result.name) {
                setName(userData.value.result.name);
            } else {
                setName(userData.value.result.email_address);
            }

            if (!isEmpty(userData.value.result.twitter)) {
                console.log('twitter', userData.value.result.twitter.username);
                setHasTwitter(true);
            } else {
                setHasTwitter(false);
            }
        } else {
            setHasTwitter(false);
        }
    }, [userData, email, name]);

    const twitterUrl = (e) => {
        e.preventDefault();
        window.open(`//twitter.com/${userData.value.result.twitter}`, '_self');
    };

    return (
        <div className="public-profile__bio mb-16 flex flex-col items-center -mt-10 pb-10">
            <div className="public-bio__photo -mt-24 mb-10">
                <img alt="User Image" className="public-bio__image" src={userProfilePic.value.url} />
            </div>
            <div className="public-bio__details flex flex-col items-center">
                <span className="public-bio__name font-bold mb-2">{name}</span>
                <span className="public-bio__email mb-6">{email}</span>
                {(hasTwitter) &&
                    <div className="newsfeed__social-links mb-5 i">
                        <h2 className="font-bold mb-5">Social Media</h2>
                        <div className="flex flex-row justify-center">
                            <button onClick={twitterUrl}>
                                <Icon
                                    className="twitter w-7"
                                    icon="twitter"
                                />
                            </button>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

PublicBio.propTypes = {
    userData: PropTypes.object.isRequired,
    userProfilePic: PropTypes.object.isRequired,
};

export default PublicBio;
