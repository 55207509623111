import { TIPPING_LIST_URL, userTipsUrl } from '../constants/url';
import Axios from 'axios';
import {
    TIPPING_LIST_FETCH_ERROR,
    TIPPING_LIST_FETCH_IN_PROGRESS,
    TIPPING_LIST_FETCH_SUCCESS,
    USER_TIPS_FETCH_IN_PROGRESS,
    USER_TIPS_FETCH_SUCCESS,
    USER_TIPS_FETCH_ERROR,
} from '../constants/tipping';

const fetchTippingListInProgress = () => {
    return {
        type: TIPPING_LIST_FETCH_IN_PROGRESS,
    };
};

const fetchTippingListSuccess = (value) => {
    return {
        type: TIPPING_LIST_FETCH_SUCCESS,
        value,
    };
};

const fetchTippingListError = (message) => {
    return {
        type: TIPPING_LIST_FETCH_ERROR,
        message,
    };
};

export const fetchTippingList = () => (dispatch) => {
    dispatch(fetchTippingListInProgress());

    Axios.get(TIPPING_LIST_URL, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
    })
        .then((res) => {
            dispatch(fetchTippingListSuccess(res.data && res.data.result));
        })
        .catch((error) => {
            dispatch(fetchTippingListError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const fetchUserTipsInProgress = () => {
    return {
        type: USER_TIPS_FETCH_IN_PROGRESS,
    };
};

const fetchUserTipsSuccess = (value) => {
    return {
        type: USER_TIPS_FETCH_SUCCESS,
        value,
    };
};

const fetchUserTipsError = (message) => {
    return {
        type: USER_TIPS_FETCH_ERROR,
        message,
    };
};

export const fetchUserTips = (id) => (dispatch) => {
    dispatch(fetchUserTipsInProgress());

    const url = userTipsUrl(id);
    Axios.get(url, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
    })
        .then((res) => {
            dispatch(fetchUserTipsSuccess(res.data && res.data.result));
        })
        .catch((error) => {
            dispatch(fetchUserTipsError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};
