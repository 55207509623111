import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import Icon from '../../../../components/Icon';
import './index.css';

const Info = (props) => {
    // const twitter = '#';
    const { userData } = props;
    const [hasTwitter, setHasTwitter] = useState(false);
    const [bio, setBio] = useState('No bio yet.');
    useEffect(() => {
        if (!isEmpty(userData.value.result)) {
            if (!isEmpty(userData.value.result.bio)) {
                setBio(userData.value.result.bio);
            } else {
                setBio('No bio yet');
            }

            if (!isEmpty(userData.value.result.twitter)) {
                setHasTwitter(true);
            } else {
                setHasTwitter(false);
            }
        } else {
            setHasTwitter(false);
            setBio('No bio yet');
        }
    }, [userData, bio]);

    const twitterUrl = (e) => {
        e.preventDefault();
        window.open(`//twitter.com/${userData.value.result.twitter.username}`, '_self');
    };

    return (
        <div className="newsfeed__info p-5 w-full">
            <div className="newsfeed__bio mb-5 text-white">
                <h2 className="font-bold mb-5">Bio</h2>
                <p>{bio}</p>
            </div>
            {(hasTwitter) &&
                <div className="newsfeed__social-links mb-5">
                    <h2 className="font-bold mb-5">Social Media</h2>
                    <div className="flex flex-row">
                        <button onClick={twitterUrl}>
                            <Icon
                                className="twitter w-7"
                                icon="twitter"
                            />
                        </button>
                    </div>
                </div>
            }
        </div>
    );
};

Info.propTypes = {
    userData: PropTypes.object.isRequired,
    // publicProfile: PropTypes.bool,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        profileDetails: state.navigationBar.profileDetails.value,
    };
};

const actionToProps = {

};

export default connect(stateToProps, actionToProps)(Info);
