import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';

const OkButton = (props) => {
    return (
        <Button
            className="verify_button"
            disabled={props.disable}
            onClick={props.onClose}>
            OK, I saved my keys
        </Button>
    );
};

OkButton.propTypes = {
    disable: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default OkButton;
