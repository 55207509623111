import React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';

const UserInbox = (props) => {
    const { id, image, name, subject, time, handleConversation, message } = props;

    return (
        <div className="flex text-white mb-3 p-2 hover:bg-custom-dark-3 cursor-pointer" onClick={() => handleConversation(id, message)}>
            <div className="rounded-full bg-white overflow-hidden w-8 h-8 xl:w-14 xl:h-14">
                <img alt="profile" className="w-10 h-full xl:w-16 object-cover object-center" src={image}/>
            </div>
            <div className="hidden lg:flex items-end w-auto flex-1 pl-4">
                <div className="flex flex-col w-2/3">
                    <span className="font-oswald-text font-bold text-base text-ellipsis">{name}</span>
                    <span className="font-oswald-text font-light text-base text-ellipsis">{subject}</span>
                </div>
                <div className="w-1/3 text-right">
                    <span className="font-oswald-text opacity-50 text-sm">{time}</span>
                </div>
            </div>
        </div>
    );
};

UserInbox.propTypes = {
    handleConversation: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    subject: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired,
    message: PropTypes.object,
};

export default connect()(UserInbox);
