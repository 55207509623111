import React from 'react';
import * as PropTypes from 'prop-types';
import { Link } from '@material-ui/core';
import { connect } from 'react-redux';
import Icon from '../../components/Icon';
import variables from '../../utils/variables';
// import homeIcon from '../../../assets/homeIcon.svg';

const Breadcrumbs = (props) => {
    const { forumId, lang } = props;

    return (
        <nav aria-label="breadcrumbs" className="breadcrumb mb-4">
            <ol className="flex flex-wrap items-center text-sm">
                {forumId && (
                    <>
                        <li aria-hidden="true" className="px-2 flex items-center"></li>
                        <li>
                            <Link
                                className="breadcrumb__link flex items-center"
                                href={'/forums/'}
                            >
                                <Icon
                                    className="dark-back-arrow mr-2"
                                    icon="dark-back-arrow"
                                />
                                <span className="font-oswald-text font-bold text-white py-2 text-lg">{variables[lang].back}</span>
                            </Link>
                        </li>
                    </>
                )}
            </ol>
        </nav>
    );
};

Breadcrumbs.propTypes = {
    lang: PropTypes.string.isRequired,
    forumId: PropTypes.string,
};
const stateToProps = (state) => {
    return {
        lang: state.language,
    };
};

const actionToProps = {

};

export default connect(stateToProps, actionToProps)(Breadcrumbs);
