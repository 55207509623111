import React, { Component } from 'react';
import { Button } from '@material-ui/core';
import DataTable from '../../components/DataTable';
import './index.css';
import variables from '../../utils/variables';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchEXNGClaims } from '../../actions/dashboard';
import {
    DEFAULT_LIMIT,
    DEFAULT_ORDER,
    DEFAULT_SEARCH_KEY,
    DEFAULT_SKIP,
    DEFAULT_SORT_BY,
    EXNG_CLAIMS_EXPORT_URL,
} from '../../constants/url';
import { downloadFile } from '../../actions/unstakeRequests';
import CircularProgress from '../../components/CircularProgress';

class ExngClaimsTable extends Component {
    constructor (props) {
        super(props);

        this.handleFetch = this.handleFetch.bind(this);
    }

    componentDidMount () {
        if (this.props.inProgress || this.props.data.length > 0) {
            return;
        }

        this.handleFetch(DEFAULT_SKIP,
            DEFAULT_LIMIT,
            DEFAULT_SEARCH_KEY,
            DEFAULT_SORT_BY,
            DEFAULT_ORDER);
    }

    componentDidUpdate (pp, ps, ss) {
        if (this.props.auth && (pp.auth !== this.props.auth)) {
            this.handleFetch(DEFAULT_SKIP,
                DEFAULT_LIMIT,
                DEFAULT_SEARCH_KEY,
                DEFAULT_SORT_BY,
                DEFAULT_ORDER);
        }
    }

    handleFetch (skip, limit, searchText, sortBy, order) {
        this.props.fetch(skip, limit, searchText, sortBy, order);
    }

    render () {
        const options = {
            responsive: 'vertical',
            serverSide: true,
            print: false,
            pagination: this.props.pagination ? this.props.pagination : false,
            count: this.props.count,
            selectableRows: 'none',
            searchPlaceholder: variables[this.props.lang]['search_by_account'],
            onSearchChange: (searchText) => {
                if (this.props.data && this.props.data.length === 0) {
                    return;
                }

                this.handleFetch(this.props.skip, this.props.limit, searchText, this.props.sortBy, null);
            },
            onChangePage: (currentPage) => {
                if (this.props.data && this.props.data.length === 0) {
                    return;
                }

                this.handleFetch(this.props.limit * currentPage, this.props.limit, this.props.searchKey, this.props.sortBy, null);
            },
            onChangeRowsPerPage: (numberOfRows) => {
                if (this.props.data && this.props.data.length === 0) {
                    return;
                }

                this.handleFetch(this.props.skip, numberOfRows, this.props.searchKey, this.props.sortBy, null);
            },
            onColumnSortChange: (changedColumn, direction) => {
                if (this.props.data && this.props.data.length === 0) {
                    return;
                }

                const order = direction === 'asc' ? 1 : -1;
                this.handleFetch(this.props.skip, this.props.limit, this.props.searchKey, changedColumn, order);
            },
            onDownload: () => {
                this.props.downloadFile(EXNG_CLAIMS_EXPORT_URL, 'EXNG claims Report');
                return false;
            },
            textLabels: {
                body: {
                    noMatch: this.props.inProgress
                        ? <CircularProgress/>
                        : <div className="no_data_table font-oswald-text text-white"> No data found </div>,
                    toolTip: 'Sort',
                },
                filter: {
                    all: 'All',
                    title: 'FILTERS',
                    reset: 'RESET',
                },
                viewColumns: {
                    title: 'Show Columns',
                    titleAria: 'Show/Hide Table Columns',
                },
            },
        };

        const columns = [{
            name: 'stake_id',
            label: 'Stake ID',
            options: {
                filter: false,
                sort: true,
            },
        }, {
            name: '_id',
            label: 'Claim ID',
            options: {
                filter: false,
                sort: true,
            },
        }, {
            name: 'account_address',
            label: 'Account Address',
            options: {
                filter: false,
                sort: true,
            },
        }, {
            name: 'email_address',
            label: 'Email Address',
            options: {
                filter: false,
                sort: true,
                display: false,
            },
        }, {
            name: 'telegram_username',
            label: 'Telegram Username',
            options: {
                filter: false,
                sort: true,
                display: false,
            },
        }, {
            name: 'claim_amount',
            label: 'Claim Amount (EXNG)',
            options: {
                filter: false,
                sort: true,
            },
        }, {
            name: 'status',
            label: 'Status',
            options: {
                filter: false,
                sort: true,
            },
        }, {
            name: 'txHash',
            label: 'Tx Hash',
            options: {
                filter: false,
                sort: true,
            },
        }, {
            name: 'actions',
            label: 'Actions',
            options: {
                filter: false,
                sort: false,
                customBodyRender: () => (
                    <Button className="view_details_button">
                        {variables[this.props.lang]['view_details']}
                    </Button>
                ),
            },
        }];

        const tableData = this.props.data && this.props.data.length
            ? this.props.data.map((item, index) =>
                [
                    item.stake_id,
                    item._id,
                    item.account_address,
                    item.email_address,
                    item.telegram_username,
                    item.claim_amount,
                ])
            : [];

        return (
            <div className="table w-full bg-custom-dark text-white font-oswald-text p-4 rounded-md">
                <DataTable
                    columns={columns}
                    data={tableData}
                    name="EXNG Claims"
                    options={options}/>
            </div>
        );
    }
}

ExngClaimsTable.propTypes = {
    count: PropTypes.number.isRequired,
    data: PropTypes.array.isRequired,
    downloadFile: PropTypes.func.isRequired,
    fetch: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    limit: PropTypes.number.isRequired,
    searchKey: PropTypes.string.isRequired,
    skip: PropTypes.number.isRequired,
    sortBy: PropTypes.string.isRequired,
    auth: PropTypes.string,
    order: PropTypes.number,
    pagination: PropTypes.bool,
};

const stateToProps = (state) => {
    return {
        auth: state.accessToken.value,
        lang: state.language,
        data: state.dashboard.list.list,
        count: state.dashboard.list.count,
        inProgress: state.dashboard.list.inProgress,
        limit: state.dashboard.list.limit,
        order: state.dashboard.list.order,
        searchKey: state.dashboard.list.searchKey,
        skip: state.dashboard.list.skip,
        sortBy: state.dashboard.list.sortBy,
    };
};

const actionToProps = {
    fetch: fetchEXNGClaims,
    downloadFile,
};

export default connect(stateToProps, actionToProps)(ExngClaimsTable);
