import React, { Fragment, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { Dialog, Transition } from '@headlessui/react';

const Modal = ({ isOpen, setIsOpen, modalTitle, children }) => {
    useEffect(() => {
        // console.log(setIsOpen);
    }, []);
    return (
        createPortal(
            <Transition appear as={Fragment} show={isOpen}>
                <Dialog
                    as="div"
                    className="relative z-10 bg-custom-dark"
                    onClose={() => setIsOpen(false)}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-custom-dark bg-opacity-25" />
                    </Transition.Child>
                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full md:w-1/2 transform overflow-hidden rounded-2xl bg-custom-dark text-custom-white p-6 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-xl font-medium leading-6 text-white"
                                    >
                                        <span className="font-bold text-lg">{modalTitle}</span>
                                    </Dialog.Title>
                                    <div className="pt-4">{children}</div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>,
            document.body,
        )
    );
};
export default Modal;
