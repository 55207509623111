import React, { useState } from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import { fetchForums } from '../../actions/forum';
import InputField from '../Global/InputField';
import Modal from '../Global/Modal';

const ForumContainer = ({
    setPageNo,
    children,
    userData,
    isSuccess,
    fetchForums,
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsOpen(false);
        await fetchForums();
    };

    const handleForm = (name, value) => {
    };

    const handleOpenModal = (status) => {
        setIsOpen(status);
    };

    return (
        <div>
            <div className="flex md:items-center flex-col md:flex-row justify-between mb-4 text-white">
                <div className="flex justify-start w-full font-oswald-text mb-4">
                    <span className="text-white opacity-60">Home</span>
                </div>
                {Object.keys(userData).length > 0 && (
                    <Modal isOpen={isOpen} modalTitle="Forum" setIsOpen={handleOpenModal}>
                        <form onSubmit={handleSubmit} >
                            <InputField
                                fieldName="title"
                                handleForm={handleForm}
                                label="Title"
                            />
                            <InputField
                                fieldClass="h-80 forum-editor"
                                fieldName="content"
                                handleForm={handleForm}
                                label="Content"
                                type="textarea"
                            />
                            <div className="flex justify-center">
                                <button className="btn--primary btn--small border bg-custom-dark-mode-red border-custom-dark-mode-red rounded-md px-6 py-2" type="submit">
                                    <span className="text-white font-oswald-text">Submit</span>
                                </button>
                            </div>
                        </form>
                    </Modal>
                )}
            </div>
            {children}
        </div>
    );
};

ForumContainer.propTypes = {
    userData: PropTypes.object.isRequired,
    children: PropTypes.elementType,
    createThread: PropTypes.func,
    fetchForums: PropTypes.func,
    isSuccess: PropTypes.bool,
    setPageNo: PropTypes.number,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        userData: state.navigationBar.profileDetails,
        isSuccess: state.forums.createForum.success,
    };
};

const actionToProps = {
    // createThread: createThread,
    fetchForums,
};

export default connect(stateToProps, actionToProps)(ForumContainer);
