import { Button } from '@material-ui/core';
import React from 'react';
import * as PropTypes from 'prop-types';

const ChangePasswordButton = (props) => {
    return (
        <Button
            className="change_password w-1/2 mx-2 font-oswald-text"
            disabled={props.disable}
            onClick={props.onClick}>
            Update
        </Button>
    );
};

ChangePasswordButton.propTypes = {
    disable: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default ChangePasswordButton;
