export const UPDATE_ME_IN_PROGRESS = 'UPDATE_ME_IN_PROGRESS';
export const UPDATE_ME_SUCCESS = 'UPDATE_ME_SUCCESS';
export const UPDATE_ME_ERROR = 'UPDATE_ME_ERROR';
export const UPDATE_PROFILE_PIC_IN_PROGRESS = 'UPDATE_PROFILE_PIC_IN_PROGRESS';
export const UPDATE_PROFILE_PIC_SUCCESS = 'UPDATE_PROFILE_PIC_SUCCESS';
export const UPDATE_PROFILE_PIC_ERROR = 'UPDATE_PROFILE_PIC_ERROR';
export const UPDATE_BANNER_PIC_IN_PROGRESS = 'UPDATE_BANNER_PIC_IN_PROGRESS';
export const UPDATE_BANNER_PIC_SUCCESS = 'UPDATE_BANNER_PIC_SUCCESS';
export const UPDATE_BANNER_PIC_ERROR = 'UPDATE_BANNER_PIC_ERROR';
export const BANNER_IMAGE_FETCH_IN_PROGRESS = 'BANNER_IMAGE_FETCH_IN_PROGRESS';
export const BANNER_IMAGE_FETCH_SUCCESS = 'BANNER_IMAGE_FETCH_SUCCESS';
export const BANNER_IMAGE_FETCH_ERROR = 'BANNER_IMAGE_FETCH_ERROR';
