import { combineReducers } from 'redux';
import {
    STAKED_REQUEST_FILTER_SET,
    UN_STAKE_REPORT_FETCH_ERROR,
    UN_STAKE_REPORT_FETCH_IN_PROGRESS,
    UN_STAKE_REPORT_FETCH_SUCCESS,
    UN_STAKED_REQUEST_FILTER_SET,
} from '../constants/unstakeRequests';
import {
    DEFAULT_COUNT,
    DEFAULT_LIMIT,
    DEFAULT_ORDER,
    DEFAULT_SEARCH_KEY,
    DEFAULT_SKIP,
    DEFAULT_SORT_BY,
} from '../constants/url';

const stakeFilter = (state = '24 hrs', action) => {
    if (action.type === STAKED_REQUEST_FILTER_SET) {
        return action.value;
    }

    return state;
};

const unstakeFilter = (state = '24 hrs', action) => {
    if (action.type === UN_STAKED_REQUEST_FILTER_SET) {
        return action.value;
    }

    return state;
};

const list = (state = {
    inProgress: false,
    list: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    count: DEFAULT_COUNT,
    searchKey: DEFAULT_SEARCH_KEY,
    sortBy: DEFAULT_SORT_BY,
    order: DEFAULT_ORDER,
}, action) => {
    switch (action.type) {
    case UN_STAKE_REPORT_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case UN_STAKE_REPORT_FETCH_SUCCESS:
        return {
            inProgress: false,
            list: action.value.list,
            count: action.value.count,
            skip: action.skip,
            limit: action.limit,
            searchKey: action.searchKey,
            sortBy: action.sortBy,
            order: action.order,
        };
    case UN_STAKE_REPORT_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

export default combineReducers({
    stakeFilter,
    unstakeFilter,
    list,
});
